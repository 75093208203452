import translate from "../i18n";
import FileUploadList, { FileUploadOptions } from '../svelte/components/FileUploadList';
import onDragFile from '../utils/drag-file';
import listen from '../utils/listen';
import { onAfterNavigate, onInit } from '../utils/subscriptions';
import logError from "../utils/log-error";

const DEFAULT_MAX_SIZE = 208666624; // 199MiB (200MiB - 1MiB for other post variables)
const DEFAULT_MAX_FILE_SIZE = 10000000;

const CLASS_CONTAINER = 'file-upload';
const CLASS_DROP_TARGET = 'file-upload__drop-target';
const CLASS_DROP_TARGET_OVER = 'file-upload__drop-target--drag-over';

let listeners: Map<HTMLInputElement, (() => any)[]> = new Map();

const init = () => {
	const elements = Array.from(document.querySelectorAll('input[type="file"][data-file-upload]')) as HTMLInputElement[];
	listeners.forEach((listener, element) => {
		if (!elements.includes(element)) {
			listeners.delete(element);
			for (const unlisten of listener) {
				unlisten();
			}
		}
	});

	for (const element of elements) {
		if (listeners.has(element) || element.form == undefined || element.form == null) {
			continue;
		}

		const options = getOptions(element);
		if (!options) {
			logError(`Options are undefined for element: ${element}`);
			return;
		}

		const parent = element.closest(options.dropTarget);
		if (!parent) {
			if (__DEV__) {
				logError(`file-upload: parent element not found, dropTarget: ${options.dropTarget}, element: ${element}`);
			}
			return;
		}

		parent.classList.add(CLASS_CONTAINER);

		let fileUploadList: FileUploadList;
		const getFileUploadList = () => {
		
			
			if (!fileUploadList) {
				const anchor = options.listBefore && parent.querySelector(options.listBefore);
                const target = anchor ? anchor.parentElement : parent;

                if ( target === null || target === undefined ) {
                    logError(`Target for fileUploadList is null or undefined. 
                                Anchor: ${anchor}, Anchor-Parent: ${anchor.parentElement}, Parent: ${parent}, uploadOptions: ${options}`);
                }

				let acceptAttr = element.hasAttribute('accept') ? element.getAttribute('accept') : undefined;
				let accept = acceptAttr && typeof acceptAttr === 'string' && acceptAttr.trim().length > 0 ? 
					acceptAttr.split(',').map(type => type.trim()).filter(type => type.length > 0) : undefined;

				if ( accept && accept.length === 0 ) {
					accept = undefined;
				}

				fileUploadList = new FileUploadList({
					anchor,
					target: target,
					props: {
						accept: accept,
						multiple: element.hasAttribute('multiple'),
						options,
					},
				});
			}

			return fileUploadList;
		};

		listeners.set(element, [
			listen(element.form!, 'before-send-form', ({ detail: { formData } }) => {
				formData.delete(element.name);
				for (const file of getFileUploadList().getFiles()) {
					formData.append(element.name, file, file.name);
				}
			}),
			listen(element, 'change', () => {
				if (element.files) {
					getFileUploadList().addFiles(element.files);
					element.value = '';
				}
			}),
			onDragFile(() => {
				const target = document.createElement('div');
				target.className = CLASS_DROP_TARGET;
				target.textContent = translate('FileUpload_DropFilesHere');
				parent.appendChild(target);

				return {
					target,
					drop: files => getFileUploadList().addFiles(files),

					enter() {
						target.classList.add(CLASS_DROP_TARGET_OVER);
					},

					leave() {
						target.classList.remove(CLASS_DROP_TARGET_OVER);
					},

					destroy() {
						parent.removeChild(target);
					},
				};
			}),
			() => {
				try {
					fileUploadList && fileUploadList.$destroy();
				} catch (e) {}
			},
		]);
	}
};

const getOptions = (element: Element): FileUploadOptions | void => {
	try {
		const options = JSON.parse(element.getAttribute('data-file-upload'));
		if (
			typeof options === 'object' &&
			typeof options.dropTarget === 'string' &&
			(!options.listBefore || typeof options.listBefore === 'string') &&
			(typeof options.availableSpace === 'number' || !options.availableSpace) &&
			(typeof options.maxFiles === 'number' || !options.maxFiles) &&
			(typeof options.maxSize === 'number' || !options.maxSize) &&
			(typeof options.maxFileSize === 'number' || !options.maxFileSize)
		) {
			if (!options.maxSize) {
				options.maxSize = DEFAULT_MAX_SIZE;
			}

			if (!options.maxFileSize) {
				options.maxFileSize = DEFAULT_MAX_FILE_SIZE;
			}

			return options;
		}
	} catch (e) {}

	if (__DEV__) {
		console.error(`data-dropable must be a json object (interface: {
			dropTarget: string;
			listBefore?: string;
			maxFiles?: number;
			maxSize?: number;
			maxFileSize?: number;
		})`);
	}
};

onInit(init);
onAfterNavigate(init);

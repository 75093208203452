import $ from 'jquery';
import './@startup';
import './components/badge';
import './components/bubble';
import './components/click-in';
import './components/file-upload';
import './components/help-bubble';
import './components/tabview';
import './layout/header';
import * as LoadingBar from './layout/loading-bar';
import './layout/navigation';
import './layout/sub-navigation';
import './legacy';
import './legacy/infinite-scroll';
import './pages';
import { onAfterNavigate, onInit } from './utils/subscriptions';
import './utils/version-checker';
import  './svelte/createSvelteComponents';

const doc = $(document);

doc.ajaxStart(LoadingBar.activate).ajaxStop(LoadingBar.deactivate);

if (document.querySelector('#content')) {
	onInit.notify(doc);
} else {
	const unlisten = onAfterNavigate(() => {
		if (document.querySelector('#content')) {
			unlisten();
			onInit.notify(doc, true);
		}
	});
}

import debounce from 'debounce';
import chevronDown from 'icon:chevron-down';
import $ from 'jquery';
import config from '../config';
import language, { updateTranslation } from '../i18n';
import changeTheme from '../utils/change-theme';
import { destroyWithin as destroyComponents } from '../utils/components';
import createCustomEvent from '../utils/create-custom-event';
import { dateFormat, formatCurrency2User, formatNumber2User } from '../utils/format';
import getScrollParent from '../utils/get-scroll-parent';
import listen from '../utils/listen';
import error from '../utils/log-error';
import { onAfterNavigate, onLogin, onNavigate } from '../utils/subscriptions';
import SignaturePad from 'signature_pad';
import { backendData } from '../svelte/Event/Staffplan/utils/utilsStaffplan';
import { jobResourcesJS } from '../svelte/Event/Staffplan/stores/stores';
import './retina';
import { useNewStaffplanStore } from '../svelte/Event/Staffplan/stores/stores';
import { moveResourceToNewCategory  } from '../svelte/Event/Staffplan/utils/moveResourceToNewCategory';


window.onerror = function (msg, url, line, col, errortext) {
	var extra = !col ? '' : '<br />column: ' + col;
	extra += !errortext ? '' : '<br />error: ' + errortext;
	try {
		if (errortext.stack != undefined) {
			extra += '<br />' + errortext.stack;
		}
	} catch (e) {
		//
	}

	let stack = errortext && errortext.stack;
	if (!stack) {
		stack = msg + '\n    at ' + url + ':' + line + ':' + col;
	}

	error(msg + "<br />location: " + document.location + "<br />url: " + url + "<br />line: " + line + extra, stack);
	return !__DEV__;
};

function in_array(haystack, needle) {
	for (var i = 0; i < haystack.length; i++) {
		if (haystack[i] == needle) {
			return true;
		}
	}
	return false;
}
// Local Storage oder Cookie auslesen
function getLocalData(name, forceCookie=false) {
	return typeof(Storage) != undefined && !forceCookie ? // local storage supported?
		window.localStorage.getItem(name) : getCookie(name);
}
// Local Storage oder Cookie speichern
function setLocalData(name, value, exdays=null, forceCookie=false, globalCookie=false) {
	if ( typeof(Storage) != undefined && !forceCookie ) {
		value === undefined || value === null || value === '' ? 
			window.localStorage.removeItem(name) : window.localStorage.setItem(name, value);
	} else {
		setCookie(name, value, exdays, globalCookie);
	}
}
// Cookies speichern
function setCookie(c_name, value, exdays, global=false) {
	var exdate = new Date();
	exdate.setDate(exdate.getDate() + exdays);
	var secure = true; // set false for testing with http
	if ( !secure && window.location.host.split('.')[0] != 'testfirma' ) {
		secure = true; 
	}
	var c_value = escape(value) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString()) + ";" 
		+ ( global ? "path=/;" : '') + ( secure ? " secure;" : '');
	document.cookie = c_name + "=" + c_value;
}
// Cookies auslesen
function getCookie(name) {
	let cookie = {};
	document.cookie.split(';').forEach(function(el) {
		let split = el.split('=');
		cookie[split[0].trim()] = split.slice(1).join('=');
	})
	return name in cookie ? cookie[name] : null;
}

// Sonderzeichen ausfiltern
function strip_specialchars(value) {
	value = value.replace(/ /g, "-");
	value = value.replace(/ä/g, "ae");
	value = value.replace(/ö/g, "oe");
	value = value.replace(/ü/g, "ue");
	value = value.replace(/ß/g, "ss");
	value = value.replace(/\(/g, "");
	value = value.replace(/\)/g, "");
	value = value.replace(/\"/g, "");
	value = value.replace(/\'/g, "");
	value = value.replace(/\{/g, "");
	value = value.replace(/\}/g, "");
	value = value.replace(/\[/g, "");
	value = value.replace(/\]/g, "");
	value = value.replace(/\*/g, "");
	value = value.replace(/\°/g, "");
	value = value.replace(/\!/g, "");
	value = value.replace(/\./g, "");
	value = value.replace(/\:/g, "");
	value = value.replace(/\,/g, "");
	value = value.replace(/\;/g, "");
	value = value.replace(/\?/g, "");
	value = value.replace(/\&/g, "");
	value = value.replace(/\=/g, "");
	value = value.replace(/\_/g, "");
	value = value.replace(/\#/g, "");
	value = value.replace(/\$/g, "");
	value = value.replace(/\//g, "");
	return value;
}

var scrollbarWidth = 0;

function getScrollbarWidth() {
	// Create the measurement node
	var scrollDiv = document.createElement("div");
	scrollDiv.className = "scrollbar-measure";
	document.body.appendChild(scrollDiv);
	// Get the scrollbar width
	scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
	// Delete the DIV
	document.body.removeChild(scrollDiv);
}

// Funktionen registrieren, wenn DOM bereit ist
$(document).ready(function () {
	getScrollbarWidth();
	// if ($(window).width() < 1024 && $('.viewport-device-width').length <= 0) {
	//   $('meta[name=viewport]').attr('content', 'width=1024');
	// }
	// Allgemeine Funktionen für jeden Request durchführen
	afterRequest();
	// Sidebar ein- und ausblenden
	$(document).on('click', "#buttonSidebarHide", function () {
		$("#ui-navigation").hide();
		$("#ui-content").css("left", "10px");
		$("#buttonSidebarShow").show();
		$("#buttonSidebarShow").removeClass("active");
		$("#gplogo").show();
	});
	$(document).on('click', "#buttonSidebarShow, #gplogo", function () {
		$("#ui-navigation").show();
		$("#ui-content").css("left", "250px");
		$("#buttonSidebarShow").hide();
		$("#buttonSidebarShow").addClass("active");
		$("#gplogo").hide();
	});
	// AJAX-Content ausblenden
	$(document).on('click', ".ajax-content.remove", function (e) {
		// Element löschen
		if (e.target == this) {
			var hinweis = $('div.hinweis', this);
			removeHinweisMitPruefung(hinweis);
		}
	});
	// Tasten abfangen
	$(document).keydown(function (e) {
		if (e.keyCode == 27) { // Escape
			var ajax = $('div.ajax-content');
			if ($('.Zebra_DatePicker').not('.dp_hidden').length == 1) { // DatePicker vorhanden?
				var datepicker = $('.Zebra_DatePicker').not('.dp_hidden');
				$(datepicker).removeClass('dp_hidden');
				e.preventDefault();
				return false;
			} else if (ajax.length) { // AJAX-Content vorhanden?
				var hinweis = $('div.hinweis', ajax.last());
				removeHinweisMitPruefung(hinweis);
			}
		} else if (e.keyCode >= 37 && e.keyCode <= 40) { // Pfeiltasten
			if ($('.Zebra_DatePicker').not('.dp_hidden').length == 1) {
				var datepicker = $('.Zebra_DatePicker').not('.dp_hidden');
				var selected = $('.dp_selected', datepicker);
				if ($(selected).length == 0) {
					var current = $('.dp_current', datepicker);
					$(current).addClass('dp_selected');
					e.preventDefault();
					return false;
				}
				if (e.keyCode == 37) { // nach links
					var index = $('td', $(selected).parent()).index(selected);
					if (index == 0) {
						$(':last-child', $(selected).parent()).addClass('dp_selected');
					} else {
						$(selected).prev().addClass('dp_selected');
					}
					$(selected).removeClass('dp_selected');
				} else if (e.keyCode == 39) { // nach rechts
					var index = $('td', $(selected).parent()).index(selected);
					if (index == 6) {
						$(':first-child', $(selected).parent()).addClass('dp_selected');
					} else {
						$(selected).next().addClass('dp_selected');
					}
					$(selected).removeClass('dp_selected');
				} else if (e.keyCode == 38) { // nach oben
					var indexWoche = $('tr', $(selected).parent().parent()).index($(selected).parent());
					var indexTag = $('td', $(selected).parent()).index(selected);
					var prev = $(selected).parent().prev();
					var prevTag = $(':nth-child(' + (indexTag + 1) + ')', prev);
					if (indexWoche == 1 || $(prevTag).hasClass('dp_disabled')) { // erste Woche, ein Monat zurück
						$('.dp_previous', datepicker).click();
						var table = $('table.dp_daypicker', datepicker);
						var letzteZeile = $(':nth-child(' + (indexTag + 1) + ')', $('tr:last-child', table));
						if (!$(letzteZeile).hasClass('dp_disabled')) {
							$(letzteZeile).addClass('dp_selected');
						} else {
							var vorletzteZeile = $(':nth-child(' + (indexTag + 1) + ')', $('tr:last-child', table).prev());
							$(vorletzteZeile).addClass('dp_selected');
						}
					} else {
						$(prevTag).addClass('dp_selected');
					}
					$(selected).removeClass('dp_selected');
				} else if (e.keyCode == 40) { // nach unten
					var indexWoche = $('tr', $(selected).parent().parent()).index($(selected).parent());
					var indexTag = $('td', $(selected).parent()).index(selected);
					var next = $(selected).parent().next();
					var nextTag = $(':nth-child(' + (indexTag + 1) + ')', next);
					if (indexWoche == 6 || $(nextTag).hasClass('dp_disabled')) { // letzte Woche, ein Monat nach vorne
						$('.dp_next', datepicker).click();
						var table = $('table.dp_daypicker', datepicker);
						var ersteZeile = $(':nth-child(' + (indexTag + 1) + ')', $('tr:first-child', table).next());
						if (!$(ersteZeile).hasClass('dp_disabled')) {
							$(ersteZeile).addClass('dp_selected');
						} else {
							var zweiteZeile = $(':nth-child(' + (indexTag + 1) + ')', $('tr:first-child', table).next().next());
							$(zweiteZeile).addClass('dp_selected');
						}
					} else {
						$(nextTag).addClass('dp_selected');
					}
					$(selected).removeClass('dp_selected');
				}
				e.preventDefault();
				return false;
			} else if ( $(':focus').hasClass('highlightable') ) {
				var el = $(':focus');
				if (e.keyCode == 37) { // nach links
					var prev = $(el).parents('td').prev();
					if ( prev.length > 0 ) {
						$('.highlightable', $(prev)).focus();
					}
				} else if (e.keyCode == 39) { // nach rechts
					var next = $(el).parents('td').next();
					if ( next.length > 0 ) {
						$('.highlightable', $(next)).focus();
					}
				} else if (e.keyCode == 38) { // nach oben
					var index = $(el).parents('td').index();
					var prevRow = $(el).parents('tr').prev();
					if ( prevRow.length > 0 ) {
						$('.highlightable', $('td', prevRow).eq(index)).focus();
					}
				} else if (e.keyCode == 40) { // nach unten
					var index = $(el).parents('td').index();
					var nextRow = $(el).parents('tr').next();
					if ( nextRow.length > 0 ) {
						$('.highlightable', $('td', nextRow).eq(index)).focus();
					}
				}
			}
		} else if (e.keyCode == 13) { // Enter
			if ($('.Zebra_DatePicker').not('.dp_hidden').length == 1) {
				var datepicker = $('.Zebra_DatePicker').not('.dp_hidden');
				$('.dp_selected', datepicker).click();
				e.preventDefault();
				return false;
			} else if ($('.nummernblock').length > 0) {
				$('.stempeluhr-submit', $('.nummernblock')).click();
				e.preventDefault();
			} else {
				var focused = document.activeElement;
				if ( $(focused).hasClass('no-submit-on-enter') ) {
					e.preventDefault();
					$(focused).blur();
					return false;
				}
			}
		} else if ( e.keyCode >= 48 && e.keyCode <= 57
				|| e.keyCode >= 96 && e.keyCode <= 105
				|| e.keyCode >= 33 && e.keyCode <= 90
				|| e.keyCode >= 97 && e.keyCode <= 122 ) {
			if ($('.nummernblock').length > 0) {
				var numeric = true;
				if (e.keyCode >= 48 && e.keyCode <= 57) {
					var zahl = e.keyCode - 48;
				} else if ( e.keyCode >= 96 && e.keyCode <= 105 ) {
					var zahl = e.keyCode - 96;
				} else {
					var zahl = String.fromCharCode(e.keyCode);
					numeric = false;
				}
				if ( !numeric ) {
					$('input[type=text]', $('.nummernblock')).attr('type', 'password');
				}
				var input = $('input[type=text], input[type=password]', $('.nummernblock'));
				$(input).val($(input).val() + zahl);
			}
		}
	});
	$(document).keyup(function (e) {
		if ( $(':focus').hasClass('highlightable') ) {
			var el = $(':focus');
			if (e.keyCode == 37 || e.keyCode == 38 || e.keyCode == 39 || e.keyCode == 40 ) { // Pfeiltasten
				$(el).trigger('click'); // Inhalt markieren
			}
		}
	});
	// Close-Button für Fenster
	$(document).on('click', "input.close, a.close, button.close", function () {
		var hinweis = $(this).closest('div.hinweis, div.bubble');
		if (hinweis.hasClass('bubble')) {
			return;
		}

		if (!$(this).attr('data-remove')) {
			removeHinweisMitPruefung(hinweis);
		} else {
			$(hinweis).hide();
		}
		if ($(this).attr('data-formclose')) {
			var hinweis = $('form[name=' + $(this).attr('data-formclose') + ']').closest('div.hinweis');
			removeHinweis(hinweis);
		}
		if (!$(this).hasClass('follow')) {
			return false;
		}
	});
	$(document).on('change', "select.closeAfterChange", function () {
		var option = $('option:selected', this);
		if ( $(option).attr('data-close') !== undefined && $(option).attr('data-close') == 'false' )
			return;
		var bubble = $(this).closest('div.bubble');
		bubbleAnzeigenVerbergen(bubble, $(this), true);
	});
	// Button mit Rückfrage vor der Ausführung
	$(document).on('click', "a.confirm, a[data-confirm]", function () {
		var onlyOnChanges = $(this).attr('data-confirmonlyonchanges');
		if (onlyOnChanges != undefined) {
			var form = $(this).parents('form');
			var changed = false;
			$('input, select, textarea', form).each(function () {
				if ($(this).attr('data-changed')) {
					changed = true;
				}
			});
			if (!form || !changed) {
				callAjaxRequest($(this));
				return false;
			}
		}
		if ($(this).is('[data-label]')) {
			var linktext = $(this).attr('data-label');
		} else {
			var linktext = $(this).text();
		}
		if ($(this).is('[data-title]')) {
			var title = $(this).attr('data-title');
		} else {
			var title = linktext;
		}
		var linkhref = $(this).attr('href');
		var hinweistext = $(this).attr('data-confirm');
		var transition = $(this).attr('data-transition');
		var urlupdate = $(this).attr('data-urlupdate');
		var mode = $(this).attr('data-mode');
		if ($(this).attr('data-buttonclass')) {
			var linkclass = $(this).attr('data-buttonclass');
		} else if ($(this).attr('class')) {
			var linkclass = $(this).attr('class');
			linkclass = linkclass.replace('confirm', '');
			linkclass = linkclass.replace('left', '');
			linkclass = linkclass.replace('right', '');
			linkclass = linkclass.replace(/icon-button.*?(\s|$)/g, '');
		} else {
			var linkclass = '';
		}
		if ($(this).attr('data-ajax') != undefined) {
			linkhref += '" data-ajax="' + $(this).attr('data-ajax');
			linkhref += '" data-target="' + $(this).attr('data-target');
			if ($(this).attr('data-package') != undefined) {
				linkhref += '" data-package="' + $(this).attr('data-package');
			}
			linkclass += (linkclass != '' ? ' ' : '') + 'close';
		}
		if ($(this).attr('data-id') != undefined) {
			linkhref += '" data-id="' + $(this).attr('data-id');
		}
		if (hinweistext.length < 100) {
			var css = ' small';
		} else {
			var css = '';
		}

		$('#main').append(`
			<div class="ajax-content" style="z-index:${$.topZIndex()}">
				<div class="hinweis confirm ${css}">
					<div class="hinweis__surface">
						<div class="header">${language('js_bestaetigung')}: ${title}</div>
						<div class="content">${hinweistext}</div>
						<div class="footer">
							<button type="button" class="button close">${language('js_abbrechen')}</button>
							<a
								href="${linkhref}"
								class="${linkclass}"
								data-transition="${transition}"
								data-urlupdate="${urlupdate}"
								data-mode="${mode}"
							>${linktext}</a>
						</div>
					</div>
				</div>
			</div>
		`);
		return false;
	});
	// Submit mit Rückfrage vor der Ausführung
	$(document).on('click', "input[type=submit][data-confirm], button[type=submit][data-confirm]", function () {
		submitConfirm(this);
		return false;
	});
	// Zeile nach Confirm löschen oder leeren
	$(document).on('click', '[data-removezeile]', function () {
		var zeile = $(this).attr('data-removezeile');
		var addAnzahl = $(this).attr('data-addanzahl');
		var table = $('table[data-addanzahl=' + addAnzahl + ']');
		var tr = $('tr[data-zeile=' + zeile + ']', table);
		if ($(tr).is(':last-child')) {
			$('input.geanedert', tr).val('0');
			$('input', tr).each(function () {
				$(this).val('');
			});
		} else {
			$(tr).remove();
		}
	});
	// Zeile ohne Confirm löschen oder leeren
	$(document).on('click', 'button.delete', function () {
		if ($(this).attr('data-confirm') !== undefined) {
			return;
		}
		var tr = $(this).closest('tr');
		if ($(tr).is(':last-child')) {
			$('input.geanedert', tr).val('0');
			$('input', tr).each(function () {
				$(this).val('');
			});
		} else {
			$(tr).remove();
		}
	});
	// Radio Buttons deselektieren
	$(document).on('click', 'input[type=radio]', function () {
		var changeRadioChecked = (radio) => {
			if ( radio.is('.checked-radio') ) {
				radio.prop("checked",false).removeClass("checked-radio");
				return;
			}
			$("input:radio[name='"+radio.prop("name")+"'].checked-radio").removeClass("checked-radio");
			radio.addClass("checked-radio");
		}
		var radio = $(this);
		var classes = radio.attr('class') != undefined ? radio.attr('class').split(/\s+/) : [];
		changeRadioChecked(radio);
		classes.forEach(c => {
			if (!c || c.length == 0) return;
			$('input[data-group='+c+']').each(function() {
				changeRadioChecked( $(this) );
			});
		});
	});
	// Checkboxen gruppiert bearbeiten
	$(document).on('change', 'input[data-group]', function () {
		if ($(this).attr('data-changeunterjob') != undefined) {
			return;
		}
		if ($(this).attr('type') == 'radio') {
			return;
		}
		var group = $(this).attr('data-group');
		if ($(this).attr('type') == 'checkbox') {
			if ($(this).prop('checked')) {
				changeInputGroupChecked(group, true, !$(this).hasClass(group));
			} else {
				changeInputGroupChecked(group, false, !$(this).hasClass(group));
			}
		} else {
			if ($(this).hasClass(group)) {
				$('input.' + group).not(this).val($(this).val());
			} else {
				$('input.' + group).not(this).val($(this).val()).trigger('change');
			}
			if ($(this).hasClass('status')) {
				$('input.' + group).each(function () {
					setStatus(this);
				});
			}
		}
	});
	$(document).on('click', 'a[data-group], button[data-group]', function () {
		var group = $(this).attr('data-group');
		var origname = $(this).attr('data-origname');
		if (group != undefined && group != '' && origname != undefined && origname != '') {
			var input = $('input[name=' + origname + ']');
			if ($(input).attr('type') == 'checkbox') {
				changeInputGroupChecked(group, $(input).prop('checked'), true);
			} else {
				$('input.' + group).val($(input).val()).trigger('change');
			}
		}
	});
	function changeInputGroupChecked(group, checked, triggerChange) {
		$('input.' + group).each(function () {
			var tr = $(this).closest('tr');
			if (tr.length > 0 && $(tr).attr('data-zeile') != undefined) {
				var zeile = $(tr).attr('data-zeile');
				var geaendert = $('input.geaendert', tr);
				if (zeile.indexOf('neu') == 0 && geaendert.length > 0 && $(geaendert).val() == 0) {
					return;
				}
			}
			if ($(this).is(':visible') && !$(this).is(':disabled')) {
				var old = $(this).prop('checked');
				$(this).prop('checked', checked);
				if ( $(this).attr('data-toggleclass') !== undefined && $(this).prop('checked') != old ) {
					toggleClass(this);
				}
			}
		});
		if (triggerChange) {
			$('input.' + group).each(function () {
				$(this).trigger('change');
			});
		}
	}
	$(document).on('change', 'input', function () {
		var input = $(this);
		if ($(input).attr('class') == undefined) {
			return;
		}
		var classList = $(input).attr('class').split(/\s+/);
		$.each(classList, function (index, item) {
			if (item == undefined || item == '') {
				return;
			}
			try {
				var groupcb = $('input[data-group=' + item + ']');
			} catch (e) {
				var elem = $(input).get(0).outerHTML;
				elem = elem.substr(0, elem.indexOf('>')+1);
				elem = elem.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
				error('Invalid item name: ' + item + ', elem: ' + elem);
			}
			if ($(groupcb).length > 0) {
				var checked = true;
				$('input.' + item).each(function () {
					if (!$(this).is(':visible')) {
						return;
					}
					var tr = $(this).closest('tr');
					if (tr.length > 0 && $(tr).attr('data-zeile') != undefined) {
						var zeile = $(tr).attr('data-zeile');
						var geaendert = $('input.geaendert', tr);
						if (zeile.indexOf('neu') == 0 && geaendert.length > 0 && $(geaendert).val() == 0) {
							return;
						}
					}
					if (!$(this).prop('checked')) {
						checked = false;
					}
				});
				$(groupcb).prop('checked', checked);
			}
		});
	});
	$(document).on('change', 'select[data-group]', function () {
		var group = $(this).attr('data-group');
		if ($(this).hasClass(group)) {
			$('select.' + group).not(this).val($(this).val());
		} else {
			$('select.' + group).not(this).val($(this).val()).trigger('change');
		}
	});
	// Infos in Unterjobs übernehmen
	$(document).on('change', 'input[data-changeunterjob], select[data-changeunterjob], textarea[data-changeunterjob]', function () {
		requestChangeUnterjob(this);
	});
	// Arbeitneherüberlassung - Dialog für zukünftige Jobs
	$(document).on('change', 'input[name=input-isdefaultpersonnelleasingjob]', function () {
		if ( $('input[name=input-ispersonnelleasing]') && $('#change-pljobs-in-future') 
				&& $('input[name=input-ispersonnelleasing]').first().prop('checked') == true ) {
			if ( $('#change-pljobs-in-future').hasClass('hidden') ) {
				$('#change-pljobs-in-future').removeClass('hidden');
			} else {
				$('#change-pljobs-in-future').toggle();
			}
		}
	});

	// Submits außerhalb eines Forms absenden
	$(document).on('click', "input[type=submit][data-formname], button[data-formname], a[data-formname]", function () {
		if (!$(this).hasClass('removeclicked')) {
			// Formname auslesen
			var formname = $(this).attr('data-formname');
			if (formname == undefined || formname == '') {
				var html = $(this).clone().wrap('<p></p>').parent().html();
				error("Formname leer: " + escapeHtml(html));
			} else {
				// Evtl. Value des Submit-Buttons übernehmen und mitsenden
				if ($(this).attr('name')) {
					if ($(this).attr('data-value')) {
						var value = $(this).attr('data-value');
					} else {
						var value = $(this).attr('value');
					}
					$("form[name=" + formname + "]").append('<input type="hidden" name="' + $(this).attr('name') + '" value="' + value + '" class="deleteaftersubmit" />');
				}
				if (!$(this).hasClass('keepclicked')) {
					$('[data-clicked]').removeAttr('data-clicked');
				}
				$(this).attr('data-clicked', 'true');
				$(this).attr('data-follow', 'true');
				// Formular absenden
				$("form[name=" + formname + "]").submit();
				if ($(this).is('a')) {
					return false;
				}
			}
		}
	});
	// Buttons mit "data-clicked" versehen (um Value des Buttons für AJAX-Calls auszulesen)
	$(document).on('click', "input[type=submit], button[type=submit]", function () {
		if (!$(this).hasClass('noclicked')) {
			$('[data-clicked]').removeAttr('data-clicked');
			$(this).attr('data-clicked', 'true');
		}
	});
	// "Clicked"-Attribut entfernen
	$(document).on('click', '.removeclicked', function () {
		if ($(this).is('data-formname')) {
			formname = $(this).attr('data-formname');
			$('input[data-clicked]', formname).removeAttr('data-clicked');
			$('button[data-clicked]', formname).removeAttr('data-clicked');
			$('select[data-clicked]', formname).val("");
			$('select[data-clicked]', formname).removeAttr('data-clicked');
		} else {
			$('input[data-clicked]').removeAttr('data-clicked');
			$('button[data-clicked]').removeAttr('data-clicked');
			$('select[data-clicked]').val("");
			$('select[data-clicked]').removeAttr('data-clicked');
		}
	});
	// Radio- oder Checkboxen mit Rückfrage
	$(document).on('click', 'input[type=checkbox][data-confirm], input[type=radio][data-confirm]', function () {
		const element = $(this);
		const id = element.attr('id');
		const label = element.attr('data-label') || language('js_aendern');
		const content = element.attr('data-confirm');
		const buttonClass = element.attr('data-buttonclass') || '';

		$('#main').append(`
			<div class="ajax-content" style="z-index: ${$.topZIndex()}">
				<div class="hinweis">
					<div class="hinweis__surface">
						<div class="header">${language('js_bestaetigung')}: ${label}</div>
						<div class="content">${content}</div>
						<div class="footer">
							<button type="button" class="button close">${language('js_abbrechen')}</button>
							<button
								type="button"
								class="button button--primary close ${buttonClass} keepclicked"
								data-labelfor="${id}"
							>${label}</button>
						</div>
					</div>
				</div>
			</div>
		`);
		return false;
	});
	// Formularfunktionen
	$(document).on('submit', 'form', function () {
		var form = $(this);
		// Submit-Button auslesen, evtl. Werte überschreiben
		var submit = $('[data-clicked]', form);
		// Signatures speichern
		$('canvas.signature',form).each(function() {
			var signaturePad = $(this).data('signaturePad');
			$('input.signature-holder', $(this).parent()).val(signaturePad.toDataURL());
		});
		// Formular per AJAX senden wenn nicht anders definiert
		if (!$(form).attr('data-submit') && !(submit).attr('data-submit')) {
			// GPS-Koordinaten ermitteln
			if ($('input[name=gps-lon]', this).length > 0 && $('input[name=gps-lat]', this).length > 0 && navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(function (position) {
					$('input[name=gps-lon]', form).val(position.coords.longitude);
					$('input[name=gps-lat]', form).val(position.coords.latitude);
					sendForm(form, submit);
				}, function (error) {
					sendForm(form, submit);
				});
			} else {
				sendForm(form, submit);
			}
			return false;
		}
	});
	var inputAutosubmit;
	// Formular automatisch absenden
	$(document).on('change input', '.autosubmit', function (e) {
		if (this.prevValue === this.value && $(this).attr('type') != 'checkbox' && $(this).attr('type') != 'radio' ) {
			return;
		}
		
		var input = this;

		clearTimeout(inputAutosubmit);
		inputAutosubmit = setTimeout(function() {
			input.prevValue = input.value;
			if (!$(input).attr('data-submit') || $(input).attr('data-submit') == 'no-ajax' ) {
				if ($(input).is('button') || $(input).is('input[type=submit]') || $(input).is('input[type=button]')
					|| $(input).is('input[type=checkbox]') || $(input).is('input[type=radio]') || $(input).is('select')
					|| $(input).attr('data-ajax') != undefined) {
					$('[data-clicked]').removeAttr('data-clicked');
					$(input).attr('data-clicked', 'true');
				}
				if ($(input).is('select')) {
					var option = $('option:selected', input);
					if ($(option).attr('data-confirm') != undefined) {
						submitConfirm(option);
					} else {
						var form = $(input).closest('[data-form]');
						if ( $(form).length > 0 ) {
							sendForm($(form),$(input));
						} else {
							$(input).closest('form').submit();
						}
					}
				} else {
					var form = $(input).closest('[data-form]');
					if ( $(form).length > 0 ) {
						sendForm($(form),$(input));
					} else {
						$(input).closest('form').submit();
					}
				}
			} else {
				$('form[name="' + $(input).attr('data-submit') + '"]').submit();
				$('#' + $(input).attr('data-submit')).trigger('click');
			}
		}, 600);
		return false;
	});
	// Suchergebnisse mit KeyUp/Down durchgehen
	$(document).on('keyup', '[data-results]', function (e) {
		if (e.keyCode == 40 || e.keyCode == 38) {
			var results = $('#' + $(this).attr('data-results'));
			var active = $('.active', results);
			if (e.keyCode == 40) { // nach unten
				if (active.length > 0) {
					var next = $(active).nextAll().not(".headline").first();
					$(active).removeClass('active');
				} else {
					var next = $('li', results).not('.headline').first();
				}
				$(next).addClass('active');
				if (next.position() != null && next.position().top > 50) {
					$('#' + $(this).attr('data-results')).closest('.scroll-y').animate({ scrollTop: $(next).position().top - 50 });
				}
			} else if (e.keyCode == 38) { // nach oben
				if (active.length > 0) {
					var prev = $(active).prevAll().not(".headline").first();
					$(active).removeClass('active');
				} else {
					var prev = $('li', results).not('.headline').last();
				}
				$(prev).addClass('active');
				if (prev.position() != null && prev.position().top > 50) {
					$('#' + $(this).attr('data-results')).closest('.scroll-y').animate({ scrollTop: $(prev).position().top - 50 });
				}
			}
		} else if (e.keyCode == 13) { // Enter
			var results = $('#' + $(this).attr('data-results'));
			var active = $('.active', results);
			if (active.length > 0 && $(active).attr('data-ajax') != undefined) {
				callAjaxRequest($('a', active));
			} else {
				$('a', active).trigger('click');
			}
		}
	});
	// Enter bei Result-Sets abfangen
	$(document).on('keydown', '[data-results]', function (e) {
		if (e.keyCode == 13) {
			e.preventDefault();
		}
	});
	// Im Formular vermerken, ob Änderungen vollzogen wurden
	$(document).on('change keyup', 'input, select, textarea', function () {
		if (!$(this).hasClass('unimportant')) {
			$(this).attr('data-changed', 'true');
		}
	});
	// Labels bei Check- oder Radioboxen mit class="active" versehen
	$(document).on('change', 'input[type=checkbox], input[type=radio]', function () {
		// Alle Boxen mit diesem Namen durchgehen
		var name = $(this).attr('name');
		if (name != undefined && name != '' && name !== 'driversLogIds[]' && name !== 'driversLogId') {
			$('input[name="' + name + '"]').each(function () {
				var label = $(this).parents('label');
				if (!label && $(this).attr('id')) {
					label = $('label[for=' + $(this).attr('id') + ']');
				}
				if (label) {
					var checked = $(this).prop('checked');
					if (checked) {
						var color = $(this).attr('data-color');
						color = color ? ' ' + color : '';
						$(label).addClass('active' + color);
					} else {
						$(label).removeClass('active');
					}
				}
			});
		}
	});
	// Mehrtägige Termine mit Hover-Event versehen
	$(document).on('mouseover mouseout', 'a.termin[data-terminid], a.termin[data-parentid], li.termin[data-terminid]', function (event) {
		var terminid = $(this).attr('data-terminid');
		if ($(this).is('a')) {
			var link = $('a.termin[data-terminid=' + terminid + ']');
			var child = $('a.termin[data-parentid=' + terminid + ']');
		} else {
			var link = $('li.termin[data-terminid=' + terminid + ']');
			var child = $('li.termin[data-parentid=' + terminid + ']');
		}
		if (event.type == 'mouseover') {
			$(link).addClass('hover');
			$(child).addClass('hover');
		} else {
			$(link).removeClass('hover');
			$(child).removeClass('hover');
		}
		var parentid = $(this).attr('data-parentid');
		if (parentid !== undefined) {
			var link = $('a.termin[data-parentid=' + parentid + ']');
			var linkParent = $('a.termin[data-terminid=' + parentid + ']');
			if (event.type == 'mouseover') {
				$(link).addClass('hover');
				$(linkParent).addClass('hover');
			} else {
				$(link).removeClass('hover');
				$(linkParent).removeClass('hover');
			}
		}
	});
	// Links per AJAX laden
	$(document).on('click', 'a[data-ajax], input[type=button][data-ajax], div.button[data-ajax]', function (e) {
		if ( $(e.target).parents('a').length > 0 && $(this).is('div') ) {
			return true;
		}
		if ($(this).attr('data-confirm') == undefined) {
			callAjaxRequest($(this));
			return false;
		}
	});
	// Select-Felder per AJAX auswerten
	$(document).on('change', 'select[data-ajax]', function () {
		if (!$(this).hasClass('autosubmit')) {
			callAjaxRequest($(this));
			return false;
		}
	});
	// Hover-Links per AJAX laden
	$(document).on('mouseenter', '[data-hover-ajax]', function () {
		var el = $(this);
		$(el).addClass('hasFocus');
		setTimeout(function () {
			if ($(el).hasClass('hasFocus')) {
				callAjaxRequest(el, true);
			}
		}, 500);
		return false;
	});
	$(document).on('mouseleave', 'a[data-hover-ajax]', function () {
		$(this).removeClass('hasFocus');
	});
	// Navigation bei anderen Links anpassen
	$(document).on('click', '[data-navi]', function (e) {
		var navigation = $('#mainnavigation');
		var link = $(this).attr('href');
		$('li', navigation).each(function () {
			var aktLink = $('a', this).attr('href');
			if (link.indexOf(aktLink) == 0) {
				$(this).addClass('active');
				$('ul', this).show();
			} else {
				$(this).removeClass('active');
				$('ul', this).hide();
			}
		});
	});
	// Termintitel automatisch in Kopfzeile aktualisieren
	$(document).on('keyup', '[data-write]', function () {
		var write = $(this).attr('data-write');
		var titel = $(this).val();
		$('#' + write).text(titel);
	});
	// Akvititäten anzeigen oder ausblenden
	$(document).on('click', '#notifications b', function () {
		if ($('#notifications ul').is(':hidden')) {
			$('#notifications ul').slideDown(400);
			$('#notifications b small').fadeOut(400);
			$('#notifications b a').fadeIn(400);
		}
		else {
			$('#notifications ul').slideUp(400);
			$('#notifications b small').fadeIn(400);
			$('#notifications b a').fadeOut(400);
		}
	});
	// Elemente als Label für Check- und Radioboxen verwenden
	$(document).on('click keyup change', '[data-labelfor]', function (e) {
		if (e.keyCode == 40 || e.keyCode == 38 || e.keyCode == 13 || e.keyCode == 27 || e.keyCode == 9) {
			return;
		}
		var input = $('#' + $(this).attr('data-labelfor'));
		//console.log($(input));
		if (!$(input).is(':visible')) {
			return;
		}
		if ($(input).hasClass('number')) {
			var value = $(this).val();
			value = parseFloat(value);
			if (!isNaN(value) && value == 0) {
				return;
			}
		}
		if ($(input).attr('type') == 'radio' || $(input).attr('type') == 'checkbox') {
			$(input).prop('checked', true);
		} else if ($(input).is('select')) {
			if ( $('option[value='+$(this).val()+']', input).length == 0 )
				$(input).append('<option value="'+$(this).val()+'"></option>');
			$(input).val($(this).val()).trigger('change');
		} else {
			$(input).prop('checked', false);
		}
	});
	$(document).on('change', 'input[type=checkbox][data-hiddenfield]', function() {
		$('input[name='+$(this).attr('data-hiddenfield')+']').val($(this).prop('checked') ? '1' : '0').trigger('change');
	});
	// Label-Elemente als Label für Links etc. verwenden
	$(document).on('click', '[data-clickfor]', function () {
		var elem = $('#' + $(this).attr('data-clickfor'));
		if ( $(elem).length == 0 ) {
			elem = $('.' + $(this).attr('data-clickfor')).first();
		}
		if (!$(elem).is('a') && !$(elem).is('input') && !$(elem).is('button') && $('a', elem).length > 0) {
			elem = $('a', elem).first();
		}
		if ( $(elem).parents('.main-navigation').length > 0 || $(elem).parents('.sub-navigation').length > 0 ) {
			var event = document.createEvent('Event');
			event.initEvent('click', true, true);
			elem[0].dispatchEvent(event);
		} else {
			$(elem).click();
		}
	});
	// Hinweise
	$(document).on('click', '[data-hinweis]', function () {
		var hinweis = $('#' + $(this).attr('data-hinweis'));
		if ($(hinweis).is(':visible')) {
			hideHinweis(hinweis);
		} else {
			showHinweis(hinweis);
		}
	});
	// Suchfeld für Mitarbeiter
	$(document).on('keyup change', 'input[data-search], select[data-search]', function () {
		durchsucheListe(this);
	});
	// Suchfeld für Listen
	$(document).on('keyup change', 'ul li.search input', function () {
		var search = $(this).val();
		var search = search.replace(/ /g, "");
		var searchreg = new RegExp(search, 'i');
		var ul = $(this).closest('ul');
		if (search == '') {
			$('li', ul).show();
		} else {
			$('li', ul).not('.search').each(function () {
				if ($(this).text().search(searchreg) == -1 || $(this).hasClass('headline')) {
					$(this).hide();
				} else {
					$(this).show();
				}
			});
		}
	});
	// Mitarbeiter bearbeiten (Namen aktualisieren)
	$(document).on('keyup', 'input.createanmeldename', function () {
	var form = $(this).closest('form');
	var nachname = $('input.createanmeldename[name=nachname]',form).val();
	var vorname = $('input.createanmeldename[name=vorname]',form).val();
	var firma = $('input.createanmeldename[name=firma]',form).val();
	var id = $('input[name=mitarbeiterid]',form).val();
	if ( firma != undefined && firma != '' ) {
		$('h1.employee-name',form).text(firma);
	} else if ( firma == undefined && ( vorname != '' || nachname != '' ) ) {
		$('h1.employee-name',form).text(nachname+', '+vorname);
	}
	if ( id == 'neu' || id == 'kopieren' ) {
		if ( firma != undefined ) {
			$('input[name=name]',form).val(strip_specialchars(firma.toLowerCase()));
		} else {
			$('input[name=name]',form).val(strip_specialchars(vorname.toLowerCase().substr(0,1))+'.'+strip_specialchars(nachname.toLowerCase()));
		}
	}
	});
	// Farbschema live ändern
	$(document).on('click', 'input.farbschema-auto', function () {
		if ( window.matchMedia ) {
			if ( $(this)[0].checked ) {
				config.isAutoTheme = true;
				updateFarbschema()
			} else {
				config.isAutoTheme = false;
			}
		}
	});
	// Nach unten scrollen, wenn ein Eintrag in einer Liste hinzugefügt werden soll
	$(document).on('click', '.tableadd input', function () {
		if ($(this).attr('data-target')) {
			var target = $(this).attr('data-target');
		} else if ($(this).closest('form').attr('data-target')) {
			var target = $(this).closest('form').attr('data-target');
		} else {
			var target = 'content';
		}
		$('#' + target).scrollTop($('#' + target).prop('scrollHeight'));
	});
	// Box mit Tabs
	$(document).on('click', '[data-folder]', function () {
		var link = $(this);
		var box = $(link).attr('data-folder');
		var href = $(link).attr('href');
		var gp = $(link).closest('[data-foldergroup]');
		var foldergroup = $(gp).attr('data-foldergroup');
		// Alle Folder verstecken und inaktiv setzen
		$('*', gp).removeClass('active');
		// entfernt teilweise zu viel, daher nur eine Ebene (Skilllevel im Anfrage-Dialog)
		$('[data-foldergroup=' + foldergroup + '] > *').removeClass('active');
		$('div[data-foldergroup=' + foldergroup + ']').not('.toolbar').not('.button-group').hide().removeClass('hidden');
		if ($(link).closest('li').length > 0) {
			$(link).parent().addClass('active');
		} else {
			$(link).addClass('active');
		}
		var folder = $('div#' + box);
		$(folder).show();
		var ajax = $(folder).attr('data-ajax');
		if (ajax != undefined && $(folder).html() == '') {
			// var src = $('img.loading').attr('src');
			// $(folder).html('<div class="ajax-loading" id="loading-ajax"><img src="' + src + '" /></div>');
			var url = $(folder).attr('data-url');
			ajaxRequest(folder, url != undefined ? url : location.href, $(folder).attr('data-ajax'),
				$(folder).attr('id'), false, 'fade', undefined, undefined, undefined, true,
				$(folder).attr('data-package'), $(folder).attr('data-mode'), undefined, function () {
					if ($('table.mitarbeiteranfragenliste', folder).length > 0) {
						var kategorie = $(link).attr('data-kategorie');
						if (kategorie != undefined && kategorie != '') {
							var bubble = $(link).closest('div.bubble');
							$('select[data-field=kategorie-1]', bubble).val(kategorie).trigger('change');
							$('tr', bubble).each(function () {
								if ($(this).css('display') != 'none') {
									$('select.kategorie', this).val(kategorie).trigger('change');
								}
							});
							$('input[data-group=mitarbeiteranfragen]', bubble).prop('checked', false);
						} else {
							var kat1 = $('select[data-field=kategorie-1]').val();
							var kat2 = $('select[data-field=kategorie-2]').val();
							var zertifikat = $('select[data-field=zertifikat]').val();
							var skilllevel = $('input[data-field=skilllevel]').val();
							var umkreis = $('select[data-field=umkreis]').val();
							var sprache = $('select[data-field=sprache]').val();
							if (kat1 != '' || kat2 != '' || zertifikat != '' || skilllevel != '0' || (umkreis != undefined && umkreis != '') || sprache != '') {
								durchsucheMaListe();
							}
						}
					}
				});
		}
		$('input[data-foldergroup=' + foldergroup + ']').val(box);
		$('a[data-foldergroup=' + foldergroup + ']').attr('href', href);
		return false;
	});
	// Cookie für Retina-Auflösung speichern
	if (window.devicePixelRatio >= 2) {
		setLocalData('retina', 'true', 30, true);
	}
	if ( iOSOrAndroid() ) {
		var blockNewRequestDialog = getLocalData('blockNewRequestDialog', true);
		setLocalData('blockNewRequestDialog', blockNewRequestDialog === null ? 'true' : blockNewRequestDialog, 30, true, true);
	}
	// Farbschema automatisch nach Browser/ OS-Einstellung ändern
	if ( window.matchMedia ) {
		updateFarbschema();
		var darkThemeQuery = window.matchMedia('(prefers-color-scheme: dark)');
		if ( darkThemeQuery.addEventListener ) {
			darkThemeQuery.addEventListener('change',({ matches }) => {
				updateFarbschema();
				showFarbschemaHinweis();
			});
		} else if ( darkThemeQuery.addListener ) {
			// Safari
			darkThemeQuery.addListener(({ matches }) => {
				updateFarbschema();
				showFarbschemaHinweis();
			});
		}
		
	}
	// Input-Felder in <label> setzen immer den Status checked
	$(document).on('click', 'label input', function () {
		if ($(this).attr('type') != 'checkbox' && $(this).attr('type') != 'radio') {
			// Checkbox immer auf false setzen, damit der Browser es über die Standardfunktion direkt auf true setzt
			$('input[type=checkbox], input[type=radio]', $(this).closest('label')).prop('checked', false);
		}
	});
	// Tippen in Input-Feldern innerhalb <label> setzt immer den Status checked
	$(document).on('keydown', 'label input', function () {
		if ($(this).attr('type') != 'checkbox' && $(this).attr('type') != 'radio') {
			$('input[type=checkbox], input[type=radio]', $(this).closest('label')).prop('checked', true);
		}
	});
	// Titel für Export ändern
	$(document).on('click', '#termin-titel-aendern', function () {
		$(this).closest('div').hide();
		$('#termin-titel-export').closest('div').show().removeClass('hidden');
	});
	// Einstellungsboxen abhängig von Radio-Buttons ein- und ausblenden
	$(document).on('change', 'select[data-togglegroup], input[data-togglegroup]', function () {
		var togglegroup = $(this).attr('data-togglegroup');
		if (togglegroup != undefined && togglegroup != '') {
			$('[data-togglegroup=' + togglegroup + ']').not(this).not('input').not('select').hide();
			if ($(this).is('select') && $('option:selected', this).attr('data-toggleid') != undefined) {
				var toggleid = $('option:selected', this).attr('data-toggleid');
				var focus = $('option:selected', this).attr('data-focus');
			} else {
				var toggleid = $(this).val();
				var focus = undefined;
			}
			if (toggleid != undefined) {
				var toggleids = toggleid.split(',');
				for (var i = 0; i < toggleids.length; i++) {
					$('[data-togglegroup=' + togglegroup + ']').each(function () {
						var id = $(this).attr('data-toggleid');
						if (id != undefined && id.indexOf(',') == -1 && id == toggleids[i]) {
							$(this).show().removeClass('hidden');
						} else if (id != undefined && id.indexOf(',') > 0) {
							var ids = id.split(',');
							if (in_array(ids, toggleids[i])) {
								$(this).show().removeClass('hidden');
							}
						}
					});
				}
			}
			if ( focus != undefined ) {
				var input = $('input[name='+focus+']');
				if ( $(input).attr('type') == 'hidden' ) {
					$('div[data-fremdid='+focus+']').trigger('click');
				} else {
					$('input[name='+focus+']').focus();					
				}
			}
		}
	});
	// Checkbox abhängig von Dropdown-Boxen aktivieren oder deaktivieren
	$(document).on('change', 'select[data-toggledisabled]', function () {
		var toggleid = $(this).attr('data-toggledisabled');
		var disabled = $(this).find(':selected').attr('data-toggle');
		$('#' + toggleid).attr('disabled', disabled == 'true' ? true : false);
	});
	// Button-Text abhängig von Dropdown ändern
	$(document).on('change', 'select[data-labelchange]', function () {
		var id = $(this).attr('data-labelchange');
		$('#' + id).val($(this).find(':selected').text());
	});
	// Timepicker immer in den Vordergrund bringen
	$(document).on('click', 'input.zeit', function () {
		$('.ui-timepicker-wrapper').topZIndex();
	});
	// Klasse durch Select-Field hinzufügen (zur Tabellenzeile)
	$(document).on('change', 'select[data-toggleaddclass]', function () {
		var toggleclass = $(this).attr('data-toggleclass');
		if ($(this).find(':selected').attr('data-toggle') == 'true') {
			$(this).parents('tr').addClass(toggleclass);
		} else {
			$(this).parents('tr').removeClass(toggleclass);
		}
	});
	// Reset für Formularfelder
	$(document).on('click', '[data-reset]', function () {
		$('input[name=' + $(this).attr('data-reset') + ']').val('');
	});
	// Unterschrift resetten
	$(document).on('click', '.m-signature-pad a.reset', function() {
		var wrapper = $(this).parents('.m-signature-pad');
		var signaturePad = $('canvas', wrapper).data('signaturePad');
		signaturePad.clear();
	});
	// Benötigte Mitarbeiter-Anzahl aktualisieren
	$(document).on('keyup', 'input.maanzahl', function () {
		mitarbeiterlisteAnpassen($(this).closest('table.liste'));
	});
	// Inhalt von Nummern-Feldern bei Focus komplett markieren
	$(document).on('click', 'input.number, input.copy, input.zeit', function () {
		$(this).select();
	});
	// Status im Job ändern
	$(document).on('change', 'input.status', function () {
		setStatus(this);
	});
	function setStatus(input) {
		var holder = $(input).closest('.status-holder');
		var anker = $('a.status', holder);
		if ( $(anker).attr('data-bubble') !== undefined ) {
			var status = $('a.status-choice[data-value=' + $(input).val() + ']', $('div#'+$(anker).attr('data-bubble')));
		} else {
			var status = $('a.status-choice[data-value=' + $(input).val() + ']');
		}
		$(anker).attr('class', $('span', status).attr('class'));
		if ($(anker).text() != '') {
			$(anker).text($(status).first().text());
		}
	}
	// Wert für Input-Feld setzen
	$(document).on('click', '[data-valuefor]', function () {
		var form = $(this).closest('form');
		var input = $(this).attr('data-valuefor');
		var value = $(this).attr('data-value');
		if ($(form).length > 0) {
			input = $('input[name=' + input + ']', form);
		} else {
			input = $('input[name=' + input + ']');
		}
		$(input).val(value).trigger('change');
		var holder = $('.icon-holder', $(input).parent());
		if ( $(holder).length > 0 ) {
			$(holder).html($(this).html());
		}
		
	});
	// Reset Icon
	$(document).on('click', '.icon-resetter', function () {
		var holder = $('.icon-holder', $(this).parent().parent());
		if ( $(holder).length > 0 ) {
			$(holder).html('');
		}
	});
	// Neue Zeilen für weitere Einträge an einer Tabelle einfügen
	$(document).on('keydown change', 'table.autoadd input, table.autoadd select, table.autoadd textarea', function () {
		if ($(this).hasClass('unimportant') ) {
			return true;
		}
		var tr = $(this).closest('tr');
		$('input.geaendert', tr).val('1');
		$(tr).removeClass('opacity');
		$(tr).removeClass('tablesorter-ignoreRow');
		var zeile = $(tr).attr('data-zeile');
		var hidden = $(this).attr('type') == 'hidden';
		if ( hidden ) {
			if ( $('[data-fremdid='+$(this).attr('name')+']').length > 0 )
				hidden = false;
		}
		if (zeile != undefined && zeile.indexOf('neu') == 0 && !hidden
				&& ( $(tr).is(':last-child') || $(tr).next().attr('data-zeile') == undefined ) ) {
			var table = $(this).closest('table');
			var addAnzahl = $(table).attr('data-addanzahl');
			var autotitle = $(table).attr('data-autotitle');
			if (addAnzahl === undefined) {
				error('JS-Fehler: data-addanzahl für table.autoadd nicht vorhanden!');
			}
			var zeilennummer = parseInt($('input[name=' + addAnzahl + ']').val()) + 1;
			if (!isNaN(zeilennummer)) {
				var trNeu = $(tr).clone();
				var re = new RegExp(zeile, 'g');
				$(trNeu).addClass('opacity');
				$(trNeu).addClass('tablesorter-ignoreRow');
				$(trNeu).html($(trNeu).html().replace(re, 'neu' + zeilennummer));
				$(trNeu).attr('data-zeile', 'neu' + zeilennummer);
				if ( $(trNeu).attr('id') != undefined )
					$(trNeu).attr('id', $(trNeu).attr('id').replace(re, 'neu' + zeilennummer));
				$('input.vorbelegungErfolgt', trNeu).removeClass('vorbelegungErfolgt');
				$('input.geaendert', trNeu).val('0');
				$('input.nummer', trNeu).val(parseInt($('input.nummer', trNeu).val())+1);
				$('[tabindex]', trNeu).attr('tabindex', '');
				if ( $(tr).hasClass('schnellerfassung-tabindex') ) {
					var maxindex = 0;
					$('[tabindex]', $('td', tr)[0]).each(function() {
						if ( parseInt($(this).attr('tabindex')) > maxindex )
							maxindex = parseInt($(this).attr('tabindex'));
					});
					$('[tabindex]', $('td', trNeu)[0]).each(function() {
						$(this).attr('tabindex', ++maxindex);
					});
				}
				$(trNeu).insertAfter(tr);
				$('input[name=' + addAnzahl + ']').val(zeilennummer);
				if (autotitle != undefined) {
					var nr = parseInt($('input[name=autotitle]').val()) + 1;
					$('input[name=autotitle]').val(nr);
					$('input.autotitle', trNeu).val(autotitle + ' ' + ("0" + nr).slice(-2));
				}
				$('[data-fremdid]', trNeu).each(function() {
					$('.entry', this).remove();
					$('input[name='+$(this).attr('data-fremdid')+']').val(0);
				});
				$('textarea', trNeu).each(function() {
					var tinyeditor = $(this).parents('.tinyeditor');
					$(this).insertAfter(tinyeditor);
					$(this).removeAttr('data-tinyedit');
					$(tinyeditor).remove();
				});
				afterRequest(trNeu);
				var table = $(tr).parents('table');
				if ( $(table).attr('data-dnd') != undefined ) {
					afterRequestDnDTables($(tr).parents('table').parent());
				}
				if ( $(table).hasClass('sortable') ) {
					$(table).trigger('addRows', [$(trNeu), false]);
				}
			}
		}
	});
	$(document).on('change', 'td.updateDataText input, td.updateDataText textarea, td.updateDataText select', function() {
		var hidden = $(this).attr('type') == 'hidden';
		var td = $(this).parents('td');
		var table = $(this).parents('table');
		if ( $(this).attr('type') == 'checkbox' && !hidden ) {
			$(td).attr('data-text', $(this).prop('checked') ? 1 : 0);
		} else {
			var multiselect = $('div.multiselect[data-fremdid='+$(this).attr('name')+']');
			if ( $(multiselect).length > 0 ) {
				var text = '';
				$('span.entry', multiselect).each(function() {
					text += (text != '' ? ', ' : '') + $(this).text();
				});
				$(td).attr('data-text', text);
			} else if ( $(this).is('select') ) {
				if ( $(this).val() == '' || $(this).val() == 0 ) {
					$(td).attr('data-text','');
				} else {
					var option = $('option:selected', this);
					$(td).attr('data-text',$(option).text());
				}
							
			} else if ( !hidden) {
				$(td).attr('data-text', $(this).val());
			}
		}
		$(table).trigger( 'updateCell', [ $(td), false, undefined ] );
	});
	// Werte aus einer vorherigen Zeile übernehmen
	$(document).on('focus', 'table.autoadd input', function () {
		var tr = $(this).closest('tr');
		var geaendert = $('input.geaendert', tr).val();
		if ($('input.vorbelegungAusVorzeile', tr).length > 0) {
			var prev = $(tr).prev();
			if (prev != undefined && $('input.datum.bis', prev).length > 0) {
				$('input.vorbelegungAusVorzeile', tr).not('input.vorbelegungErfolgt').each(function () {
					if ($(this).hasClass('datum')) {
						$(this).val($('input.datum.bis', prev).val());
					} else if ($(this).hasClass('zeit')) {
						$(this).val($('input.zeit.bis', prev).val());
					}
					$(this).addClass('vorbelegungErfolgt');
					checkDateTime('', '', '', this);
				});
			}
		}
		$('input.geaendert', tr).val(geaendert);
	});
	// Autocomplete
	$(document).on('keyup click', 'input[data-autocomplete]', function (e) {
		// Keine Änderungen bei Pfeiltasten
		if (e.keyCode == 40 || e.keyCode == 38 || e.keyCode == 13 || e.keyCode == 27) {
			return;
		}
		var name = $(this).attr('name');
		// Autocomplete bei Tab ausblenden
		if (e.keyCode == 9) {
			$('div[data-autocompletefor=' + name + ']').remove();
		}
		var autocomplete = $('div[data-autocompletefor=' + name + ']');
		if ($(autocomplete).length == 0) {
			// alle anderen Autocompletes ausblenden
			$('div[data-autocompletefor]').remove();
			// Autocomplete aktivieren
			var left = $(this).offset().left;
			var top = $(this).offset().top + $(this).outerHeight();
			if ( $(this).parents('.ajax-content').length > 0 ) {
				top -= $(document).scrollTop();
			}
			var width = $(this).outerWidth() - 2;
			var clazz = $(this).attr('class');
			$(this).attr('data-results', 'autocomplete-' + name);
			$('#main').append('<div class="autocomplete ' + clazz + '" data-autocompletefor="' + name + '" id="autocomplete-' + name + '" style="top: ' + top + 'px; left: ' + left + 'px; z-index: ' + $.topZIndex() + '; width: ' + width + 'px; display: none;"></div>');
			var autocomplete = $('div[data-autocompletefor=' + name + ']');
		}
		var search = $(this).val();
		var ajax = $(this).attr('data-autocomplete');
		var pack = $(this).attr('data-package');
		var mode = $(this).attr('data-mode');
		var object;
		var attribute;
		if ( ajax.indexOf('.') > 0 ) {
			ajax = ajax.split('.');
			object = ajax[0];
			attribute = ajax[1];
			ajax = 'printAutocompleteEntries';
		}
		$.ajax({
			url: location.href,
			type: 'post',
			data: { ajax: ajax, package: pack, search: search, mode: mode, object: object, attribute: attribute },
			success: function (data) {
				if (data != 'false') {
					$(autocomplete).html(data);
					$(autocomplete).show();
				} else {
					$(autocomplete).remove();
				}
			},
			timeout: 30000
		});
	});
	$(document).on('focusout', 'input[data-autocomplete]', function () {
		var name = $(this).attr('name');
		window.setTimeout(function () {
			$('div[data-autocompletefor=' + name + ']').remove();
		}, 100);
	});
	$(document).on('click', 'div.autocomplete a', function () {
		var autocomplete = $(this).closest('div');
		$('input[name=' + $(autocomplete).attr('data-autocompletefor') + ']').val($(this).text());
		$('input[name=' + $(autocomplete).attr('data-autocompletefor') + ']').trigger('change');
		$('input[name=' + $(autocomplete).attr('data-autocompletefor') + ']').focus();
		$(autocomplete).remove();
	});
	// Abrechnungsinformationen aus Kategorien übernehmen
	$(document).on('change', 'select[data-abrechnungfor]', function () {
		var nummer = $(this).attr('data-abrechnungfor');
		var table = $(this).parents('table');
		var option = $('option:selected', this);
		var avgSkill = $(option).attr('data-skilllevel');
		if ($('select[name=mitarbeiteranfragen-lohnart-' + nummer + ']', table).length > 0) {
			var lohnart = $(option).attr('data-lohnart');
			var stundenlohn = $(option).attr('data-stundenlohn');
			var mindeststunden = $(option).attr('data-mindeststunden');
			var pauschallohn = $(option).attr('data-pauschallohn');
			var tagessatz = $(option).attr('data-tagessatz');
			var tagessatzStunden = $(option).attr('data-tagessatzstunden');
			var tagessatzMehrbetrag = $(option).attr('data-tagessatzmehrbetrag');
			var tagessatzMehrart = $(option).attr('data-tagessatzmehrart');
			$('select[name=mitarbeiteranfragen-lohnart-' + nummer + ']', table).val(lohnart);
			if (lohnart == 'H' && stundenlohn != '' && stundenlohn != undefined) {
				$('input[name=mitarbeiteranfragen-lohn-' + nummer + ']', table).val(stundenlohn);
			} else if (lohnart == 'PSCH' && pauschallohn != '' && pauschallohn != undefined) {
				$('input[name=mitarbeiteranfragen-lohn-' + nummer + ']', table).val(pauschallohn);
			} else if (lohnart == 'T' && tagessatz != '' && tagessatz != undefined) {
				$('input[name=mitarbeiteranfragen-lohn-' + nummer + ']', table).val(tagessatz);
			}
			$('input[name=mitarbeiteranfragen-mindeststunden-' + nummer + ']', table).val(mindeststunden);
			$('input[name=mitarbeiteranfragen-tagessatz-stunden-' + nummer + ']', table).val(tagessatzStunden);
			$('input[name=mitarbeiteranfragen-tagessatz-mehrbetrag-' + nummer + ']', table).val(tagessatzMehrbetrag);
			$('input[name=mitarbeiteranfragen-tagessatz-mehrart-' + nummer + ']', table).val(tagessatzMehrart);
		}
		$('input[name=mitarbeiteranfragen-skilllevel-' + nummer + ']', table).val(avgSkill).trigger('change');
	});
	// Korrektes Zeitformat prüfen
	$(document).on('timeFormatError', 'input.zeit', function () {
		$(this).addClass('red');
	});
	$(document).on('change', 'input.zeit', function () {
		$(this).removeClass('red');
	});
	// Stempeluhr
	var clickOrTouch = (('ontouchend' in window)) ? 'touchend' : 'click';
	$(document).on(clickOrTouch, 'div.nummernblock input[type=button]', function () {
		var table = $(this).parents('div.nummernblock');
		var value = $(this).val();
		if (value != 'C' && value != 'X') {
			$('input[type=text], input[type=password]', table).val($('input[type=text], input[type=password]', table).val() + value);
		} else {
			$('input[type=text], input[type=password]', table).val('');
		}
	});
	var touch = false;
	$(document).on('touchstart mouseover', '.touch-hoverfix', function (e) {
		if (e.type == 'touchstart') {
			touch = true;
		}
		if (e.type == 'touchstart' || !touch) {
			$(this).addClass('hover');
		}
	});
	$(document).on('touchend touchcancel mouseout', '.touch-hoverfix', function (e) {
		$(this).removeClass('hover');
	});
	// Skill-Level
	$(document).on('click', 'span.skill span', function () {
		var holder = $(this).closest('.skill-holder');
		if ($(holder).hasClass('readonly')) {
			return; // keine Änderung erlaubt
		}
		var level = $(this).attr('data-level');
		if ($(this).hasClass('active') && level == $('input', holder).val()) {
			$('input', holder).val(0).trigger('change');
		} else {
			$('input', holder).val(level).trigger('change');
		}
	});
	$(document).on('change', 'span.skill-holder input', function () {
		var holder = $(this).closest('.skill-holder');
		var level = $(this).val();
		$('span.skill span', holder).removeClass('active');
		for (var i = 1; i <= level; i++) {
			$('span[data-level=' + i + ']', holder).addClass('active');
		}

		holder.next('button').attr('data-skilllevel', level);
	});
	// FremdID-Chooser
	$(document).on('focus, click', 'input[data-fremdid]', function () {
		if ($(this).attr('data-refocus') == undefined) {
			$(this).attr('data-refocus', 'true');
			var input = $(this);
			window.setTimeout(function () {
				if (!$(input).is(':focus')) {
					return;
				}
				var mode = $(input).attr('data-mode');
				var id = $(input).attr('id');
				var suchtext = $(input).val();
				ajaxRequest(input, location.href, 'printHinweisUI', 'ajax-content', '', 'fade', false, false, false, true, 'FremdIDAuswahl', mode, function (target) {
					$('ul.listview', $('#' + target)).attr('data-target', id);
				}, function (target) {
					$('input[name=fremdidauswahl-suchbegriff]', $('#' + target)).val(suchtext).trigger('change');
				});
				$(input).removeAttr('data-refocus');
			}, 300);
		}
	});
	$(document).on('click', 'a[data-fremdid]', function () {
		var textfeld = $('#' + $(this).closest('ul.listview').attr('data-target'));
		var text = $(this).attr('data-text');
		var fremdid = $(this).attr('data-fremdid');
		var fremdidFeld = $(textfeld).attr('data-fremdid');
		
		var personnelleasing = $(this).attr('data-personnelleasing');
		if ( personnelleasing !== undefined && personnelleasing !== false && personnelleasing !== '0') {
			$('#jobispersonnelleasing').removeClass('hidden');
			$('#jobispersonnelleasing ').find(':checkbox').first().attr('checked', personnelleasing === '2');
			$('#jobispersonnelleasing ').attr('data-personnelleasing', personnelleasing);
		}
		if ($(textfeld).is('input')) {
			$(textfeld).val(text);
			$('input[name=' + fremdidFeld + ']', $(textfeld).parent()).val(fremdid).trigger('change');
			var mapslink = $(this).attr('data-mapslink');
			if (mapslink != undefined) {
				$('a.maps-link', $(textfeld).parent()).attr('href', mapslink);
				$('a.maps-link', $(textfeld).parent()).show();
			}
			$(textfeld).attr('data-refocus', 'true');
			$(textfeld).focus();
			$(textfeld).removeAttr('data-refocus');
		} else {
			$(textfeld).append('<span class="entry" data-value="' + fremdid + '">' + text + ' <a>x</a></span>');
			if ($(textfeld).hasClass('single')) {
				var ids = fremdid;
			} else {
				var ids = '';
				$('span.entry', textfeld).each(function () {
					if (ids != '') {
						ids += ',';
					}
					ids += $(this).attr('data-value');
				});
			}
			$('input[name=' + fremdidFeld + ']').val(ids).trigger('change');
			var mapslink = $(this).attr('data-mapslink');
			if (mapslink != undefined) {
				$('a.maps-link', $(textfeld)).attr('href', mapslink);
				$('a.maps-link', $(textfeld)).show();
			}
		}
		$('[data-fremdidactive=' + fremdidFeld + ']').addClass('active');
	});
	$(document).on('click', '.inputclear', function () {
		var clearfor = $(this).attr('data-clearfor');
		$('#' + clearfor).val('');
		if ($('#' + clearfor).attr('data-fremdid') != undefined) {
			$('input[name=' + $('#' + clearfor).attr('data-fremdid') + ']', $('#' + clearfor).parent()).val('0').trigger('change');
		} else {
			$('#' + clearfor).focus();
			$('#' + clearfor).trigger('change');
		}
		$('[data-fremdidactive=' + clearfor + ']').removeClass('active');
		var parent = $('#' + clearfor).parent();
		if ($('a.maps-link', parent).length > 0) {
			$('a.maps-link', parent).hide();
		}
	});
	$(document).on('change', 'input[data-resetfor]', function () {
		if ($(this).is(':checked')) {
			var reset = $(this).attr('data-resetfor');
			$('#' + reset).val('').trigger('change');
		}
	});
	$(document).on('change', 'input[data-changefremdidzugehoerigkeitfor]', function () {
		var fremdid = $(this).attr('data-changefremdidzugehoerigkeitfor');
		var chooser = $('[data-fremdid=' + fremdid + ']');
		if ( chooser === undefined || $(chooser).length == 0 )
			return;
			
		var mode = $(chooser).attr('data-mode');
		var modeSplit = mode.split('-');
		var modeNeu = modeSplit[0] + '-' + $(this).val();
		if (mode != modeNeu) {
			$(chooser).attr('data-mode', modeNeu);
			$('span.entry', chooser).each(function () {
				$('a:last-child', this).click();
			});
			if ($(this).val() == 0) {
				$(chooser).closest('tr').removeClass('hidden').hide();
			} else {
				$(chooser).closest('tr').removeClass('hidden').show();
			}
		}
	});
	// FremdID Multi-Chooser
	$(document).on('click', 'div.multiselect[data-fremdid]', function () {
		if ($(this).hasClass('single') && $('span.entry', this).length > 0) {
			return; // nur ein Eintrag erlaubt
		}
		if ($(this).attr('data-refocus') == undefined) {
			$('div.multiselect[data-fremdid]').removeAttr('data-lastclicked');
			$(this).attr('data-lastclicked', 'true');
			var input = $(this);
			window.setTimeout(function () {
				var mode = $(input).attr('data-mode');
				var id = $(input).attr('id');
				ajaxRequest(input, location.href, 'printHinweisUI', 'ajax-content', '', 'fade', false, false, false, true, 'FremdIDAuswahl', mode, function (target) {
					$('ul.listview', $('#' + target)).attr('data-target', id);
				}, function (target) {
					$('input[name=fremdidauswahl-suchbegriff]', $('#' + target)).trigger('change');
				});
			}, 300);
		}
	});
	$(document).on('click', 'div.multiselect span.entry', function (e) {
		if ($('a', this).length > 0) {
			e.stopPropagation();
		}
	});
	$(document).on('click', 'div.multiselect span.entry a', function (e) {
		if (!$(this).is(':last-child')) {
			return;
		}
		e.stopPropagation();
		var entry = $(this).parent();
		var div = $(entry).parent();
		var ids = '';
		$('span.entry', div).not(entry).each(function () {
			if (ids != '') {
				ids += ',';
			}
			ids += $(this).attr('data-value');
		});
		var fremdid = $(div).attr('data-fremdid');
		$(entry).remove();
		$('input[name=' + fremdid + ']').val(ids).trigger('change');
		$('a.maps-link', div).hide();
	});
	$(document).on('change', 'tr.reisekostenpauschale input, tr.reisekostenpauschale select', function () {
		var country = $('input[name=country]').val();
		var countryAT = country == 'AT';
		var tr = $(this).closest('tr');
		var ignorieren = $('input[data-type=ignorieren]', tr);
		if ($(ignorieren).is(':checked')) {
			$('.ergebnis', tr).text('0,00');
			return;
		}
		var fruehstueck = $('input[data-type=fruehstueck]', tr);
		var mittagessen = $('input[data-type=mittagessen]', tr);
		var abendessen = $('input[data-type=abendessen]', tr);
		var unterkunft = $('select option:selected', tr);
		var pauschale = parseFloat($('.ergebnis', tr).attr('data-pauschalbetrag'));
		if ($(fruehstueck).attr('data-reduktion').indexOf('%') >= 0) { // AT if percentage
			if ($(fruehstueck).is(':checked') || $(mittagessen).is(':checked') || $(abendessen).is(':checked')) {
				pauschale = pauschale * ( parseFloat($(fruehstueck).attr('data-reduktion').replace('%', '')) / 100);
			}
		} else { // DE or AT if fixed value
			if ($(fruehstueck).is(':checked')) {
				pauschale -= parseFloat($(fruehstueck).attr('data-reduktion'));
			}
			if ($(mittagessen).is(':checked')) {
				pauschale -= parseFloat($(mittagessen).attr('data-reduktion'));
			}
			if ($(abendessen).is(':checked') && (!countryAT || !$(mittagessen).is(':checked')) ) {
				pauschale -= parseFloat($(abendessen).attr('data-reduktion'));
			}
		}
		if (pauschale < 0) {
			pauschale = 0;
		}
		pauschale += parseFloat($(unterkunft).attr('data-betrag'));
		$('.ergebnis', tr).text(formatCurrency2User(pauschale));
	});
	$(document).on('change', 'select.kennzeichen', function () {
		var option = $('option:selected', this);
		$('input[name=kennzeichen]').val($(option).attr('data-kennzeichen'));
	});
	$(document).on('change', 'select.fahrer', function () {
		deaktiviereVerplanteFahrer();
	});
	$(document).on('click', 'div.antworten a', function () {
		var div = $(this).parent();
		$('div.antwort', div).show();
		$(this).hide();
		$('textarea', div).focus();
	});
	$(document).on('click', 'a.add-comment', function () {
		var context = $(this).parents('div.hinweis');
		var div = $('div.kommentar.hidden', context).first();
		$(div).removeClass('hidden').show();
		$('a.add-comment', context).each( function() {
			$(this).hide();
		});
		$(this).closest('.scroll-y').animate({
			scrollTop: $(div).offset().top
		}, 500);
		$('textarea', div).focus();
	});
	$(document).on('input change', '.slider', function () {
		var slider_value = $(this).val();
		if(slider_value > 1000) slider_value = '1000+';
		var label = $(this).parent().find('label')
		$(label).html($(label).html().replace(/\d+\+?/g, slider_value));
	});
	$(document).on('change', 'select[data-colorclass]', function () {
		var div = $(this).closest('.' + $(this).attr('data-colorclass'));
		$('option', this).each(function () {
			if ($(this).attr('data-color') != undefined) {
				$(div).removeClass($(this).attr('data-color'));
			}
		});
		var option = $('option:selected', this);
		if ($(option).attr('data-color') != undefined) {
			$(div).addClass($(option).attr('data-color'));
			
		}
	});
	$(document).on('click', '[data-toggle]', function () {
		var id = $(this).attr('data-toggle');
		$('#' + id).toggle().removeClass('hidden');
		onAfterNavigate.notify({ target: id });
		if ($('[data-togglesave=' + id + ']').length > 0) {
			$('[data-togglesave=' + id + ']').val($('#' + id).is(':visible') ? 1 : 0).trigger('change');
		}
	});
	$(document).on('click', '[data-toggleclass]', function (e) {
		if ( $(this).attr('data-stoppropagation') !== undefined ) {
			e.preventDefault();
			e.stopPropagation();
		}
		toggleClass(this);
	});
	// Bubbles Ausblenden bei externem Klick
	$(document).on('click', 'body', function (e) {
		if ($(e.target).parents('a[data-ajax]').length > 0) {
			callAjaxRequest($(e.target).parents('a[data-ajax]'));
			return false;
		} else if ($(e.target).parents('input[type=submit], button[type=submit]').length > 0) {
			$(e.target).parents('button[type=submit]').attr('data-clicked', 'true');
		}
	});
	// Submit per AJAX bei einzelnen Feldern verhindern
	$(document).on('change', 'select[data-submit]', function () {
		if ($(this).attr('data-submit') == 'no-ajax') {
			$(this).closest('form').attr('data-submit', 'no-ajax');
		}
	});
	// Submit div
	$(document).on('click', '.submit-div', function(event) {
		var target = $(event.target);
		if ( !target.is('button[type="submit"]') && !target.closest('button[type="submit"]').length 
				&& !target.is('a.button') && !target.closest('a.button').length ) {
			$(this).find('.divSubmitButton').trigger('click');
		}
		if ( target.is('a.button') || target.closest('a.button').length ) {
			event.stopPropagation();
		}
	});
	// Select changes select
	$(document).on('change', 'select', function() {
		if ( $(this).data('autoselectclass') ) {
			var selected = $(this).find('option:selected');
			var targetOption = selected.data('targetoption');
			if ( targetOption ) {
				$('select.' + $(this).data('autoselectclass')).find('option').each(function() {
					if ( $(this).val() == targetOption ) {
						$(this).prop('selected', true);
					}
				});
		  	}
		}
	});

	// country select changes state
	$(document).on('change', 'select.countryselect', function() {
		var selectedCountry = $(this).find('option:selected').val();
		$('select.stateselect').each(function() {
			var stateOptions = getApiCountryStates(selectedCountry);
			stateOptions = JSON.parse( stateOptions)['Bundeslaender'];
			$(this).empty();
			stateOptions.forEach(option => {
				const {value, label, disabled=false} = option;
				var option = $('<option></option>').attr("value", value).text(label);
				if ( disabled )
					option.attr('disabled','disabled');
				$(this).append(option);
			});
		});
	});

	function getApiCountryStates(countryCode) {
		var res = null;
		$.ajax({
			type: 'POST',
			async: false,
			url: window.location.origin+'/api/basedata/enums/',
			data: {CountryCode: countryCode},
			contentType: 'application/x-www-form-urlencoded',
			success: function(response) {
				res = response;
			},
		});
		return res;
	}

	// employeebranchselect for date of branch change
	$(document).on('change', 'select.employeebranch', function() {
		var selectedBranch = $(this).find('option:selected').val();
		console.log('selected branch'+selectedBranch);
		let branchChangeDialog = $('#branchchange');
		if ( branchChangeDialog.hasClass('hidden') )
			branchChangeDialog.removeClass('hidden');
		if ( branchChangeDialog.css('display') == 'none' )
			branchChangeDialog.css('display', 'flex');
	});


	// Sprachauswahl ändern
	$(document).on('click', 'div.sprache .delete', function () {
		var code = $('input.languagecode', $(this).parent()).val();
		$(this).parents('div.sprache').remove();
		$('select.languagecode option[value=' + code + ']').prop("disabled", false);
	});
	$(document).on('change', 'div.sprache select', function () {
		var div = $(this).parents('div.sprache');
		$(div).removeClass('level-0');
		$(div).removeClass('level-1');
		$(div).removeClass('level-2');
		$(div).removeClass('level-3');
		$(div).removeClass('level-4');
		$(div).addClass('level-' + $(this).val());
	});
	// Dashboard
	$(document).on('dragstart', 'div.widget', function (e) {
		e.stopPropagation();
		e.originalEvent.dataTransfer.setData("text", $(this).attr('data-widgetid'));
		var dashboard = $(this).closest('.dashboard');
		var text = language('js_widgetablegen');
		$('div.spalte', dashboard).each(function () {
			var spalte = $(this);
			setTimeout(function () {
				$(spalte).prepend('<div class="target" data-type="first" data-afterid="">' + text + '</div>');
			}, 10);
			$('div.widget', this).each(function () {
				var widget = $(this);
				setTimeout(function () {
					$('<div class="target" data-type="after" data-afterid="' + $(widget).attr('data-widgetid') + '">' + text + '</div>').insertAfter(widget);
				}, 10);
			});
		});
		setTimeout(function () {
			var maxheight = 0;
			$('div.spalte', dashboard).each(function () {
				var height = $(this).height();
				if (maxheight < height) {
					maxheight = height;
				}
			});
			$('div.spalte', dashboard).each(function () {
				if ($(this).height() < maxheight) {
					$(this).height(maxheight);
					var target = $('div.target', this).last();
					if ( $(target).length > 0 ) {
						var height = $(target).position().top;
						var padding = parseInt($(target).css('padding-top')) * 2;
						$('div.target', this).last().height(maxheight - height - padding);
					}
				}
			});
		}, 15);
	});
	$(document).on('dragover', 'div.target', function (e) {
		e.stopPropagation();
		e.preventDefault();
		$(this).addClass('hover');
		$('div.target').not(this).removeClass('hover');
	});
	$(document).on('dragleave', 'div.target', function (e) {
		e.stopPropagation();
		e.preventDefault();
		$('div.target').removeClass('hover');
	});
	$(document).on('drop', 'div.target', function (e) {
		e.stopPropagation();
		e.preventDefault();
		var id = e.originalEvent.dataTransfer.getData("text");
		var widget = $('div.widget[data-widgetid=' + id + ']').detach();
		$(widget).insertAfter(this);
		var form = $('form.update', widget);
		$('input[name=type]').val($(this).attr('data-type'));
		$('input[name=afterid]').val($(this).attr('data-afterid'));
		$('input[name=spalte]').val($(this).closest('div.spalte').attr('data-spalte'));
		$(form).submit();
		$('div.target').remove();
		$('div.spalte').height('auto');
	});
	$(document).on('dragend', 'div.widget', function (e) {
		$('div.target').remove();
		$('div.spalte').height('auto');
	});
	$(document).on('mousedown', 'div.widget div.content', function (e) {
		var content = $(this);
		$(content).parent().attr('draggable', false);
		$(document).one('mouseup', function () {
			$(content).parent().attr('draggable', true);
		});
	});
	// Push-Notifications
	registerServiceWorker();
	$(document).on('click', 'a.push-subscribe', function () {
		if (('serviceWorker' in navigator) && ('PushManager' in window) && ('showNotification' in ServiceWorkerRegistration.prototype)) {
			navigator.serviceWorker.ready
				.then(function (serviceWorkerRegistration) {
					return serviceWorkerRegistration.pushManager.subscribe({
						userVisibleOnly: true,
						applicationServerKey: urlBase64ToUint8Array(config.pushServerKey),
					});
				})
				.then(function (subscription) {
					sendSubscriptionToServer(subscription, 'INSERT');
				})
				.then(function () {
					$('div.hinweis.push-subscribe').hide();
					$('p.push-subscribe').hide();
					$('p.active').removeClass('hidden').show();
				})
				.catch(function (e) {
					console.error('Error when updating the subscription', e);
					var hinweis = '<div class="hinweis" id="ajax-hinweis" style="display: none; z-index: ' + $.topZIndex() + '"><div class="hinweis__surface"><div class="header row">' + language('js_push') + '</div><div class="content row scroll-y">' + language('js_pusherror') + '</div><div class="footer row"><a class="close">' + language('js_ok') + '</a></div></div></div>';
					$('#main').append(hinweis);
					$("#ajax-hinweis").fadeIn(400);
				});
		}
	});
	$(document).on('click', 'a.push-unsubscribe', function () {
		var parent = $(this).parents('div.push-activation');
		navigator.serviceWorker.ready
			.then(function (serviceWorkerRegistration) {
				return serviceWorkerRegistration.pushManager.getSubscription();
			})
			.then(function (subscription) {
				if (!subscription) {
					$('p.push-subscribe').removeClass('hidden').show();
					$('p.active').hide();
					return;
				}
				sendSubscriptionToServer(subscription, 'DELETE');
				return subscription;
			})
			.then(function (subscription) {
				subscription.unsubscribe();
			})
			.then(function () {
				$('p.push-subscribe').removeClass('hidden').show();
				$('p.active').hide();
			})
			.catch(function (e) {
				console.error('Error when unsubscribing the user', e);
			});
	});
	$(document).on('click', 'a.push-norequest', function () {
		setLocalData('push-norequest', 'true', 30);
	});
	$(document).on('click', 'div.push-subscribe a.close', function () {
		let count = parseInt( getLocalData('push-subscribe-close-count') );
		if ( typeof count != 'number' ) {
			count = 1;
		} else {
			count++;
		}
		setLocalData('push-subscribe-close-count', count, 30);
		if ( count >= 5 ) 
			setLocalData('push-norequest', 'true', 30);
	});
	$(document).on('change', 'select.terminanfrage-kategorieselect', function () {
		var kategorieid = $(this).val();
		var td = $(this).parents('td');
		var link = $('a[data-bubble]', td);
		$(link).attr('data-mode', 'kategorie-' + kategorieid);
		$(link).attr('data-kategorie', kategorieid);
	});
	$(document).on('change', 'select[data-update]', function () {
		var updatefor = $(this).attr('data-update');
		var value = $('option:selected', this).attr('data-updatevalue');
		$('input[name=' + updatefor + ']').val(value);
	});
	// Standardtexte
	$(document).on('click', 'a.vorlage-einfuegen', function () {
		var form = $(this).closest('form');
		var textarea = $('textarea[name=' + $(this).attr('data-formname') + ']', form);
        if ( textarea.length === 0 ) {
            // textarea not found by name
            var textarea = $('textarea[id=' + $(this).attr('data-formname') + ']', form);
        }
		var hinweis = $(this).closest('div.bubble');
		var kategorie = $('li.kategorie.active', hinweis);
		kategorie = $('div#' + $('a', kategorie).attr('data-folder'), hinweis);
		if ($(kategorie).length > 0) {
			var vorlage = $('li.vorlage.active', hinweis);
			vorlage = $('div#' + $('a', vorlage).attr('data-folder'), hinweis);
			if ($(vorlage).length > 0) {
				var text = $('div.vorlage', vorlage).html();
				if ($(textarea).data('tinyeditor') != undefined) {
					$(textarea).data('tinyeditor').i.focus();
					$(textarea).data('tinyeditor').action('insertHTML', text, false);
				} else {
					text = text.replace(/<br>/g, "");
					text = text.replace(/<br \/>/g, "");
					if ($(textarea).attr('id') == undefined) {
						error('Die Textarea ' + $(textarea).attr('name') + ' enthält keine ID.');
					}
					var caretPos = document.getElementById($(textarea).attr('id')).selectionStart;
					var textAreaTxt = $(textarea).val();
					$(textarea).val(textAreaTxt.substring(0, caretPos) + text + textAreaTxt.substring(caretPos));
				}
			}
		}
	});
	// Datepicker
	$(document).on('focus', 'input.datum', function () {
		datepickerInitialisieren(this);
		$('div.Zebra_DatePicker').topZIndex();
	});
	// Timepicker
	$(document).on('focus', 'input.zeit', function () {
		if ($(this).attr('data-pair') != undefined && $(this).hasClass('von')) {
			checkDateTime('', '', '', this);
		}
		if ( config.timeFormat == '12H' ) {
			var timeFormat = 'h:i A';
		} else {
			var timeFormat = 'H:i';
		}
		$(this).timepicker({ 'timeFormat': timeFormat, 'step': 15, 'disableTextInput': false });
	});
	$(document).on('showTimepicker', function (a) {
		$('div.ui-timepicker-wrapper').topZIndex();
	});
	// Zeiträume prüfen
	$(document).on('change', 'input[data-pair], select[data-pair]', function () {
		dateCallbackAusfuehren(this, '', '', '', false);
	});
	// Personalplan
	$(document).on('change', 'table.personalplan select', function () {
		var tr = $(this).parents('tr');
		var selectedValue = this.value;
		$('input[type=checkbox]', tr).each( function(idx, checkbox) { 
			var i = $(checkbox).attr('data-i');
			var td = $(this).parents('td');
			var hidden = $('input[name=personalplan-subemployee-'+i+']', td);
			if ( hidden.length > 0 ) {
				hidden[0].value = selectedValue;
			} else {
				$(td).append('<input type="hidden" name="personalplan-subemployee-' + i + '" value="' + selectedValue + '" />');
			}
			createInputFieldsForPersonnelPlanIfNecessary(checkbox, td);
		})
	});
	$(document).on('change', 'table.personalplan input[type=checkbox]', function () {
		var td = $(this).parents('td');
		createInputFieldsForPersonnelPlanIfNecessary(this, td);
		var checked = $(this).prop('checked');
		var anfragenvariante = $('input[name=anfragenvariante]:checked').val();
		if (!checked) {
			var angefragt = $(td).hasClass('angefragt');
			var gebucht = $(td).hasClass('gebucht');
			var verfuegbar = $(td).hasClass('verfuegbar');
			var nv = $(td).hasClass('nv');
			if (anfragenvariante == 'anfragen' && (verfuegbar || gebucht || nv)) {
				$(this).prop('checked', true);
				checked = true;
			} else if (anfragenvariante == 'eintragen' && (gebucht || angefragt || nv)) {
				$(this).prop('checked', true);
				checked = true;
			} else if (anfragenvariante == 'buchen' && (verfuegbar || angefragt || nv)) {
				$(this).prop('checked', true);
				checked = true;
			} else if (anfragenvariante == 'nv' && (verfuegbar || angefragt || gebucht)) {
				$(this).prop('checked', true);
				checked = true;
			}
			$(td).removeClass('angefragt');
			$(td).removeClass('gebucht');
			$(td).removeClass('abgelehnt');
			$(td).removeClass('nv');
		}
		if (checked) {
			$(td).removeClass('angefragt');
			$(td).removeClass('gebucht');
			$(td).removeClass('abgelehnt');
			$(td).removeClass('verfuegbar');
			$(td).removeClass('nv');
			if (anfragenvariante == 'anfragen') {
				$(td).addClass('angefragt');
			} else if (anfragenvariante == 'eintragen') {
				$(td).addClass('verfuegbar');
			} else if (anfragenvariante == 'buchen') {
				$(td).addClass('gebucht');
			} else if (anfragenvariante == 'nv') {
				$(td).addClass('nv');
			}
			$('input.anfragenvariante', td).val(anfragenvariante);
			var zugehoerigkeit = $('input.zugehoerigkeit', td).val();
			var zugehoerigkeitid = $('input.zugehoerigkeitid', td).val();
			var freierMitarbeiter = $('input.freiermitarbeiter', td);
			if ($(freierMitarbeiter).length > 0) {
				freierMitarbeiter = $(freierMitarbeiter).val();
			} else {
				freierMitarbeiter = undefined;
			}
			var terminid = $('input.terminid', td).val();
			if ( zugehoerigkeit != 'D' ) {
				$('input[data-terminid=' + terminid + ']').each(function () {
					var tdMa = $(this).parents('td');
					var tdZugehoerigkeit = $(this).attr('data-zugehoerigkeit');
					var tdZugehoerigkeitId = $(this).attr('data-zugehoerigkeitid');
					var tdFreierMitarbeiter = $(this).attr('data-freiermitarbeiter');
					if (tdZugehoerigkeit != zugehoerigkeit
						|| tdZugehoerigkeitId != zugehoerigkeitid
						|| freierMitarbeiter != undefined && tdFreierMitarbeiter != undefined && tdFreierMitarbeiter != freierMitarbeiter) {
						return;
					}
					if (!$(tdMa).is(td)) {
						$(tdMa).removeClass('angefragt');
						$(tdMa).removeClass('gebucht');
						$(tdMa).removeClass('abgelehnt');
						$(tdMa).removeClass('verfuegbar');
						$(tdMa).removeClass('nv');
						createInputFieldsForPersonnelPlanIfNecessary(this, tdMa);
						$('input[type=checkbox]', tdMa).prop('checked', false).trigger('change');
					}
				});
			}
		} else {
			$(td).removeClass('angefragt');
			$(td).removeClass('gebucht');
			$(td).removeClass('abgelehnt');
			$(td).removeClass('verfuegbar');
			$(td).removeClass('nv');
		}
	});
	$(document).on('click', 'table.personalplan button.delete-ma', function () {
		var hinweistext = $(this).attr('data-confirm');
		var title = $(this).attr('data-label');
		var id = $(this).parents('tr').attr('id');
		$('#main').append(`
			<div class="ajax-content" style="z-index:${$.topZIndex()}">
				<div class="hinweis confirm">
					<div class="hinweis__surface">
						<div class="header">${language('js_bestaetigung')}: ${title}</div>
						<div class="content">${hinweistext}</div>
						<div class="footer">
							<button type="button" class="button close">${language('js_abbrechen')}</button>
							<button type="button" class="button button--primary close personnelplan-delete" data-id="${id}">${title}</button>
						</div>
					</div>
				</div>
			</div>
		`);
	});
	$(document).on('click', 'button.personnelplan-delete', function () {
		var tr = $('tr#'+$(this).attr('data-id'));
		$('td', tr).each(function() {
			var cb = $('input[type=checkbox]', this);
			if ( $(cb).prop('checked') ) {
				$(this).removeClass('angefragt');
				$(this).removeClass('gebucht');
				$(this).removeClass('abgelehnt');
				$(this).removeClass('verfuegbar');
				$(this).removeClass('nv');
				$(cb).prop('checked', false).trigger('change');
			}
		});
		$(tr).hide();
	});
	// Jobtypen-Multiselect
	$(document).on('change', 'input.jobtypen-multiselect', function () {
		var parent = $(this).parents('.jobtypen-multiselect');
		var multiselect = $('div.multiselect', parent);
		$('span.entry', multiselect).remove();
		var keiner = true;
		var alle = true;
		$('input.jobtypen-multiselect', parent).each(function () {
			if ($(this).prop('checked')) {
				keiner = false;
				var bezeichnung = $('label', $(this).closest('td')).text();
				$(multiselect).append('<span class="entry">' + bezeichnung + '</span>');
			} else {
				alle = false;
			}
		});
		if (keiner) {
			$('span.entry', multiselect).remove();
			$(multiselect).append('<span class="entry">' + language('js_keintyp') + '</span>');
		}
		if (alle) {
			$('span.entry', multiselect).remove();
			$(multiselect).append('<span class="entry">' + language('js_alletypen') + '</span>');
		}
	});
	// Refresh für Ajax-Content
	/*refresh =*/ window.setInterval(function () {
		$('[data-refresh]').each(function () {
			var lastrefresh = $(this).attr('data-lastrefresh');
			if (lastrefresh == undefined) {
				var d = new Date();
				$(this).attr('data-lastrefresh', d.getTime());
				return;
			}
			var refreshInterval = $(this).attr('data-refresh');
			var d = new Date();
			if (lastrefresh < d.getTime() - (refreshInterval * 1000)) {
				// Data-Lastrefresh setzen
				$(this).attr('data-lastrefresh', d.getTime());
				if ( $(this).attr('data-sendform') != undefined ) {
					sendForm($('form[name='+$(this).attr('data-sendform')+']'), $(this));
				} else {
					callAjaxRequest($(this));
				}
			}
		});
	}, 1000);
	$(document).on('change', '#pdfcolor-background, #pdfcolor-text', function () {
		var background = $('#pdfcolor-background').val();
		var text = $('#pdfcolor-text').val();
		var h3 = $('#pdfheadline-preview');
		$(h3).css('border-color', background);
		$('span', $(h3)).css('color', text);
		$('span', $(h3)).css('background-color', background);
	});
	$(document).on('click', '#restorepdfcolor', function () {
		$('#pdfcolor-background').val($(this).attr('data-background'));
		$('#pdfcolor-text').val($(this).attr('data-text')).trigger('change');
	});
	$(document).on('change', 'input.taeglicher-zeitraum', function () {
		var form = $(this).closest('form');
		var datumvon = $('input.datum.von', form);
		var datumbis = $('input.datum.bis', form);
		var zeitvon = $('input.zeit.von', form);
		var zeitbis = $('input.zeit.bis', form);
		var spanJe = $('span.taeglicher-zeitraum-je', form);
		var spanBis = $('span.taeglicher-zeitraum-bis', form);
		if ($(this).prop('checked')) {
			$(spanJe).show();
			$(spanBis).show();
			$(zeitvon).css('margin-left', '0px');
			$(zeitvon).insertAfter(spanJe);
		} else {
			$(spanJe).hide();
			$(spanBis).hide();
			$(zeitvon).css('margin-left', '5px');
			$(zeitvon).insertAfter(datumvon);
		}
	});

	// workinghours
	(() => {
		let newItem;

		const calcStart = (container, event) => {
			container = $(container);
			const relX = event.pageX - container.offset().left;
			const hourWidth = container.innerWidth() / 24;
			return Math.floor(relX / hourWidth);
		}

		function fitIntoRow(row, item, left, right) {
			for (const _item of row.querySelectorAll('a')) {
				if (_item === item) {
					continue;
				}

				const _left = _item.getAttribute('data-left');
				if (
					left == _left
					|| (left < _left && right > _left)
					|| (left > _left && left < _item.getAttribute('data-right'))
				) {
					return false;
				}
			}

			return true;
		}

		function findRow(container, item, left, right) {
			for (const row of container.querySelectorAll('.row')) {
				if (fitIntoRow(row, item, left, right)) {
					return row;
				}
			}

			const row = document.createElement('div');
			row.className = 'row';
			container.appendChild(row);
			return row;
		}

		$(document).on('change', '.workinghours__dialog input, .workinghours__dialog select', function (e) {
			e.stopPropagation();
			e.preventDefault();
			var bubble = $(this).closest('.bubble');
			var form = $(this).closest('form');
			var table = $('table', form);
			var dayStarttime = new Date();
			dayStarttime.setTime(parseInt($(table).attr('data-zeitraumvon')) * 1000);
			var dayEndtime = new Date();
			dayEndtime.setTime(parseInt($(table).attr('data-zeitraumbis')) * 1000);
			var id = $(bubble).attr('id');
			var link = $('a[data-bubble=' + id + ']');
			var overviewElement = $('span[data-zeitid=' + id + ']');
			if ( $('select.taetigkeit', bubble).length > 0 ) {
				var activity = $('select.taetigkeit', bubble).val();
			} else {
				var activity = $('input.taetigkeit', bubble).val();
			}
			var category = $('select.kategorie', bubble);
			category = $('option:selected', category);
			$('span', link).text(activity);
			var timeStart = getDateFromDatumInput($('input.datum.von', bubble), $('input.zeit.von', bubble));
			var timeEnd = getDateFromDatumInput($('input.datum.bis', bubble), $('input.zeit.bis', bubble));
			var left = 0;
			var right = 1440;
			if (timeStart > dayStarttime) {
				left = (timeStart.getHours() * 60) + timeStart.getMinutes();
			}
			if (timeEnd == undefined || isNaN(timeEnd.getTime())) {
				timeEnd = new Date();
			}
			if (timeEnd < dayEndtime) {
				right = (timeEnd.getHours() * 60) + timeEnd.getMinutes();
			}
			$(link).attr('style', 'left: calc(100% / 1440 * ' + left + '); width: calc(100% / 1440 * ' + (right - left) + ');');
			$(link).attr('data-left', left);
			$(link).attr('data-right', right);

			const oldRow = link[0].parentElement;
			const newRow = findRow(oldRow.parentElement, link[0], left, right);
			if (newRow !== oldRow) {
				newRow.appendChild(link[0]);
				newRow.appendChild(bubble[0]);

				if (!oldRow.childElementCount) {
					oldRow.parentElement.removeChild(oldRow);
				}
			}

			$(overviewElement).attr('style', 'left: calc(100% / 1440 * ' + left + '); width: calc(100% / 1440 * ' + (right - left) + ');');
			$(link).removeClass('genehmigt');
			$(link).removeClass('abgelehnt');
			if ($(category).attr('data-pause') == 'true') {
				var td = $(overviewElement).closest('td');
				$('div.pause', td).append(overviewElement);
			} else if (!$(overviewElement).hasClass('arbeit')) {
				var td = $(overviewElement).closest('td');
				$('div.arbeit', td).append(overviewElement);
			}
			$(overviewElement).removeClass();
			$(overviewElement).addClass('termin');
			$(overviewElement).addClass($(category).attr('data-farbe'));
			$(table).removeAttr('data-formatted');
		});

		$(document).on('mouseenter', '.workinghours__content table tbody td.stunden', function (event) {
			if (newItem) {
				return;
			}

			if ($('.arbeitszeit-neu').length == '0') {
				return; // no rights to write
			}

			if (
				event.relatedTarget != undefined
				&& (
					$(event.relatedTarget).parents('div.bubble').length > 0
					|| $(event.relatedTarget).hasClass('bubble')
				)
			) {
				return;
			}

			newItem = document.createElement('div');
			newItem.className = 'neu';
			newItem.innerHTML = $('.arbeitszeit-neu').html();

			const start = calcStart(this, event);

			const left = start * 60;
			const right = left + 60;

			const link = newItem.querySelector('a');

			link.style.cssText = `left: calc(100% / 1440 * ${left}); width: calc(100% / 1440 * ${right - left});`;
			link.setAttribute('data-left', left);
			link.setAttribute('data-right', right);
			link.setAttribute('data-uhrzeitvon', ("0" + start).slice(-2) + ':00');
			link.setAttribute('data-uhrzeitbis', ("0" + (start + 1)).slice(-2) + ':00');

			findRow(this, link, left, right).appendChild(newItem);
		});

		$(document).on('mousemove', '.workinghours__content table tbody td.stunden', function (event) {
			if (!newItem) {
				return;
			}

			const start = calcStart(this, event);

			const left = start * 60;
			const right = left + 60;

			const link = newItem.querySelector('a');

			if (link.getAttribute('data-left') == left) {
				return;
			}

			link.style.cssText = `left: calc(100% / 1440 * ${left}); width: calc(100% / 1440 * ${right - left});`;
			link.setAttribute('data-left', left);
			link.setAttribute('data-right', right);
			link.setAttribute('data-uhrzeitvon', ("0" + start).slice(-2) + ':00');
			link.setAttribute('data-uhrzeitbis', ("0" + (start + 1)).slice(-2) + ':00');

			const row = findRow(this, link, left, right);
			const parent = newItem.parentElement;
			if (parent !== row) {
				row.appendChild(newItem);

				if (!parent.childElementCount) {
					parent.parentElement.removeChild(parent);
				}
			}
		});

		$(document).on('mouseleave', '.workinghours__content table tbody td.stunden', function (event) {
			if (!newItem) {
				return;
			}

			const parent = newItem.parentElement;
			parent.removeChild(newItem);
			newItem = undefined;

			if (!parent.childElementCount) {
				parent.parentElement.removeChild(parent);
			}
		});

		$(document).on('click', '.workinghours__content table div.neu > a', function (e) {
			newItem = undefined;

			var div = $(this).parent();
			var form = $(this).closest('form');
			var anzahl = parseInt($('input[name=anzahl]', form).val());
			var re = new RegExp('-neu', 'g');
			$(div).html($(div).html().replace(re, '-' + (anzahl + 1)));
			var link = $(div).children('a');
			var hinweis = $(div).children('div.bubble');
			if ( config.timeFormat == '12H' ) {
				var zeitVon = getTime($(link).attr('data-uhrzeitvon')).split(':');
				var dateVon = new Date(2000, 0, 1, zeitVon[0], zeitVon[1], '00');
				
				var zeitBis = getTime($(link).attr('data-uhrzeitbis')).split(':');
				var dateBis = new Date(2000, 0, 1, zeitBis[0], zeitBis[1], '00');
				
				$('input.zeit.von', div).val(formatAMPM(dateVon));
				$('input.zeit.bis', div).val(formatAMPM(dateBis));
			} else {
				$('input.zeit.von', div).val($(link).attr('data-uhrzeitvon'));
				$('input.zeit.bis', div).val($(link).attr('data-uhrzeitbis'));
			}
			$('input[name=anzahl]', form).val(anzahl + 1);
			$(link).unwrap();
			$(link).attr('data-bubble', 'zeit-' + (anzahl + 1));
			var table = $(link).closest('table');
			var uebersicht = $('td.uebersicht', table);
			var arbeitszeiten = $('div.work', uebersicht);
			$(arbeitszeiten).append('<span data-zeitid="' + $(link).attr('data-bubble') + '" class="termin arbeit"></span>');
			var span = $('span[data-zeitid=' + $(link).attr('data-bubble') + ']', uebersicht);
			$(span).attr('style', $(link).attr('style'));
			var zugehoerigkeit = $(link).closest('td').attr('data-zugehoerigkeit');
			var zugehoerigkeitid = $(link).closest('td').attr('data-zugehoerigkeitid');
			$('input.zugehoerigkeit', hinweis).val(zugehoerigkeit);
			$('input.zugehoerigkeitid', hinweis).val(zugehoerigkeitid);
			$(link).click();
		});

		$(document).on('dragstart', 'a.termin', function (e) {
			e.stopPropagation();
			if ($(this).attr('data-bubble') != undefined) {
				e.originalEvent.dataTransfer.setData("text", $(this).attr('data-bubble'));
				$(this).closest('td.stunden').trigger('mouseout');
			}
		});

		$(document).on('dragover', 'td.stunden', function (e) {
			e.stopPropagation();
			e.preventDefault();
			// nichts tun, wird benötigt, da sonst das drop Event nicht funktioniert
		});

		$(document).on('dragleave', 'td.stunden', function (e) {
			e.stopPropagation();
			e.preventDefault();
			// nichts tun, wird benötigt, da sonst das drop Event nicht funktioniert
		});

		$(document).on('drop', 'td.stunden', function (e) {
			if ($(this).hasClass('uebersicht') || $(this).attr('data-zugehoerigkeit') == undefined) {
				return;
			}

			e.stopPropagation();
			e.preventDefault();
			var id = e.originalEvent.dataTransfer.getData("text");
			if (id != undefined && id != '') {
				try {
					var link = $('a.termin[data-bubble=' + id + ']')[0];
				} catch (e) {
					// do nothing, just make sure that we don't receive an error
					var link = undefined;
				}
				if ( link == undefined )
					return;

				var bubble = $('#' + id);

				const row = findRow(this, link, link.getAttribute('data-left'), link.getAttribute('data-right'));
				const parent = link.parentElement;
				if (parent !== row) {
					row.appendChild(link);
					row.appendChild(bubble[0]);

					if (!parent.childElementCount) {
						parent.parentElement.removeChild(parent);
					}

					$('input.zugehoerigkeit', bubble).val($(this).attr('data-zugehoerigkeit'));
					$('input.zugehoerigkeitid', bubble).val($(this).attr('data-zugehoerigkeitid'));
				}
			}
		});
	})();

	$(document).on('click', 'a.zeit-loeschen', function () {
		if ($(this).attr('data-confirm') != undefined) {
			return;
		}
		var bubble = $('#' + $(this).attr('data-id'));
		var link = $('a[data-bubble=' + $(bubble).attr('id') + ']');
		var zeitstrahl = $('span[data-zeitid=' + $(bubble).attr('id') + ']');
		var zeitid = $('input.id', bubble).val();
		$(zeitstrahl).remove();
		if (zeitid == 'neu') {
			$(link).remove();
			$(bubble).remove();
		} else {
			$('input.delete', bubble).val('1');
			$(link).hide();
		}
	});
	$(document).on('click', '.workinghours__content table a.termin', function (event) {
		var td = $(this).parents(td);
		var offset = $(td).offset();
		if (offset != undefined) {
			var relX = event.pageX - offset.left;
			var laenge = $(td).innerWidth();
			var laengeStunde = laenge / 24;
			var position = relX / laengeStunde;
			var stunde = Math.floor(position);
			var minute = Math.floor(60 * (position - stunde));
			var bubble = $('#'+$(this).attr('data-bubble'));
			$('input.teilzeit', bubble).val();
			var zeit = ("0" + stunde).slice(-2) + ':' + ("0" + minute).slice(-2);
			if ( config.timeFormat == '12H' ) {
				var zeitVon = getTime(zeit).split(':');
				var dateVon = new Date(2000, 0, 1, zeitVon[0], zeitVon[1], '00');
				$('input.teilzeit', bubble).val(formatAMPM(dateVon));
			} else {
				$('input.teilzeit', bubble).val(zeit);
			}
		}
	});
	$(document).on('click', '.workinghours__dialog a.teilzeit', function () {
		var form = $(this).closest('form');
		var hinweis = $(this).closest('.bubble');
		var link = $('a.termin[data-bubble=' + $(hinweis).attr('id') + ']', form);
		var td = $(link).closest('.row');
		var teilzeit = getTime($('input.teilzeit', hinweis).val());
		if (teilzeit == undefined || teilzeit == '00:00' ) {
			var uhrzeitvon = $('input.zeit.von', hinweis).val().split(':');
			if ($('input.zeit.bis', hinweis).val() != '') {
				var uhrzeitbis = $('input.zeit.bis', hinweis).val().split(':');
				var dauerStunde = parseInt(uhrzeitbis[0]) - parseInt(uhrzeitvon[0]);
				var dauerMinute = parseInt(uhrzeitbis[1]) - parseInt(uhrzeitvon[1]);
			} else {
				var dauerStunde = 1;
				var dauerMinute = 0;
			}
			var dateVon = new Date(2000, 0, 1, parseInt(uhrzeitvon[0]) + (dauerStunde / 2), parseInt(uhrzeitvon[1]) + (dauerMinute / 2), '00');
			if ( config.timeFormat == '12H' ) {
				var zeitVon = getTime(teilzeit).split(':');
				var dateVon = new Date(2000, 0, 1, zeitVon[0], zeitVon[1], '00');
				teilzeit = formatAMPM(dateVon);
			}
		} else {
			if ( config.timeFormat == '12H' ) {
				var zeitVon = getTime(teilzeit).split(':');
				var dateVon = new Date(2000, 0, 1, zeitVon[0], zeitVon[1], '00');
				teilzeit = formatAMPM(dateVon);
			}
		}
		$(td).append('<div id="teilzeit-wrapper"></div>');
		var wrapper = $('#teilzeit-wrapper');
		var link2 = $(link).clone();
		var hinweis2 = $(hinweis).clone();
		$(wrapper).append(link2);
		$(wrapper).append(hinweis2);
		var anzahl = parseInt($('input[name=anzahl]', form).val());
		if ( $('select.taetigkeit', hinweis).length > 0 ) {
			var taetigkeitName = $('select.taetigkeit', hinweis).attr('name').split('-');
		} else {
			var taetigkeitName = $('input.taetigkeit', hinweis).attr('name').split('-');
		}
		var re = new RegExp($(link).attr('data-bubble'), 'g');
		$(wrapper).html($(wrapper).html().replace(re, 'zeit-neu' + (anzahl + 1)));
		var re = new RegExp('-' + taetigkeitName[1] + '"', 'g');
		$(wrapper).html($(wrapper).html().replace(re, '-' + (anzahl + 1) + '"'));
		var hinweis2 = $(wrapper).children('div.bubble');
		$('input.id', hinweis2).val('neu');
		var uebersicht = $('td.uebersicht', $(td).closest('table'));
		var arbeitszeiten = $('div.work', uebersicht);
		$(arbeitszeiten).append('<span data-zeitid="' + $(hinweis2).attr('id') + '" class="termin"></span>');
		if ($('input.datum.bis', hinweis).val() == '') {
			$('input.datum.bis', hinweis).val($('input.datum.von', hinweis).val());
		}
		$('input.zeit.bis', hinweis2).val($('input.zeit.bis', hinweis).val()).trigger('change');
		$('input.zeit.bis', hinweis).val(teilzeit).trigger('change');
		$('input.zeit.von', hinweis2).val(teilzeit).trigger('change');
		$(hinweis2).unwrap();
		$('input[name=anzahl]', form).val(anzahl + 1);
	});
	$(document).on('change', 'input.load-arbeitszeitenobjekt', function () {
		var name = $(this).attr('name');
		var zid = $(this).val();
		var z = $(this).attr('data-zugehoerigkeit');
		var tr = $(this).closest('tr');
		var tbody = $('<tbody id="' + name + '-target"></tbody>');
		tbody.insertBefore(tr);
		ajaxRequest(this, location.href, 'printNeuesObjekt', name + '-target', '', '', false, false, false, true, 'Arbeitszeiten', z + '-' + zid, function (target) {
			var tr = $('tr', tbody);
			if (tr.length > 0) {
				tr.unwrap();
			} else {
				tbody.remove();
			}
			$(this).val('');
			$('input[data-fremdid=' + name + ']').val('');
		});
	});
	$(document).on('click', 'input[type="submit"][data-submit-and-reload-bubble]', function (event) {
		event.preventDefault();
		event.stopPropagation();
		event.stopImmediatePropagation();
		var button = $(this);
		$.post(button.data('url'), {
			ajax: true,
			package: button.data('package'),
			action: button.data('action')
		}).then(function () {
			bubbleAjaxLoading(button.closest('.bubble'));
		}).catch(function () { });
	});

	$(document).on('change', '#reisekosten-fahrtkosten .kennzeichen', function () {
		var select = $(this);
		select.prev().prop('disabled', !Number(select.val()));
	});

	$(document).on('click', 'div.filterlist div.filter a', function () {
		var form = $(this).parents('form');
		$(this).parent().remove();
		form.submit();
		return false;
	});

	$(document).on('change', 'select.setTimerange', function() {
		var tr = $(this).parents('tr');
		var option = $('option:selected',this);
		if ( $(option).attr('data-von-datum') != undefined ) {
			$('.datum.von',tr).val($(option).attr('data-von-datum'));
			$('.datum.bis',tr).val($(option).attr('data-bis-datum'));
			$('.zeit.von',tr).val($(option).attr('data-von-zeit'));
			$('.zeit.bis',tr).val($(option).attr('data-bis-zeit'));
			checkDateTime('','','',$('.datum.von',tr));
		}
	});
	$(document).on('change', 'select.optgroup-name', function() {
		var wrapper = $(this).parent();
		var name = $('small.optgroup-name', wrapper);
		var option = $(':selected', this);
		var group = $(option).parent();
		if ( $(group).is('optgroup') ) {
			$(wrapper).addClass('optgroup-name--visible');
			$(name).text($(group).attr('label'));
		} else {
			$(wrapper).removeClass('optgroup-name--visible');
			$(name).text('');
		}
	});
	$(document).on('change', 'select.colorchange', function() {
		var option = $(':selected', this);
		if ( $(option).attr('data-color') !== undefined ) {
			$(this).attr('data-color', $(option).attr('data-color'));
		} else {
			$(this).attr('data-color', '');
		}
		var settingJobProjectTypeDisponenten = false;
		var settingJobProjectTypeProjektleiter = false;
		// Check if schedulers or project managers are depending on job/project type
		$('option', $(this)).each(function() {
			if ( $(this).attr('data-disponenten-ids') !== undefined )
				settingJobProjectTypeDisponenten = true;
			if ( $(this).attr('data-projektleiter-ids') !== undefined )
				settingJobProjectTypeProjektleiter = true;
		});		
		// Disponenten 
		if ( $('#disponentenidsneu') !== undefined && settingJobProjectTypeDisponenten ) {
			var optionDisponentenIds = $(option).attr('data-disponenten-ids') !== undefined ? 
				$(option).attr('data-disponenten-ids').split(',').filter(Boolean) : [];
			var optionDisponentenNames = $(option).attr('data-disponenten-names') !== undefined ? 
				$(option).attr('data-disponenten-names').split('--').filter(Boolean) : [];
			var count = 0;
			var dispIdsInput = $('#disponentenidsneu');
			// update existing spans or delete
			dispIdsInput.children('span').each(function(index){
				if ( index == 0 ) // placeholder
					return;
				if ( optionDisponentenIds.length < index ) { // delete span
					$(this).remove();
				} 
				// update data-id
				$(this).attr('data-id', optionDisponentenIds[index-1]);
				// update link
				var a = $(this).children('a').first();
				var href = a.attr('href').split('/');
				var href = href.slice(0, href.length -1).join('/')+'/'+optionDisponentenIds[index-1]
				a.attr('href', href);
				a.text(optionDisponentenNames[index-1])
				count++;
			});
			// add spans
			for( var i=0; i < (optionDisponentenIds.length - count); i++ ) {
				var id = optionDisponentenIds[count+i];
				var span = '<span class="entry" data-value="'+id+'" data-id="'+id+'">';
				span += '<a href="'+window.location.origin+'/mitarbeiter/'+id+'" data-ajax="printHinweisUI" data-package="MitarbeiterVorschau" data-target="ajax-content" data-transition="fade" data-urlupdate="false">';
				span += optionDisponentenNames[count+i];
				span += '</a> <a tabindex="0">x</a>';
				span += '</span>';
				dispIdsInput.append(span);
			} 
			// set hidden ids
			dispIdsInput.next('input[type=hidden]').val( optionDisponentenIds.join(',') )
		}
		// Projektleiter
		if ( $('#projektleiteridsneu') !== undefined && settingJobProjectTypeProjektleiter ) {
			var optionProjectleiterIds = $(option).attr('data-projektleiter-ids') !== undefined ? 
				$(option).attr('data-projektleiter-ids').split(',').filter(Boolean) : [];
			var optionProjectleiterNames = $(option).attr('data-projektleiter-names') !== undefined ? 
				$(option).attr('data-projektleiter-names').split('--').filter(Boolean) : [];
			var count = 0;
			var plIdsInput = $('#projektleiteridsneu');
			// update existing spans or delete
			plIdsInput.children('span').each(function(index){
				if ( index == 0 ) // placeholder
					return;
				if ( optionProjectleiterIds.length < index ) { // delete span
					$(this).remove();
				} 
				// update data-id
				$(this).attr('data-id', optionProjectleiterIds[index-1]);
				// update link
				var a = $(this).children('a').first();
				var href = a.attr('href').split('/');
				var href = href.slice(0, href.length -1).join('/')+'/'+optionProjectleiterIds[index-1]
				a.attr('href', href);
				a.text(optionProjectleiterNames[index-1])
				count++;
			});
			// add spans
			for ( var i=0; i < (optionProjectleiterIds.length - count); i++ ) {
				var id = optionProjectleiterIds[count+i];
				var span = '<span class="entry" data-value="'+id+'" data-id="'+id+'">';
				span += '<a href="'+window.location.origin+'/mitarbeiter/'+id+'" data-ajax="printHinweisUI" data-package="MitarbeiterVorschau" data-target="ajax-content" data-transition="fade" data-urlupdate="false">';
				span += optionProjectleiterNames[count+i];
				span += '</a> <a tabindex="0">x</a>';
				span += '</span>';
				plIdsInput.append(span);
			} 
			// set hidden ids
			plIdsInput.next('input[type=hidden]').val( optionProjectleiterIds.join(',') );
		}

	});
	$(document).on('change', '.highlightable', function() {
		if ( parseFloat($(this).val()) > 0 )
			$(this).addClass('highlight');
		else
			$(this).removeClass('highlight');
	});
	$(document).on('change', '.risk-assessment--risk input', function() {
		var table = $(this).closest('table');
		$('td, th', table).removeClass('active');
		var thead = $('thead', table);
		var tbody = $('tbody', table);
		var severity = $('input[type=radio]:checked', thead);
		var propability = $('input[type=radio]:checked', tbody);
		if ( $(severity).length > 0 ) {
			var th = $(severity).parents('th').index();
			$('tr', thead).each(function() {
				$($('th', this).get(th)).addClass('active');
			});
		}
		if ( $(propability).length > 0 ) {
			$(propability).parents('td').addClass('active');
		}
		if ( $(severity).length > 0 && $(propability).length > 0 ) {
			$($('td',$(propability).parents('tr')).get(th)).addClass('active');
		}
	});
	$(document).on('mouseover', '.risk-assessment--risk th', function() {
		var th = this;
		$(th).addClass('hover');
		$('tr', $(th).parents('thead')).each(function() {
			$($('th', this).get($(th).index())).addClass('hover');
		});
	});
	$(document).on('mouseout', '.risk-assessment--risk th', function() {
		var th = this;
		$(th).removeClass('hover');
		$('tr', $(th).parents('thead')).each(function() {
			$($('th', this).get($(th).index())).removeClass('hover');
		});
	});
	$(document).on('mouseover', '.risk-assessment--risk td', function() {
		$(this).addClass('hover');
	});
	$(document).on('mouseout', '.risk-assessment--risk td', function() {
		$(this).removeClass('hover');
	});
	$(document).on('click', '.risk-assessment--risk td', function() {
		if ( $(this).is(':first-child') )
			return;
		
		var tr = $(this).parents('tr');
		var table = $(tr).parents('table');
		var pos = $(this).index();
		
		$('input[type=radio]', tr).prop('checked', true).trigger('change');
		
		var headline = $('thead tr:first-child', table);
		var th = $('th', headline).get(pos);
		
		$('input[type=radio]', th).prop('checked', true).trigger('change');
	});
	$(document).on('change', '.terminanfrage-kategorieselect', function() {
		var td = $(this).parents('td');
		$('button.terminanfrage-button', td).attr('data-event-employee-request-category', $(this).val());
	});
	$(document).on('click', '.expand-application', function() {
		var form =  $(document).find('form[name="application"]');
		var toggleClassClicked = $(this).attr('data-toggleclass');
		$(document).find('[class^=position-texttemplate-]').each(function(idx) {
			var classesItem = $(this).attr('class').split(' ');
			if ( !classesItem.includes(toggleClassClicked) && idx % 2 == 0 ) {
				$(this).attr('style', 'display: inline;')
			}
			if ( !classesItem.includes(toggleClassClicked) && idx % 2 == 1 ) {
				$(this).attr('style', 'display: none;')
			}
		});
		if ( form.attr('style') == '' || form.attr('style') == undefined ) {
			form.stop().animate({'max-width':'800px', 'width':'800px'});
		} else {
			var collapse = true;
			$(document).find('[class^=position-texttemplate-]').each(function(idx) {
				if ( idx % 2 === 1 && !($(this).attr('style') === 'display: none;') && !($(this).attr('style') === undefined)) {
					collapse = false;
				}
			});
			if ( collapse ){
				form.attr('style','');
			}
		}
	});
	$(document).on('change', 'select.tariff-currency', function() {
		var currency = $(this).val();
		$('select.tariff-currency').val(currency);
		$('a.tariff-currency').removeClass('hidden');
		$('a.tariff-currency').hide();
		$('a.tariff-currency.'+currency).show();
	});
	$(document).on('change', 'select.indivtabchange', function() {
		var id = $(this).val();
		$('button[name=tab]').each(function() {
			$(this).removeClass('hidden');
			var jobtypes = $(this).attr('data-jobtypes');
			if ( jobtypes === undefined || jobtypes == '' || jobtypes.indexOf('"-1"') >= 0 || jobtypes.indexOf('"'+id+'"') >= 0 )
				$(this).show();
			else
				$(this).hide();
		});
	});
	$(document).on('change', 'input[data-preis]', function() {
		var monatspreis = parseFloat($('input.monatspreis').val());
		var isMwSt = $('input.mwst').length > 0;
		$('input[data-preis]').each(function() {
			if ( $(this).prop('checked') ) {
				monatspreis += parseFloat($(this).attr('data-preis'));
			}
		});
		$('span.monatspreis').each(function() {
			if ( parseInt($(this).attr('data-dauer')) >= 12 )
				$(this).text(formatNumber2User(monatspreis*0.95));
			else
				$(this).text(formatNumber2User(monatspreis));
		});
		$('span.gesamtpreis').each(function() {
			var dauer = parseInt($(this).attr('data-dauer'));
			if ( dauer >= 12 )
				$(this).text(formatNumber2User(monatspreis*dauer*0.95));
			else
				$(this).text(formatNumber2User(monatspreis*dauer));
		});
		if ( isMwSt ) {
			var mwst = parseFloat($('input.mwst').val());
			$('span.mwstbetrag').each(function() {
				var dauer = parseInt($(this).attr('data-dauer'));
				if ( dauer >= 12 )
					$(this).text(formatNumber2User((monatspreis*dauer*0.95)+((monatspreis*dauer*0.95)/100*mwst)));
				else
					$(this).text(formatNumber2User((monatspreis*dauer)+((monatspreis*dauer)/100*mwst)));
			});
		}
	});
	$(document).on('click', 'button.copy-adddate', function() {
		var tr = $('<tr><td></td><td></td></tr>');
		var left = null;
		$('input[type=text]', $(this).parents('td')).each(function() {
			var clone = $(this).clone();

			if ( left == null ) {
				left = $(this).position().left;
				$(clone).css('marginLeft', left);
			}
			
			$('td:last-child', tr).append(clone);
			$('td:last-child', tr).append(' ');
		});
		
		$('td:last-child', tr).append($('.copy-deldate', $(this).parents('td')).clone().removeClass('hidden'));
		
		$(tr).insertBefore('#copy-multiple');
	});
	$(document).on('click', 'button.copy-deldate', function() {
		$(this).parents('tr').remove();
	});
});

function toggleClass(element) {
	var clazz = $(element).attr('data-toggleclass');
	clazz = $('.' + clazz);
	var bubble = clazz.parents('div.bubble');
	if ( bubble.length > 0 ) {
		var keepHeight = $(bubble).attr('data-keepheight');
		if ( keepHeight === undefined )
			keepHeight = $(bubble).height();
		$(bubble).height('auto');
	}


	if ( clazz.closest('div.subemployee-select').length > 0 ) {
		clazz.toggleClass('hidden').css('display', function(_, display) {
			return display === 'none' ? 'inline-block' : '';
		});
	} else {
		clazz.toggle().removeClass('hidden');
	}

	if ( bubble.length > 0 ) {
		$('[data-bubble='+$(bubble).attr('id')+']')[0].bubble.position();
		if ( keepHeight > $(bubble).height() ) {
			$(bubble).height(keepHeight);
			$(bubble).attr('data-keepheight', keepHeight);
		}
	}
	var fold = $('span.fold', element);
	if ($(fold).length > 0 && clazz.is(':visible')) {
		$(fold).html('&times;');
	} else if ($(fold).length > 0 && !clazz.is(':visible')) {
		$(fold).text('+');
	}
}

function createInputFieldsForPersonnelPlanIfNecessary(checkbox, td) {
	if ($('input.anfragenvariante', td).length == 0) {
		var i = $(checkbox).attr('data-i');
		$(td).append('<input type="hidden" name="personalplan-temaid-' + i + '" value="' + $(checkbox).attr('data-temaid') + '" />');
		$(td).append('<input type="hidden" name="personalplan-terminid-' + i + '" value="' + $(checkbox).attr('data-terminid') + '" class="terminid" />');
		$(td).append('<input type="hidden" name="personalplan-zugehoerigkeit-' + i + '" value="' + $(checkbox).attr('data-zugehoerigkeit') + '" class="zugehoerigkeit" />');
		$(td).append('<input type="hidden" name="personalplan-zugehoerigkeitid-' + i + '" value="' + $(checkbox).attr('data-zugehoerigkeitid') + '" class="zugehoerigkeitid" />');
		$(td).append('<input type="hidden" name="personalplan-anfragenvariante-' + i + '" value="" class="anfragenvariante" />');
		if ($(checkbox).attr('data-kategorieid') != undefined) {
			$(td).append('<input type="hidden" name="personalplan-kategorieid-' + i + '" value="' + $(checkbox).attr('data-kategorieid') + '" class="kategorie" />');
		}
		if ($(checkbox).attr('data-freiermitarbeiter') != undefined) {
			$(td).append('<input type="hidden" name="personalplan-freiermitarbeiter-' + i + '" value="' + $(checkbox).attr('data-freiermitarbeiter') + '" class="freiermitarbeiter" />');
		}
		if ($(checkbox).attr('data-subemployee') != undefined) {
			var hidden = $('input[name=personalplan-subemployee-'+i+']', td);
			if ( hidden.length == 0) { // if not already added via select 
				$(td).append('<input type="hidden" name="personalplan-subemployee-' + i + '" value="' + $(checkbox).attr('data-subemployee') + '" class="subemployee" />');
			}
		}
	}
}

function datepickerInitialisieren(el) {
	var datepickerData = $(el).data('Zebra_DatePicker');
	if (datepickerData == undefined) {
		if ($(el).hasClass('years')) {
			datepickerGeburtstage(el);
		} else if ($(el).attr('data-pair') != undefined) {
			datepickerZeitraum(el);
		} else {
			datepicker(el);
		}
	}
}

function datepicker(el) {
	var width = $(el).outerWidth() * (-1);
	var height = $(el).outerHeight() + 260;
	if ($(el).attr('data-von')) { var von = $(el).attr('data-von'); } else { var von = false; }
	if ($(el).attr('data-bis')) { var bis = $(el).attr('data-bis'); } else { var bis = false; }
	if ($(el).attr('data-callback') == 'terminanlegen') {
		var callback = function () {
			var datumsformat = config.dateFormat;
			if (datumsformat == undefined) {
				error('Datumsformat JS nicht definiert (terminanlegen)');
			}
			// Datum aus URL entfernen
			var url = $(el).attr('data-url');
			url = url.substring(0, url.length - 10);
			var datum = $(el).val();
			if (datumsformat == 'GB' || datumsformat == 'US') {
				var datum = datum.split('/');
			} else if (datumsformat == 'INT' || datumsformat == 'BE' ) {
				var datum = datum.split('-');
			} else {
				var datum = datum.split('.');
			}
			if (datumsformat == 'US') {
				url = url + datum[2] + '-' + datum[0] + '-' + datum[1];
			} else if (datumsformat == 'INT') {
				url = url + datum[0] + '-' + datum[1] + '-' + datum[2];
			} else {
				url = url + datum[2] + '-' + datum[1] + '-' + datum[0];
			}
			$(el).attr('data-url', url);
			callAjaxRequest(el);
			hideHinweis($(el).closest('.bubble'));
		};
	} else if ($(el).attr('data-callback') == 'zieldatum') {
		var callback = function () {
			var datum = $(el).val();
			var name = $(el).attr('name');
			if (datum != '') {
				$('[data-bubble=' + name + ']').addClass('active');
			} else {
				$('[data-bubble=' + name + ']').removeClass('active');
			}
		};
	} else if ($(el).hasClass('autosubmit') ) {
		var callback = function () {
			$(el).trigger('change');
		};
	} else {
		var callback = function () {
			// Dummy
		};
	}
	$(el).Zebra_DatePicker({
		format: dateFormat,
		days: [language('js_sonntag'), language('js_montag'), language('js_dienstag'), language('js_mittwoch'), language('js_donnerstag'), language('js_freitag'), language('js_samstag')],
		lang_clear_date: language('js_zuruecksetzen'),
		months: [language('js_januar'), language('js_februar'), language('js_maerz'), language('js_april'), language('js_mai'), language('js_juni'), language('js_juli'), language('js_august'), language('js_september'), language('js_oktober'), language('js_november'), language('js_dezember')],
		show_icon: false,
		direction: [von == 'false' ? false : von, bis == 'false' ? false : bis],
		offset: [width, height],
		onSelect: callback,
		onClear: callback,
		show_select_today: false,
		readonly_element: false,
		first_day_of_week: parseInt(config.weekStart),
		open_on_focus: true
	});
}

function datepickerGeburtstage(el) {
	var width = $(el).outerWidth() * (-1);
	var height = $(el).outerHeight() + 260;
	var callback = function () {
		var id = $(el).attr('id');
		if (id != undefined) {
			var reset = $('input[data-resetfor=' + id + ']');
			if ($(reset).length > 0 && $(el).val() != '') {
				$('input[data-resetfor=' + id + ']').prop('checked', false);
			}
		}
	};
	$(el).Zebra_DatePicker({
		format: dateFormat,
		view: 'years',
		days: [language('js_sonntag'), language('js_montag'), language('js_dienstag'), language('js_mittwoch'), language('js_donnerstag'), language('js_freitag'), language('js_samstag')],
		lang_clear_date: language('js_zuruecksetzen'),
		months: [language('js_januar'), language('js_februar'), language('js_maerz'), language('js_april'), language('js_mai'), language('js_juni'), language('js_juli'), language('js_august'), language('js_september'), language('js_oktober'), language('js_november'), language('js_dezember')],
		show_icon: false,
		offset: [width, height],
		onSelect: callback,
		show_select_today: false,
		readonly_element: false,
		first_day_of_week: parseInt(config.weekStart),
		open_on_focus: true
	});
}

function datepickerZeitraum(el) {
	var pair = $(el).attr('data-pair');
	pair = $('input.datum.bis[data-pair=' + pair + ']');
	var width = $(el).outerWidth() * (-1);
	var height = $(el).outerHeight() + 260;
	if ($(el).attr('data-von')) { var von = $(el).attr('data-von'); } else { var von = false; }
	if ($(el).attr('data-bis')) { var bis = $(el).attr('data-bis'); } else { var bis = false; }
	var callback = function (a, b, c) {
		dateCallbackAusfuehren(el, a, b, c);
	};
	if ($(el).hasClass('von')) {
		checkDateTime('', '', '', el);
		$(el).Zebra_DatePicker({
			format: dateFormat,
			days: [language('js_sonntag'), language('js_montag'), language('js_dienstag'), language('js_mittwoch'), language('js_donnerstag'), language('js_freitag'), language('js_samstag')],
			lang_clear_date: language('js_zuruecksetzen'),
			months: [language('js_januar'), language('js_februar'), language('js_maerz'), language('js_april'), language('js_mai'), language('js_juni'), language('js_juli'), language('js_august'), language('js_september'), language('js_oktober'), language('js_november'), language('js_dezember')],
			show_icon: false,
			offset: [width, height],
			direction: [von, bis],
			pair: pair,
			onSelect: callback,
			show_select_today: false,
			readonly_element: false,
			first_day_of_week: parseInt(config.weekStart),
			open_on_focus: true
		});
	} else {
		var pair = $(el).attr('data-pair');
		var direction = $(el).hasClass('past') ? undefined : true;
		datepickerZeitraum($('input.datum.von[data-pair=' + pair + ']'));
		$(el).Zebra_DatePicker({
			format: dateFormat,
			days: [language('js_sonntag'), language('js_montag'), language('js_dienstag'), language('js_mittwoch'), language('js_donnerstag'), language('js_freitag'), language('js_samstag')],
			lang_clear_date: language('js_zuruecksetzen'),
			months: [language('js_januar'), language('js_februar'), language('js_maerz'), language('js_april'), language('js_mai'), language('js_juni'), language('js_juli'), language('js_august'), language('js_september'), language('js_oktober'), language('js_november'), language('js_dezember')],
			show_icon: false,
			offset: [width, height],
			direction: direction,
			onSelect: callback,
			show_select_today: false,
			readonly_element: false,
			first_day_of_week: parseInt(config.weekStart),
			open_on_focus: true
		});
	}
}

function dateCallbackAusfuehren(el, a, b, c, triggerChange) {
	if ($(el).parents('table.autoadd').length > 0) {
		$('input.geaendert', $(el).parents('tr')).val('1');
	}
	if ($(el).attr('data-callback') == 'abrechnungszeitraum') {
		addZeitraumZuURL(el);
	} else if ($(el).attr('data-callback') == 'urlaubszeitraum') {
		checkDateTime(a, b, c, el);
		refreshUrlaubszeitraum(el);
	} else if ($(el).attr('data-callback') == 'change' || $(el).hasClass('autosubmit') ) {
		checkDateTime(a, b, c, el);
		if (triggerChange == undefined || triggerChange != false) {
			$(el).trigger('change');
		}
	} else {
		checkDateTime(a, b, c, el);
	}
}

function addZeitraumZuURL(el) {
	// Datum aus URL entfernen
	var url = $(el).attr('data-url');
	var datumsformat = config.dateFormat;
	if (datumsformat == undefined) {
		error('Datumsformat JS nicht definiert (abrechnungszeitraum)');
	}
	var datumvon = $('input[data-pair=' + $(el).attr('data-pair') + '].von').val();
	var datumbis = $('input[data-pair=' + $(el).attr('data-pair') + '].bis').val();
	var unbegrenzt = datumbis == '';
	if (datumsformat == 'GB' || datumsformat == 'US') {
		var datumvon = datumvon.split('/');
		var datumbis = datumbis.split('/');
	} else if (datumsformat == 'INT' || datumsformat == 'BE') {
		var datumvon = datumvon.split('-');
		var datumbis = datumbis.split('-');
	} else {
		var datumvon = datumvon.split('.');
		var datumbis = datumbis.split('.');
	}
	if (datumsformat == 'US') {
		datumvon = datumvon[2] + '-' + datumvon[0] + '-' + datumvon[1];
		datumbis = datumbis[2] + '-' + datumbis[0] + '-' + datumbis[1];
	} else if (datumsformat == 'INT') {
		datumvon = datumvon[0] + '-' + datumvon[1] + '-' + datumvon[2];
		datumbis = datumbis[0] + '-' + datumbis[1] + '-' + datumbis[2];
	} else {
		datumvon = datumvon[2] + '-' + datumvon[1] + '-' + datumvon[0];
		datumbis = datumbis[2] + '-' + datumbis[1] + '-' + datumbis[0];
	}
	$(el).attr('data-url', url.replace('datum', datumvon + '-bis-' + (unbegrenzt ? 'unbegrenzt' : datumbis)));
	callAjaxRequest(el);
	hideHinweis($(el).closest('.bubble'));
}

function refreshUrlaubszeitraum(el) {
	var pair = $(el).attr('data-pair');
	var datumsformat = config.dateFormat;
	if (datumsformat == undefined) {
		error('Datumsformat JS nicht definiert (abrechnungszeitraum)');
	}
	var datumvon = $('input.von[data-pair=' + pair + ']').val();
	var datumbis = $('input.bis[data-pair=' + pair + ']').val();
	if (datumsformat == 'GB' || datumsformat == 'US') {
		var datumvon = datumvon.split('/');
		var datumbis = datumbis.split('/');
	} else if (datumsformat == 'INT' || datumsformat == 'BE') {
		var datumvon = datumvon.split('-');
		var datumbis = datumbis.split('-');
	} else {
		var datumvon = datumvon.split('.');
		var datumbis = datumbis.split('.');
	}
	if (datumvon[0] != undefined && datumvon[1] != undefined && datumvon[2] != undefined
		&& datumbis[0] != undefined && datumbis[1] != undefined && datumbis[2] != undefined) {
		if (datumsformat == 'US') {
			datumvon = datumvon[2] + '-' + datumvon[0] + '-' + datumvon[1];
			datumbis = datumbis[2] + '-' + datumbis[0] + '-' + datumbis[1];
		} else if (datumsformat == 'INT') {
			datumvon = datumvon[0] + '-' + datumvon[1] + '-' + datumvon[2];
			datumbis = datumbis[0] + '-' + datumbis[1] + '-' + datumbis[2];
		} else {
			datumvon = datumvon[2] + '-' + datumvon[1] + '-' + datumvon[0];
			datumbis = datumbis[2] + '-' + datumbis[1] + '-' + datumbis[0];
		}
		var target = $('#kalenderurlaubedit-anfragen');
		if ( $(target).length > 0 ) {
			var url = $(target).attr('data-url');
			if ( url !== undefined ) {
				var z = $('input[name=urlaub-zugehoerigkeit]').val();
				var zid = $('input[name=urlaub-zugehoerigkeitid]').val();
				if ( z != 'M' ) 
					zid = zid + z;

				if ( url.indexOf('krankmeldung/') >= 0 ) {
					url = url.substr(0, url.indexOf('krankmeldung/'));
					var name = 'krankmeldung';
				} else if ( url.indexOf('abwesenheit/') >= 0 ) {
					url = url.substr(0, url.indexOf('abwesenheit/'));
					var name = 'abwesenheit';
				} else if ( url.indexOf('zeitraum/') >= 0 ) {
					url = url.substr(0, url.indexOf('zeitraum/'));
					var name = 'zeitraum';
				} else {
					url = url.substr(0, url.indexOf('urlaub/'));
					var name = 'urlaub';
				}
					
				//console.log(url + name + '/neu-' + zid + '-' + datumvon + '-bis-' + datumbis);
				$(target).attr('data-url', url + name + '/neu-' + zid + '-' + datumvon + '-bis-' + datumbis);
				callAjaxRequest(target);
			}
		}
	}
}

function deaktiviereVerplanteFahrer() {
	$('select.fahrer option').prop("disabled", false);
	$('select.fahrer').each(function () {
		var id = $('option:selected', this).attr('value');
		if (id > 0) {
			$('select.fahrer option[value=' + id + ']').not(':selected').attr('disabled', 'disabled');
		}
	});
}

function sendSubscriptionToServer(subscription, method) {
	const key = subscription.getKey('p256dh');
	const token = subscription.getKey('auth');
	const contentEncoding = (PushManager.supportedContentEncodings || ['aesgcm'])[0];
	var data = {};
	data['ajax'] = true;
	data['silent'] = true;
	data['package'] = 'NotificationHelper';
	data['action'] = 'subscribePushNotification';
	data['method'] = method;
	data['type'] = 'D';
	data['endpoint'] = subscription.endpoint;
	data['publickey'] = key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null;
	data['authtoken'] = token ? btoa(String.fromCharCode.apply(null, new Uint8Array(token))) : null;
	data['encoding'] = contentEncoding;
	$.ajax({
		type: 'POST',
		url: window.location,
		data: data
	});
}

function registerServiceWorker() {
	if (!('serviceWorker' in navigator)) {
		return;
	}

	if (!('PushManager' in window)) {
		return;
	}

	if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
		return;
	}

	// Check the current Notification permission.
	// If its denied, the button should appears as such, until the user changes the permission manually
	if (Notification.permission === 'denied') {
		return;
	}

	navigator.serviceWorker.register(config.serviceWorkerUrl)
		.then(function () {
			// console.log('[SW] Service worker has been registered');
			navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
				return serviceWorkerRegistration.pushManager.getSubscription();
			})
				.then(function (subscription) {
					if (!subscription) {
						return;
					}
					sendSubscriptionToServer(subscription, 'UPDATE');
				})
				.catch(function (e) {
					console.error('Error when updating the subscription', e);
				});
		}, function (e) {
			console.error('[SW] Service worker registration failed', e);
		});
}

function urlBase64ToUint8Array(base64String) {
	const padding = '='.repeat((4 - base64String.length % 4) % 4);
	const base64 = (base64String + padding)
		.replace(/\-/g, '+')
		.replace(/_/g, '/');

	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);

	for (var i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}

export function sendForm(form, submit, callback) {
	// Formular per AJAX senden wenn nicht anders definiert
	if (!$(form).attr('data-submit') && !$(submit).attr('data-submit') && !$(form).attr('data-svelte')) {
		// Loading-Gif anzeigen
		var loading = $(form).attr('data-loading');
		if (loading && $('#' + loading).length > 0) {
			$('#' + loading).show();
		} else if (loading) {
			$('.' + loading).show();
		}
		// Bei erste Schritte alle Submits entfernen
		if ($(form).attr('name') == 'ersteschritte') {
			$('[data-formname=ersteschritte]').each(function () {
				$(form).removeAttr('data-formname');
			});
		}
		// Textareas mit Tinyeditor posten
		var abbrechen = false;
		$('textarea', form).each(function () {
			if ($(this).hasClass('tinyeditor') && $(this).data('tinyeditor') != null) {
				var inhalt = $(this).val();
				$(this).data('tinyeditor').post();
				var inhaltNeu = $(this).val();
				if (inhalt != inhaltNeu && $(this).attr('data-changeunterjob') != undefined) {
					if (!requestChangeUnterjob(this, $(form).attr('name'))) {
						abbrechen = true;
					}
				}
			}
		});
		if (abbrechen) {
			if (loading && $('#' + loading).length > 0) {
				$('#' + loading).hide();
			} else if (loading) {
				$('.' + loading).hide();
			}
			return false;
		}
		// Prüfvorschriften für einzelne Formulare
		if ($(form).attr('name') == 'termin' && $('#aenderung-zeitraum', form).length > 0 && $('[name=verfuegbarkeit-zuruecksetzen-abbrechen]', form).length > 0) {
			var zuruecksetzen = $('input[name=verfuegbarkeit-zuruecksetzen-abbrechen]', form).val();
			if (zuruecksetzen != '1' && $(submit).attr('name') != 'check-changedperiod') {
				var changed = false;
				$('[data-origvalue]', form).each(function () {
					if ($(this).val() != $(this).attr('data-origvalue')) {
						changed = true;
					}
				});
				if (changed) {
					$('#aenderung-zeitraum', form).removeClass('hidden').show();
					$('#aenderung-zeitraum', form).topZIndex();
					if (loading && $('#' + loading).length > 0) {
						$('#' + loading).hide();
					} else if (loading) {
						$('.' + loading).hide();
					}
					return false;
				}
			}
		} else if ($(form).attr('name') == 'abrechnungsdefinition' && $('#lohnanpassen', form) && !$('#lohnanpassen', form).is(':visible') && $('select[name=lohnart]', form).attr('data-changed')
			|| $(form).attr('name') == 'abrechnungsdefinition' && $('#lohnanpassen', form) && !$('#lohnanpassen', form).is(':visible') && $('input.lohn[data-changed]', form).length > 0) {
			$('#lohnanpassen', form).removeClass('hidden');
			// 'data-clicked' von den Submits löschen
			$('[data-clicked]').removeAttr('data-clicked');
			if (loading && $('#' + loading).length > 0) {
				$('#' + loading).hide();
			} else if (loading) {
				$('.' + loading).hide();
			}
			return false;
		}  else if ($(form).attr('name') == 'edit-shift' && $('#shiftid', form).val() != 'neu' && $('#shiftchanges', form).length > 0 && !$('#shiftchanges', form).is(':visible') ){
			var changes = false;
			for(var i=0; i<=7; i++) {
				if ( $('input[name=input-shift-daysofweek'+i+']', form).attr('data-changed') )
					changes = true;
			}
			'title projectid timefrom timeuntil auxiliaryjobtitle'.split(' ').forEach(element => {
				if ( $('input[name=input-shift-'+element+']', form).attr('data-changed') )
					changes = true;
			});
			'auxiliaryjobduration'.split(' ').forEach(element => {
				if ( $('select[name=input-shift-'+element+']', form).attr('data-changed') )
					changes = true;
			});
			$('input[id^="neuekategorie-id-"]', form).each(function(){
				if ( $(this).attr('data-changed') ) 
					changes = true;
			});
			$('input[name^="neuekategorie-anzahl-"]', form).each(function(){
				if ( $(this).attr('data-changed') ) 
					changes = true;
			});
			if ( changes ) {
				$('#shiftchanges', form).removeClass('hidden');
				// 'data-clicked' von den Submits löschen
				$('[data-clicked]').removeAttr('data-clicked');
				if (loading && $('#' + loading).length > 0) {
					$('#' + loading).hide();
				} else if (loading) {
					$('.' + loading).hide();
				}
				return false;
			}
		} else if ($(form).attr('name') == 'edit-risk'
					&& $('#input-risk-riskassessmentids', form).length != 0 
					&& $('#input-risk-riskassessmentids', form).val().split(',').filter(Number).length > 0
					&& $('#riskchanges', form) && !$('#riskchanges', form).is(':visible') ) {
			var changes = false;
			// input fields
			'riskfactor-categoryid activityfield-categoryid title icon icon-measures responsibleperson checkbox-not-relevant-substitutionalmeasures checkbox-not-relevant-technicalmeasures checkbox-not-relevant-organisationalmeasures checkbox-not-relevant-personalmeasures'.split(' ').forEach(element => {
				if ( $('input[name='+element+']', form).attr('data-changed') )
					changes = true;
			});
			// input radio
			'propability severity propability-aftermeasures severity-aftermeasures'.split(' ').forEach(element => {
				$('input[name='+element+']', form).each(function(){
					if ( $(this).attr('data-changed') )
						changes = true;
				});
			});
			// textarea tinyeditor
			'description substitutionalmeasures technicalmeasures organisationalmeasures personalmeasures'.split(' ').forEach(element => {
				$('textarea[name='+element+']', form).each(function(){
					if ( $(this).attr('data-changed') || $(this).parent().parent('div.tinyeditor').attr('data-lazy-reveal') == 'true' )
						changes = true;
				});
			});
			if ( changes ) {
				$('#riskchanges', form).removeClass('hidden');
				// 'data-clicked' von den Submits löschen
				$('[data-clicked]').removeAttr('data-clicked');
				if (loading && $('#' + loading).length > 0) {
					$('#' + loading).hide();
				} else if (loading) {
					$('.' + loading).hide();
				}
				return false;
			}
		} else if ( ['termin','aufgabe'].includes( $(form).attr('name') ) ) {
			if ( $('#unsavedcomments', form).is(':visible') ) {
				$('#unsavedcomments').hide();
			} else if ( $(submit).attr('id') == 'termin-submit' ) {
				var unsavedComments = false;
				$('textarea[name=kommentar]', form).each(function(){
					if ( $(this).val() ) {
						unsavedComments = true;
					}
				});
				if ( unsavedComments ) {
					$('#unsavedcomments', form).removeClass('hidden');
					$('[data-clicked]').removeAttr('data-clicked');
					if (loading && $('#' + loading).length > 0) {
						$('#' + loading).hide();
					} else if (loading) {
						$('.' + loading).hide();
					}
					return false;
				}
			}
		} else if ( $(form).attr('name') == 'basedata-edit-DbKunde'  && $('#change-pljobs-in-future', form) && !$('#change-pljobs-in-future', form).is(':visible') ) {
			if ( $('input[name=input-ispersonnelleasing]', form).first().prop('checked') == true
					&& $('input[name=input-ispersonnelleasing]', form).first().attr('data-changed')
					&& $('input[name=input-isdefaultpersonnelleasingjob]', form).first().prop('checked') == true ) {

				$('#change-pljobs-in-future', form).removeClass('hidden');
				if (loading && $('#' + loading).length > 0) {
					$('#' + loading).hide();
				} else if (loading) {
					$('.' + loading).hide();
				}
				return false;
			} 
		} else if ( $(form).attr('name') == 'mitarbeitermail' && $('#hinweis-gebucht-setzen', form).length > 0 && !$('#hinweis-gebucht-setzen', form).is(':visible')) {
			$('#hinweis-gebucht-setzen', form).removeClass('hidden');
			if (loading && $('#' + loading).length > 0) {
				$('#' + loading).hide();
			} else if (loading) {
				$('.' + loading).hide();
			}
			return false;
		}
		// Variablen auslesen
		var variables = {
			loading: loading,
			url: $(form).attr('action') == 'window-href' ? location.href : $(form).attr('action'),
			method: $(form).attr('method'),
			ajax: $(form).attr('data-ajax'),
			pack: $(form).attr('data-package'),
			mode: $(form).attr('data-mode'),
			ajaxSendForm: $(form).attr('data-ajax-sendform'),
			target: $(form).attr('data-target') == undefined ? 'content' : $(form).attr('data-target'),
			header: $(form).attr('data-header'),
			transition: $(form).attr('data-transition'),
			remove: $(form).attr('data-remove'),
			urlremove: $(form).attr('data-urlremove'),
			bubbleclose: $(form).attr('data-bubbleclose'),
			preventurlupdate: $(form).attr('data-urlupdate'),
			scroll: true
		};
		if ( variables.url == undefined && $(form).attr('data-action') != undefined ) {
			variables.url = $(form).attr('data-action') == 'window-href' ? location.href : $(form).attr('data-action');
		}
		// Werte auslesen
		if ( $(form).is('form') ) {
				var formData = new FormData($(form)[0]);
		} else {
			var formData = new FormData();
			$('input, select',form).each(function() {
				formData.append($(this).attr('name'), $(this).val());
			});
		}
		// var formData = new FormData();
		formData.append('ajax', true);
		var additionalData = undefined;
		// Submit-Button auslesen, evtl. Werte überschreiben
		if (submit && $(submit).is('[data-infinitescroll]')) {
			variables.transition = 'add';
			var start = $('input[name=' + $(submit).attr('data-infinitescroll') + ']', submit);
			formData.append('infinitescroll-start', start.val());
			start.remove();
			// Key & Value übernehmen
			if ($(submit).attr('data-name')) {
				var name = $(submit).attr('data-name');
				var value = $(submit).attr('data-value');
				formData.append(name, value);
			}
			// Evtl. Angaben zum Request durch Angaben aus dem Button überschreiben
			overrideValuesWithSubmitButtonValues(submit, variables);
			additionalData = formData;
			additionalData.append('infinitescroll-start', start.val());
		} else if (submit && $(submit).is('[data-sendform]')) { // für Bubbles
			variables.remove = 'false';
			variables.bubbleclose = 'false';
			variables.url = location.href;
			if ($(submit).attr('data-sendform-noajax') != undefined) {
				variables.ajax = undefined;
				variables.pack = undefined;
				variables.target = undefined;
				variables.ajaxSendForm = undefined;
			}
		} else if (submit) { // für Buttons
			// Key & Value übernehmen
			if ($(submit).attr('name')
				&& $(submit).attr('type') !== 'checkbox' && $(submit).attr('type') !== 'radio') {
				var name = $(submit).attr('name');
				if ($(submit).attr('data-value') != undefined) {
					var value = $(submit).attr('data-value');
				} else {
					var value = $(submit).val();
				}
				formData.append(name, value);
			}
			// Evtl. Angaben zum Request durch Angaben aus dem Button überschreiben
			overrideValuesWithSubmitButtonValues(submit, variables);
		}
		// Count für infinitescroll Tabellen hinzufügen (damit alle Daten bei einem refresh neu geladen werden)
		var name = $(form).attr('name');
		if ( name != undefined && name != '' ) {
			$('[data-sendform='+name+']').each(function() {
				if ( $(this).attr('data-infinitescroll') !== undefined ) {
					var input = $('input[name='+$(this).attr('data-infinitescroll')+']');
					if ( $(input).length > 0 ) {
						formData.append('infinitescroll-count', $(input).val());
					} else {
						var input = $('input[name=infinite-limit]');
						if ( $(input).length > 0 ) {
							formData.append('infinitescroll-count', $(input).val());
						}
					}
				}
			});
		}
		// Remove bei fehlendem bubbleclose verhindern
		if (variables.bubbleclose !== undefined && variables.bubbleclose == 'false' && variables.remove == 'hinweis') {
			variables.remove = false;
		}
		// Check if a form has to be sent on success
		if (variables.ajax == undefined && variables.ajaxSendForm !== undefined) {
			variables.ajax = variables.ajaxSendForm;
			variables.pack = 'sendForm';
		}
		// File-Felder anpassen (leere ausfiltern wg. Safari-Bug)
		$('input[type=file]', form).each(function () {
			var files = $(this).prop('files');
			if (files != undefined && files.length <= 0) {
				try {
					formData.delete($(this).attr('name'));
				} catch (e) { }
			}
		});

		$(form)[0].dispatchEvent(createCustomEvent('before-send-form', { detail: { formData } }));

		// Parameter aus der URL entfernen
		if (variables.urlremove) {
			if (variables.urlremove.indexOf('url:') >= 0) {
				variables.url = variables.urlremove.replace('url:', '');
			} else {
				variables.url = removeFromURL(location.href, variables.urlremove);
			}
		}
		if (!formData.has('action') || formData.get('action') == '' ) { // no action specified, calling UI function with additional Data from the form
			var ajaxs = variables.ajax !== undefined ? variables.ajax.split(',') : undefined;
			var packs = variables.pack !== undefined ? variables.pack.split(',') : undefined;
			var targets = variables.target.split(',');
			var transitions = variables.transition !== undefined ? variables.transition.split(',') : undefined;
			for (var i = 0; i < ajaxs.length; i++) {
				ajaxRequest(submit, variables.url, ajaxs[i], targets[i], variables.loading, transitions != undefined ? transitions[i] : undefined, variables.header, true, null, variables.preventurlupdate, packs !== undefined ? packs[i] : undefined, variables.mode, callback, undefined, formData);
			}
			if (variables.loading && $('#' + variables.loading).length > 0) {
				$('#' + variables.loading).hide();
			} else if (variables.loading) {
				$('.' + variables.loading).hide();
			}
		} else {
			if ( additionalData == undefined && $(form).attr('data-ajaxwithformdata') !== undefined ) {
				additionalData = formData;
			}
			sendFormData(form, submit, variables.url, variables.method, formData, variables.loading, variables.ajax, variables.pack, variables.mode, variables.target, variables.header, variables.transition, variables.remove, variables.bubbleclose, variables.preventurlupdate, callback, additionalData, variables.scroll);
		}
		return true;
	}
}

function overrideValuesWithSubmitButtonValues(submit, variables) {
	if ($(submit).attr('data-loading')) {
		var loadingOriginal = variables.loading;
		var hideOriginal = false;
		variables.loading = $(submit).attr('data-loading');
		if (variables.loading && $('#' + variables.loading).length > 0) {
			$('#' + variables.loading).show();
			hideOriginal = true;
		} else if (variables.loading) {
			$('.' + variables.loading).show();
			hideOriginal = true;
		}
		if (loadingOriginal && $('#' + loadingOriginal).length > 0) {
			$('#' + loadingOriginal).hide();
		} else if (loadingOriginal) {
			$('.' + loadingOriginal).hide();
		}
	}
	if ($(submit).attr('data-ajax') != undefined) {
		variables.ajax = $(submit).attr('data-ajax');
	}
	if ($(submit).attr('data-package') != undefined) {
		variables.pack = $(submit).attr('data-package');
	}
	if ($(submit).attr('data-mode') != undefined) {
		variables.mode = $(submit).attr('data-mode');
	}
	if ($(submit).attr('data-target') != undefined) {
		variables.target = $(submit).attr('data-target');
	}
	if ($(submit).attr('data-ajax-sendform') != undefined) {
		variables.ajaxSendForm = $(submit).attr('data-ajax-sendform');
	}
	if ($(submit).attr('data-header') != undefined) {
		variables.header = $(submit).attr('data-header');
	}
	if ($(submit).attr('data-transition') != undefined) {
		variables.transition = $(submit).attr('data-transition');
	}
	if ($(submit).attr('data-remove') != undefined) {
		variables.remove = $(submit).attr('data-remove');
	}
	if ($(submit).attr('data-urlremove') != undefined) {
		variables.urlremove = $(submit).attr('data-urlremove');
	}
	if ($(submit).attr('data-urlupdate') != undefined) {
		variables.preventurlupdate = $(submit).attr('data-urlupdate');
	}
	if ($(submit).attr('data-bubbleclose') != undefined) {
		variables.bubbleclose = $(submit).attr('data-bubbleclose');
	}
	if ($(submit).attr('data-action') && $(submit).attr('data-action') == 'window-href') {
		variables.url = location.href;
	} else if ($(submit).attr('data-action') != undefined) {
		variables.url = $(submit).attr('data-action');
	} else if ($(submit).is('select') && $(submit).attr('data-valueaction') != undefined) {
		variables.url = $(submit).val();
	}
	if ($(submit).attr('data-scroll') && $(submit).attr('data-scroll') == 'top' ) {
		variables.scroll = false;
	}
}

export function sendFormData(form, submit, url, method, formData, loading, ajax, pack, mode, target, header, transition, remove, bubbleclose, preventurlupdate, callback, additionalData, scroll) {
	// AJAX-Request absenden
	$.ajax({
		url: url,
		type: method,
		data: formData,
		contentType: false,
		processData: false,
		tryCount: 0,
		retryLimit: 3,
		success: function (data) {
			if (data == 'login') {
				window.location = url;
				return;
			}

			var htmlData = $(data);

			const loginConfig = htmlData.find('login-config')[0];
			if (loginConfig) {
				onLogin.loggedIn = 1;

				changeTheme(loginConfig.getAttribute('theme'));

				if (loginConfig.hasAttribute('is-demo')) {
					document.body.classList.add('demo');
				}

				if (loginConfig.hasAttribute('has-system-message')) {
					document.body.classList.add('sysmessage');
				}

				for (const translation of loginConfig.querySelectorAll('translation')) {
					updateTranslation(translation.getAttribute('key'), translation.innerHTML);
				}
			}

			htmlData.find('badge').each(function () {
				const badge = document.querySelector(`.badge[data-badge-id="${this.getAttribute('id')}"]`);
				if (badge) {
					const count = this.textContent;
					badge.textContent = count;
					badge.setAttribute('aria-hidden', count && count !== '0' ? 'false' : 'true');
				}
			});

			if (formData && formData.get && formData.get('package') === 'Badge') {
				return;
			}

			// Red zurücksetzen
			$('input, textarea, select', form).not('.button').not('input[type=button]').not('input[type=submit]').removeClass('red');
			// Hinweis auslesen
			htmlData.find('hinweis').each(function () {
				var titel = $('titel', this).text();
				var text = $('text', this).text();
				if (text.length < 100) {
					var css = ' small';
				} else {
					var css = '';
				}
				text = $('text', this).html();
				var autohide = $(this).attr('autohide');
				var hinweis = '<div class="hinweis' + css + '" id="ajax-hinweis" style="display: none; z-index: ' + $.topZIndex() + '"><div class="hinweis__surface"><div class="header row">' + titel + '</div><div class="content row scroll-y">' + text + '</div><div class="footer row"><a class="close">' + language('js_ok') + '</a></div></div></div>';
				if (target == 'main') {
					$('body').append(hinweis);
				} else {
					$('#main').append(hinweis);
				}
				$("#ajax-hinweis").fadeIn(400);
				if (autohide == 'true' && text.indexOf('Fehler') < 0) {
					window.setTimeout(function fadeOut() {
						$("#ajax-hinweis").fadeOut("400", function () {
							$("#ajax-hinweis").remove();
						});
					}, 1500);
				}
				if (loading && $('#' + loading).length > 0) {
					$('#' + loading).hide();
				} else if (loading) {
					$('.' + loading).hide();
				}
			});
			var i = 1;
			// Falsche Input-Felder auslesen
			htmlData.find('fault').each(function () {
				var name = $(this).attr('input');
				if ( name == undefined || name == '' )
					return;

				var fremdid = $('div[data-fremdid=' + name + ']', form);
				if (fremdid.length > 0) {
					var input = fremdid;
				} else {
					var input = $('input[name=' + name + ']', form);
					if (input.length == 0) {
						input = $('select[name=' + name + ']', form);
					}
					if (input.length == 0) {
						input = $('textarea[name=' + name + ']', form);
					}
				}
				if (input.length > 0) {
					// Status auf Rot setzen
					$(input).addClass('red');
					$(input).parents('label').addClass('red');
					var id = $(input).attr('id');
					if (id != undefined) {
						$('label[for=' + id + ']').addClass('red');
					}
					// Zum Element scrollen
					if (i == 1) {
						const parent = getScrollParent(input[0]);
						const parentScrollTop = parent.scrollTop;
						const parentTop = (parent === (document.scrollingElement || document.documentElement))
							? 56 // Header height
							: parent.getBoundingClientRect().top;
						const scrollTop = parentScrollTop - parentTop + input[0].getBoundingClientRect().top - 50;
						if (parentScrollTop > scrollTop || parentScrollTop < parent.scrollHeight - scrollTop) {
							$(parent).animate({ scrollTop });
						}
					}
					// Bubble sichtbar machen, wenn Feld in einem Bubble
					var bubble = $(input).closest('div.bubble');
					if (!$(bubble).is(':visible')) {
						bubbleAnzeigenVerbergen(bubble);
					}
				}
				i++;
			});
			// Input-Felder updaten
			htmlData.find('inputupdate').each(function () {
				var value = $(this).text().split('|');
				if (value.length == 1) {
					$('input[name=' + $(this).attr('input') + ']', form).val($(this).text());
					$('input[name=' + $(this).attr('input') + ']', form).attr('data-origvalue', $(this).text());
				} else if (value.length == 2) {
					var formular = $('form[name=' + value[0] + ']');
					$('input[name=' + $(this).attr('input') + ']', formular).val(value[1]);
					$('input[name=' + $(this).attr('input') + ']', formular).attr('data-origvalue', value[1]);
				}
			});
			// Multiselect-Adds updaten
			htmlData.find('multiselectadd').each(function () {
				var value = $(this).text().split(':');
				var inputName = $(this).attr('input');
				if ( inputName == 'last-clicked' ) {
					var inputName = $('div.multiselect[data-lastclicked]').attr('data-fremdid');
				}
				if (value.length == 2) {
					var input = $('input[name=' + inputName + ']', form);
					var id = value[0];
					var text = value[1];
					var formular = form;
				} else if (value.length == 3) {
					var formular = $('form[name=' + value[0] + ']');
					var input = $('input[name=' + inputName + ']', formular);
					var id = value[1];
					var text = value[2];
				}
				var div = $('div[data-fremdid=' + inputName + ']', formular);
				if ($(div).hasClass('single')) {
					$(input).val(id).trigger('change');
				} else {
					var valueAlt = $(input).val();
					var valueNeu = valueAlt != '' ? valueAlt + ',' + id : id;
					$(input).val(valueNeu).trigger('change');
				}
				$(div).append('<span class="entry" data-value="' + id + '">' + text + ' <a>x</a></span>');
			});
			// Divs nach oben scrollen
			htmlData.find('scrollToTop').each(function () {
				var div = $('#' + $(this).text());
				$(div).animate({
					scrollTop: 0
				});
			});
			// Rückmeldung auslesen
			htmlData.find('action').each(function () {
				// URL aktualisieren
				if (!preventurlupdate && $(this).attr('state') == 'true' ) {
					url = $(this).text();
					updateURL(url, 'Formular ' + $(form).attr('name'));
				}
				var ajaxUrl = url;
				if ( $(form).attr('data-ajax-url') !== undefined && ( $(form).attr('data-ajax') == $(submit).attr('data-ajax') || $(submit).attr('data-ajax') === undefined ) ) {
					ajaxUrl = $(form).attr('data-ajax-url');
					preventurlupdate = true;
				}
				// Neuen Request wenn Status = true
				if ($(this).attr('state') == 'true' && ajax) {
					performAjaxCallsForVerarbeitungsergebnis(this, form, submit, ajaxUrl, ajax, pack, target, transition, mode, loading, header, preventurlupdate, additionalData, bubbleclose, scroll);
				} else if (loading && $('#' + loading).length > 0) {
					$('#' + loading).hide();
				} else if (loading) {
					$('.' + loading).hide();
				}
				if ($(this).attr('state') == 'true' && $(submit).is('a')) {
					if ($(submit).attr('target')) {
						if (!window.open($(submit).attr('href'), $(submit).attr('target'))) {
							// PopUps werden unterdrückt, Meldung ausgeben
							$('#main').append('<div class="ajax-content" style="z-index: ' + $.topZIndex() + '"><div class="hinweis small"><div class="hinweis__surface"><div class="header row">' + language('js_popupaktiv') + '</div><div class="content row">' + language('js_popuphinweis1') + '<a href="' + $(submit).attr('href') + '" target="' + $(submit).attr('target') + '" class="close follow">' + language('js_popuphier') + '</a>' + language('js_popuphinweis2') + '</div><div class="footer row"><a class="orange close">' + language('js_schliessen') + '</a></div></div></div></div>');
						}
					} else {
						window.location.href = $(submit).attr('href');
					}
				}
				// Hinweis entfernen
				if ($(this).attr('state') == 'true' && $(this).attr('preventHinweisRemove') !== undefined && $(this).attr('preventHinweisRemove') == 'true' ) {
					remove = false;
					var firstSubmit = $('input[type=submit].hiddenfield', form);
					if ( $(firstSubmit).attr('data-ajax') !== undefined ) {
						var currentAjax = $(firstSubmit).attr('data-ajax');
						var currentPack = $(firstSubmit).attr('data-package');
						var currentTarget = $(firstSubmit).attr('data-target');
						var currentMode = $(firstSubmit).attr('data-mode');
						var currentTransition = $(firstSubmit).attr('data-transition');
						var currentBubbleclose = $(firstSubmit).attr('data-bubbleclose');
						performAjaxCallsForVerarbeitungsergebnis(this, form, firstSubmit, ajaxUrl, currentAjax, currentPack, currentTarget, currentTransition, currentMode, loading, header, preventurlupdate, additionalData, currentBubbleclose, scroll);
					}
				}
				if ($(this).attr('state') == 'true' && remove && remove == 'hinweis' && target != 'ajax-content-dark' && target != 'ajax-content-self') {
					var hinweis = $(form).closest('div.hinweis');
					removeHinweis(hinweis);
				}
				// Callback ausführen
				if (callback != undefined) {
					callback();
				}
			});
			// Temporäre Felder löschen
			$('input.deleteaftersubmit', form).remove();
			// 'data-clicked' von den Submits löschen
			$('[data-clicked]').removeAttr('data-clicked');
			// Changed zurücksetzen
			$('input, select, textarea', form).removeAttr('data-changed');
		},
		timeout: 30000,
		error: function (jqxhr, status, errormsg) {
			this.tryCount++;
			if (this.tryCount <= this.retryLimit) {
				// try again
				$.ajax(this);
				return;
			} else {
				console.log('Request error');
				console.log(status);
				console.log(errormsg);
				error('Es trat ein Verarbeitungsfehler auf oder die Verbindung wurde zurückgesetzt. Fehlermeldung: ' + status + ' ' + errormsg + '. Response-Text: ' + jqxhr.responseText + ' Headers: ' + jqxhr.getAllResponseHeaders() + ' URL: ' + url + ', AJAX: ' + ajax + ', HEADER: ' + header);
			}
		}
	});
}

function performAjaxCallsForVerarbeitungsergebnis(action, form, submit, url, ajax, pack, target, transition, mode, loading, header, preventurlupdate, additionalData, bubbleclose, scroll) {
	if ( pack !== undefined && pack == 'sendForm' ) {
		var form = $('form[name=' + ajax + ']');
		if ( $(form).length > 0 ) {
			$(form).submit();
		} else {
			form = $('[data-form=' + ajax + ']');
			if ( $(form).length > 0 ) {
				sendForm($(form),submit);
			}
		}
	} else {
		var ajaxs = ajax !== undefined ? ajax.split(',') : undefined;
		var packs = pack !== undefined ? pack.split(',') : undefined;
		var targets = target !== undefined ? target.split(',') : undefined;
		var transitions = transition !== undefined ? transition.split(',') : undefined;
		var modes = mode !== undefined ? mode.split(',') : undefined;
		for (var i = 0; i < ajaxs.length; i++) {
			if ($(action).attr('clear') != 'true' || !$(form).parents('#' + targets[i]).html()) {
				if (targets[i] == 'ajax-content-self') {
					var ajaxContentId = $(form).closest('.ajax-content').attr('id');
					if ( ajaxContentId != undefined && ajaxContentId != '' ) {
						targets[i] = ajaxContentId;
					}
				}
				ajaxRequest(submit, url, ajaxs[i], targets[i], loading, transitions != undefined ? transitions[i] : undefined, header, scroll, null, preventurlupdate, packs !== undefined ? packs[i] : undefined, modes !== undefined ? modes[i] : undefined, undefined, undefined, additionalData);
			} else {
				$('#' + targets[i]).html('');
				if (loading && $('#' + loading).length > 0) {
					$('#' + loading).hide();
				} else if (loading) {
					$('.' + loading).hide();
				}
			}
		}
	}
	// Alle Bubbles schließen, solche mit AJAX-Content zurücksetzen (da sich Daten geändert haben können)
	if (!bubbleclose || bubbleclose == 'true') {
		$('div.bubble').not('.appended').each(function () {
			if ( this.bubble != undefined ) {
				this.bubble.close();
			} else {
				$(this).hide();
			}
			$('#bubblearrow-' + $(this).attr('id')).remove();
			if ($(this).attr('data-ajax') && this.bubble != undefined ) {
				this.bubble.reset();
			}
		});
	}
}

function submitConfirm(input) {
	if ($(input).is('[data-label]')) {
		var linktext = $(input).attr('data-label');
	} else {
		var linktext = $(input).val();
	}

	var hinweistext = $(input).attr('data-confirm');
	if ($(input).attr('data-buttonclass')) {
		var linkclass = $(input).attr('data-buttonclass');
	} else if ($(input).attr('class')) {
		var linkclass = $(input).attr('class');
		linkclass = linkclass.replace('hidden', '');
		linkclass = linkclass.replace('confirm', '');
		linkclass = linkclass.replace('left', '');
		linkclass = linkclass.replace('right', '');
		linkclass = linkclass.replace('big', '');
		linkclass = linkclass.replace(/icon-button.*?(\s|$)/g, '');
	} else {
		var linkclass = '';
	}

	var formname = $(input).closest('form').attr('name');
	var name = $(input).attr('name');
	var value = $(input).attr('value');

	if ($(input).closest('table.autoadd').length > 0) {
		var zeile = $(input).closest('tr').attr('data-zeile');

		if (zeile != undefined && zeile.indexOf('neu') == 0) {
			var addAnzahl = $(input).closest('table').attr('data-addanzahl');

			$('#main').append(`
				<div class="ajax-content" style="z-index: ${$.topZIndex()}">
					<div class="hinweis confirm">
						<div class="hinweis__surface">
							<div class="header">${language('js_bestaetigung')}: ${linktext}</div>
							<div class="content">${hinweistext}</div>
							<div class="footer">
								<button type="button" class="button close removeclicked" data-formname="${formname}">
									${language('js_abbrechen')}
								</button>
								<button
									type="button"
									class="button button--primary close removeclicked ${linkclass}"
									data-formname="${formname}"
									data-removezeile="${zeile}"
									data-addanzahl="${addAnzahl}"
								>${linktext}</button>
							</div>
						</div>
					</div>
				</div>
			`);
			return false;
		}
	}

	if (!$(this).closest('form').attr('data-submit')) {
		if (name != undefined && value != undefined) {
			var submit = ' name="' + name + '" data-value="' + value + '"';
		} else {
			var submit = '';
		}

		$('#main').append(`
			<div class="ajax-content" style="z-index: ${$.topZIndex()}">
				<div class="hinweis confirm">
					<div class="hinweis__surface">
						<div class="header">${language('js_bestaetigung')}: ${linktext}</div>
						<div class="content">${hinweistext}</div>
						<div class="footer">
							<button type="button" class="button close removeclicked" data-formname="${formname}">
								${language('js_abbrechen')}
							</button>
							<button
								type="submit"
								class="button button--primary close ${linkclass} keepclicked"
								data-formname="${formname}"
								${submit}
							>${linktext}</button>
						</div>
					</div>
				</div>
			</div>
		`);
		return false;
	}
}

// Mitarbeiterliste anpassen
function mitarbeiterlisteAnpassen(table) {
	// Mitarbeiter nach oben kopieren, Leerzeilen nach unten
	var prev = undefined;
	$('> tbody > tr, > tr', table).filter(function () {
		return !$(this).parentsUntil(table, ".bubble").length;
	}).each(function () {
		if ($(this).attr('data-kategorie') != undefined) {
			prev = this;
		} else if (prev != undefined && $('td.nn', this).length == 0) {
			$(this).insertAfter(prev);
		}
	});
	// Leerzeilen einfügen
	var anzahlSoll = 0;
	var anzahlIst = 0;
	var massenverarbeitung = $('[data-group="massenverarbeitung"]', $(table).parent().parent()).length > 0;
	var tbody;
	$('> tbody > tr, > tr', table).filter(function () {
		return !$(this).parentsUntil(table, ".bubble").length;
	}).each(function () {
		if ($(this).attr('data-kategorie') != undefined) {
			tbody = $(this).parent();
			// vorherige Leerzeilen einfügen
			if (anzahlSoll > 0 && anzahlIst < anzahlSoll) {
				var zeile = `
					<tr>
						<td></td>
						<td colspan="1337" class="nn">-- ${language('js_nichtbesetzt')} --</td>
					</tr>
				`;
				for (var i = anzahlIst; i < anzahlSoll; i++) {
					$(zeile).insertBefore(this);
				}
			}
			// Neu initialisieren
			var input = $('input.maanzahl', this);
			anzahlSoll = $(input).length > 0 ? $(input).val() : 0;
			anzahlIst = 0;
		} else {
			anzahlIst++;
		}
		if (anzahlIst > anzahlSoll && $('td.nn', this).length > 0) {
			$(this).remove();
		}
	});
	// Leerzeilen für die letzte Kategorie einfügen
	if (anzahlSoll > 0 && anzahlIst < anzahlSoll) {
		var zeile = `
			<tr>
				${massenverarbeitung ? '<td></td>' : ''}
				<td colspan="1337" class="nn">-- ${language('js_nichtbesetzt')} --</td>
			</tr>
		`;
		for (var i = anzahlIst; i < anzahlSoll; i++) {
			$(tbody).append(zeile);
		}
	}
	scrollTableAnpassen($(table));
}

// Bubbles anzeigen
function bubbleAnzeigenVerbergen(bubble, button, hidebubble) {
	if (button == undefined && $(bubble).attr('data-buttonid') != undefined) {
		button = $('#' + $(bubble).attr('data-buttonid'));
	}
	if ($(button).attr('id') == undefined) {
		$(button).attr('id', $(bubble).attr('id') + '-button');
	}
	$(bubble).attr('data-buttonid', $(button).attr('id'));
	if ($(bubble).is(':visible') || hidebubble !== undefined && hidebubble == true) {
		// Is bubble appended to another bubble?
		if ($(bubble).hasClass('appended') && $(bubble).attr('data-bubbleappend') != undefined) {
			var append = $('#' + $(bubble).attr('data-bubbleappend'));
			if ($(append).hasClass('termin') && $(append).width() > 1240) {
				$(append).css('width', '');
				afterRequest(append);
			}
			$(append).css('margin-left', $(append).outerWidth() / 2 * -1);
			$('div.content', append).css('border-right', 'none');
			$(bubble).removeClass('appended');
		}
		$(bubble).hide();
		$('#bubblearrow-' + $(bubble).attr('id')).remove();
	} else {
		if ($(button).is('input[type=radio]')) {
			$(button).attr('data-preventbubblehide', true);
		}
		bubblePositionieren(bubble, button);
		bubbleAjaxLoading(bubble, button);
		// Fokus auf bestimmte Objekte setzen
		$('input[data-focusonvisible]', bubble).each(function () {
			$(this).focus();
		});
		$(button).closest('.scroll-y').on('scroll', function (e) {
			$('.bubble', this).each(function () {
				if ($(this).is(':visible')) {
					var id = $(this).attr('id');
					var link = $('[data-bubble=' + id + ']');
					if ($(link).length > 0) {
						bubblePositionieren(this, link);
					}
				}
			});
		});
	}
}

function bubbleAjaxLoading(bubble, button) {
	// Inhalt per AJAX laden
	if ($(bubble).attr('data-ajax') && $(bubble).html() == ''
		|| $(bubble).attr('data-ajax') && $(bubble).attr('data-forcereload')) {
		var src = $('img.loading').attr('src');
		$(bubble).html('<div class="ajax-loading" id="loading-ajax"><img src="' + src + '" /></div>');
		var form = $(bubble).attr('data-sendform');
		if (form != undefined && $('form[name=' + form + ']').attr('data-submit') != 'no-ajax') {
			$(bubble).attr('data-clicked', '');
			var erg = sendForm($('form[name=' + form + ']'), bubble, function () {
				$(bubble).attr('data-url', location.href);
				var id = $(bubble).attr('id');
				var url = $(bubble).attr('data-url');
				if (button != undefined && url == 'use-caller-url') {
					url = $(button).attr('href');
				}
				var ajax = $(bubble).attr('data-ajax');
				var pack = $(bubble).attr('data-package');
				var mode = $(bubble).attr('data-mode');
				if (button != undefined && $(button).attr('data-mode') != undefined) {
					mode = $(button).attr('data-mode');
				}
				ajaxRequest(bubble, url != undefined ? url : location.href, ajax, id, false, 'fade', undefined, undefined, undefined, true, pack, mode, function () {
					// Prüfen, ob Bubble noch sichtbar
					if ($(bubble).is(':visible')) {
						// Bubble neu positionieren (bei variabler Höhe)
						if ( button != undefined )
							bubblePositionieren(bubble, button);
						// Höhe bei variablen Bubbles festsetzen
						if ($(bubble).hasClass('autosize')) {
							$(bubble).css('height', $(bubble).height());
							// $(bubble).removeClass('autosize'); // sorgt dafür, dass Bubbles beim zweiten Mal nicht mehr autosize annehmen
						}
					}
				}, function () {
					// Sonderbehandlung für Mitarbeiteranfrage
					bubbleSonderbehandlung(bubble, button);
				});
			});
			if (!erg) {
				$(bubble).hide();
				$('#bubblearrow-' + $(bubble).attr('id')).remove();
			}
		} else {
			var url = $(bubble).attr('data-url');
			if (button != undefined && url == 'use-caller-url') {
				url = $(button).attr('href');
			}
			ajaxRequest(bubble, url != undefined ? url : location.href, $(bubble).attr('data-ajax'),
					$(bubble).attr('id'), false, 'fade', undefined, undefined, undefined, true,
					$(bubble).attr('data-package'), $(bubble).attr('data-mode'), function () {
				// Prüfen, ob Bubble noch sichtbar
				if ($(bubble).is(':visible')) {
					// Bubble neu positionieren (bei variabler Höhe)
					if ( button != undefined )
						bubblePositionieren(bubble, button);
					// Höhe bei variablen Bubbles festsetzen
					if ($(bubble).hasClass('autosize')) {
						$(bubble).css('height', $(bubble).height());
						// $(bubble).removeClass('autosize'); // sorgt dafür, dass Bubbles beim zweiten Mal nicht mehr autosize annehmen
					}
				}
			}, function () {
				// Sonderbehandlung für Mitarbeiteranfrage
				bubbleSonderbehandlung(bubble, button);
			});
		}
	} else {
		bubbleSonderbehandlung(bubble, button);
	}
}

function bubbleSonderbehandlung(bubble, button) {
	// Sonderbehandlung für Mitarbeiter-Anfragen
	if ($(bubble).attr('id') == 'terminanfrage') {
		var kategorie = $(button).attr('data-kategorie');
		if (kategorie != undefined && kategorie > 0) {
			var skilllevel = $(button).attr('data-skilllevel');
			$('input[data-field=skilllevel]', bubble).val(skilllevel).trigger('change');
			$('select[data-field=kategorie-1]', bubble).val(kategorie).trigger('change');
			$('select.kategorie', bubble).val(kategorie).trigger('change');
			$('a[data-folder]', bubble).attr('data-kategorie', kategorie);
		} else {
			// $('select[data-field=kategorie-1]',bubble).val('').trigger('change');
			// $('select.kategorie',bubble).val('').trigger('change');
			// $('input[data-group=mitarbeiteranfragen]',bubble).prop('checked',false).trigger('change');
			// $('a[data-folder]',bubble).attr('data-kategorie','');
		}
	}
}

// Monatskalender anpassen
function monatskalenderAnpassen() {
	return;
}

// Wochenkalender anpassen
function wochenkalenderAnpassen() {
	var calendar = $('.calendar--month.week');
	var sort = $(calendar).attr('data-sort');
	if (sort == 'H') {
		// Unterjobs nach ihren Hauptjobs einsortieren
		$('a[data-parentid]',calendar).each(function () {
			var parentid = $(this).attr('data-parentid');
			var parent = $('a[data-terminid=' + parentid + ']', $(this).parent());
			if (parent.length == 0) {
				var spare = '<a href="" class="spare transparent" data-terminid="' + parentid + '"></a>';
				$(this).parent().append(spare);
				$('div.termine', $(this).parents('td').siblings()).each(function () {
					if ($('a[data-parentid=' + parentid + ']', this).length > 0) {
						$(this).append(spare);
					}
				});
				var parent = $('a[data-terminid=' + parentid + ']', $(this).parent());
			}
			while (parent.next().attr('data-parentid') == parentid) {
				parent = parent.next();
			}
			$(this).insertAfter(parent);
		});
	}
	// zusammengehörige Termine auf eine Höhe bringen
	$('a[data-terminid]',calendar).each(function () {
		var terminid = $(this).attr('data-terminid');
		var parentid = $(this).attr('data-parentid');
		var max = 0;
		var maxHeight = 0;
		// Maximale Position ermitteln
		$('a[data-terminid=' + terminid + ']',calendar).each(function () {
			$(this).css('height', 'auto');
			var top = $(this).position().top;
			if (top > max) {
				max = top;
			}
			var height = $(this).height();
			if (height > maxHeight) {
				maxHeight = height;
			}
		});
		// Maximale Position ermitteln
		if (parentid != undefined && sort == 'H') {
			$('.calendar__day',calendar).each(function () {
				$('a[data-parentid=' + parentid + ']').first().each(function () {
					$(this).css('height', 'auto');
					var top = $(this).position().top;
					if (top > max) {
						max = top;
					}
					var height = $(this).height();
					if (height > maxHeight) {
						maxHeight = height;
					}
				});
			});
		}
		maxHeight += 10;
		// Alle Termine auf diese Position setzen
		$('a[data-terminid=' + terminid + ']',calendar).each(function () {
			var top = $(this).position().top;
			var differenz = max - top;
			$(this).css('marginTop', differenz);
			if (!$(this).hasClass('transparent')) {
				$(this).css('height', maxHeight);
			}
		});
	});
	// Set minmum height for week
	var height = $(calendar).height();
	var weekdaysHeight = $('.calendar__weekdays', calendar).height();
	var holidaysHeight = $('.calendar__week.holidays',calendar).height();
	$('.calendar__week',calendar).not('.holidays').height(height-weekdaysHeight-holidaysHeight);
}

var todayLineTimeout;

// Tageskalender anpassen
function tageskalenderAnpassen() {
	// Linie für aktuelle Uhrzeit platzieren
	var table = $('table.day-view');
	var tr = $('tbody tr', table).not('headline').first();
	var start = 0;
	$('td', tr).each(function() {
		if ( !$(this).hasClass('calendar__day__content') )
			start += $(this).outerWidth();
	});
	var gesamtlaenge = $('td', tr).last().outerWidth();
	var stundenwidth = gesamtlaenge/24;
	var minutenwidth = stundenwidth/60;
	var content = $('.main-content');
	if ($('#today-linie').length > 0) {
		if (todayLineTimeout) {
			clearTimeout(todayLineTimeout);
		}
		$('#today-linie').css('height', $(content).innerHeight() - $(table).position().top);
		$('#today-linie').css('position', 'fixed');
		$('#today-linie').css('top', $(content).offset().top + $(table).position().top);
		$('#today-linie').css('zIndex', 22);
		function datum() {
			const todayLine = $('#today-linie');
			if (!todayLine.length) {
				return;
			}

			todayLineTimeout = setTimeout(datum, 10000);

			var uhr = new Date();
			var m = uhr.getMinutes();
			var h = uhr.getHours();
			var px = Math.round((stundenwidth * h) + (minutenwidth * m));

			todayLine.css('margin-left', start + px);
		}
		datum();
	}
}

// Browser-History ändern, neue URL in Elemente einfügen
function updateURL(url, quelle) {
	// console.log('URL: '+url+', Quelle: '+quelle);
	// Browser-History ändern, Adressleiste anpassen
	if (history.pushState) {
		try {
			window.history.pushState(new Object(), document.title, url);
		} catch (e) {
			if (typeof SecurityError != 'undefined' && e instanceof SecurityError && e.message.includes('Attempt to use history')) {
				// nichts tun
			} else {
				throw e;
			}
		}
	}
	// Neue URL in urlupdate-Elemente einfügen
	$('form[data-updateurl=true]').attr('action', url);
	$('a[data-updateurl=true]').attr('href', url);
}

// Einträge aus der URL löschen
function removeFromURL(url, remove) {
	if (remove === undefined || url === undefined)
		return url;
		
	remove = remove.split(',');
	// URL anpassen
	var split = url.split('/');
	var url = '';
	for (var i = 0; i < split.length; i++) {
		if (!in_array(remove, split[i])) {
			if (i > 0) {
				var url = url + '/';
			}
			var url = url + split[i];
		} else {
			if (split[i + 1] && !isNaN(split[i + 1])
					|| split[i + 1] && isDatumGueltig(split[i + 1])
					|| split[i + 1] && split[i + 1].match('neu') != null
					|| split[i + 1] && split[i + 1].match('new') != null
					|| split[i + 1] && split[i + 1].match('copy') != null) {
				i = i + 1;
			}
		}
	}
	return url;
}

function isDatumGueltig(datum) {
	if (datum == '') {
		return false;
	}
	var laenge = datum.length;
	if (laenge != 10 && laenge != 16 && laenge != 19) {
		return false;
	}
	if (substr_count(datum, '-') != 2) {
		return false;
	}
	if (laenge > 10 && substr_count(datum, ' ') != 1) {
		return false;
	}
	if (laenge == 16 && substr_count(datum, ':') != 1
		|| laenge == 19 && substr_count(datum, ':') != 2) {
		return false;
	}
	return true;
}

function substr_count(haystack, needle, offset, length) {
	var cnt = 0;
	haystack += '';
	needle += '';
	if (isNaN(offset)) {
		offset = 0;
	}
	if (isNaN(length)) {
		length = 0;
	}
	if (needle.length === 0) {
		return false;
	}
	offset--;
	while ((offset = haystack.indexOf(needle, offset + 1)) !== -1) {
		if (length > 0 && (offset + needle.length) > length) {
			return false;
		}
		cnt++;
	}
	return cnt;
}

// Eintrag in URL ändern
function changeURL(url, key, value) {
	var split = url.split('/');
	var url = '';
	var changed = false;
	for (var i = 0; i < split.length; i++) {
		if (split[i] != key) {
			if (i > 0) {
				var url = url + '/';
			}
			var url = url + split[i];
		}
		else {
			if (i > 0) {
				var url = url + '/';
			}
			var url = url + split[i];
			if (value != 'false') {
				var url = url + '/' + value;
			}
			if (split[i + 1] && !isNaN(split[i + 1]) || split[i + 1] && split[i + 1].match('neu') != null) {
				i = i + 1;
			}
			changed = true;
		}
	}
	if (changed != true) {
		if (i > 0) {
			var url = url + '/';
		}
		var url = url + key;
		if (value != 'false') {
			var url = url + '/' + value;
		}
	}
	return url;
}

// AJAX-Request über Links aufrufen
function callAjaxRequest(el, hover) {
	var preventurlupdate;
	if (hover == true) {
		hover = 'hover-';
		preventurlupdate = true;
	} else {
		hover = '';
		preventurlupdate = false;
	}
	// Recent-Changes zurücksetzen
	if (!hover && $(el).hasClass('recent-changes') || !hover && $(el).parents('.recent-changes').length > 0) {
		$(el).removeClass('recent-changes');
		$('.recent-changes', el).remove();
		$(el).parents('.recent-changes').each(function () {
			$('.recent-changes', this).not(el).not($(el).parent()).remove();
			$(this).removeClass('recent-changes');
		});
	}
	// Loading-Gif anzeigen
	var loading = $(el).attr('data-' + hover + 'loading');
	if (loading) {
		$('#' + loading).show();
	}
	// Variablen auslesen
	if ($(el).is('select')) {
		if ($(el).attr('data-url')) {
			var url = $(el).attr('data-url') + $(el).val();
		} else {
			var url = $(el).val();
		}
	} else if ($(el).attr('data-' + hover + 'url')) {
		var url = $(el).attr('data-' + hover + 'url');
	} else {
		var url = $(el).attr('href');
	}
	if (url == 'window-href') {
		url = location.href;
	}
	var ajax = $(el).attr('data-' + hover + 'ajax');
	var pack = $(el).attr('data-' + hover + 'package');
	var mode = $(el).attr('data-' + hover + 'mode');
	var target = $(el).attr('data-' + hover + 'target');
	if (!target) {
		target = 'content';
	}
	var transition = $(el).attr('data-' + hover + 'transition');
	var header = $(el).attr('data-' + hover + 'header');
	if ($(el).attr('data-' + hover + 'urlupdate') == 'false') {
		preventurlupdate = true;
	}
	ajax = ajax != undefined ? ajax.split(',') : undefined;
	pack = pack != undefined ? pack.split(',') : undefined;
	mode = mode != undefined ? mode.split(',') : undefined;
	target = target != undefined ? target.split(',') : undefined;
	transition = transition != undefined ? transition.split(',') : undefined;
	var callback = undefined;
	if ($(el).hasClass('addfilter-autosubmit')) {
		callback = function () {
			$(el).parents('form').submit();
		};
	} else if ($(el).hasClass('fremdidauswahl-autosubmit')) {
		callback = function () {
			$('input[name=fremdidauswahl-suchbegriff]').val('').trigger('change');
		};
	}
	// Request absenden
	for (var i = 0; i < ajax.length; i++) {
		ajaxRequest(el, url, ajax[i], target[i], loading, transition != undefined ? transition[i] : undefined, header, false, false, preventurlupdate, pack != undefined ? pack[i] : undefined, mode != undefined ? mode[i] : undefined, callback);
	}
}

// AJAX-Request durchführen
export function ajaxRequest(elem, url, ajax, target, loading, transition, header, scroll, maindata, preventurlupdate, pack, mode, callback, callbackBeforeShow, additionalData) {
	onNavigate.notify({ url, ajax, mode, pack, target });

	// Target auslesen
	if (target == 'ajax-content' || target == 'ajax-content-dark' || target == 'ajax-content-self' ) {
		var d = new Date();
		var id = 'ajax-content-' + d.getTime();
		var ajaxcontent = '<div class="ajax-content remove';
		if (target == 'ajax-content-dark') {
			ajaxcontent += ' dark';
		}
		ajaxcontent += '" id="' + id + '" style="z-index: ' + $.topZIndex() + ';';
		if (transition) {
			ajaxcontent += ' display: none;';
		}
		ajaxcontent += '"><div class="ajax-loading" id="loading-ajax">' + language('js_abrufen') + '...</div></div>';
		$('#main').append(ajaxcontent);
		// Wenn erste Schritte aktiv sind, bekommen diese den höchsten Z-Index
		if ($('.hinweis.first-steps').length > 0) {
			$('.hinweis.first-steps').css('zIndex', ($.topZIndex() + 1));
		}
		if (transition) {
			$('#' + id).fadeIn(400);
		}
		target = id;
	}
	// Notwendigkeit für Anpassung der Scrollbar auslesen
	if (!scroll) {
		scroll = false;
	}

	// console.log('Ajax-Request: '+ajax+' (Package: '+pack+', Mode: '+mode+') für '+target);
	if (additionalData == undefined) {
		var formData = new FormData();
	} else {
		var formData = additionalData;
	}
	if (formData.has('ajax')) {
		formData.delete('ajax');
	}
	if (formData.has('package')) {
		formData.delete('package');
	}
	if (formData.has('mode')) {
		formData.delete('mode');
	}
	if (formData.has('action')) {
		formData.delete('action');
	}
	formData.append('ajax', ajax);
	if (pack != undefined) {
		formData.append('package', pack);
	}
	if (mode != undefined) {
		formData.append('mode', mode);
	}
	try {
		elem = $(elem).get(0).outerHTML;
		elem = elem.substr(0, elem.indexOf('>')+1);
		formData.append('element', elem);
	} catch(e) {
		// do nothing
	}
	if ( target == undefined || target == '' ) {
		if ( elem !== undefined && elem !== null ) {
			elem = $(elem).get(0).outerHTML;
			elem = elem.substr(0, elem.indexOf('>')+1);
			elem = elem.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
		} else {
			elem = 'no elem specified!';
		}
		error('no target specified: ' + elem, new Error().stack);
	}
	
	$.ajax({
		url: url,
		type: 'post',
		data: formData,
		contentType: false,
		processData: false,
		tryCount: 0,
		retryLimit: 3,
		success: function (data) {
			// console.log(data);
			if (data != 'false') {
				if (data == 'login') {
					window.location = url;
					return;
				}
				if ( config.isAutoTheme && config.currentTheme != getBrowserTheme() &&
						$('.farbschema-update').length == 0 ) {
					showFarbschemaHinweis();	
				}
				
				// Farbschema anpassen
				if (ajax == 'printMainUI') {
					var farbschema = $('input[name=farbschema]', data).val();
					if (farbschema != undefined && farbschema != '' && farbschema != 'blue' && !$('#main').hasClass(farbschema)) {
						$('body').append('<div class="row col" id="black" style="background-color: #000000; top: 0px; bottom: 0px; left: 0px; right: 0px; display: none;"></div>');
						$('#black').fadeIn(400, function () {
							$('#main').addClass(farbschema);
							$('#black').fadeOut(400, function () {
								$('#black').remove();
							});
						});
					}
				}
				// URL aktualisieren
				if (!preventurlupdate) {
					updateURL(url, 'ajaxRequest()');
				}
				// Request für Head senden
				if (header) {
					ajaxRequest(elem, url, header, target, loading, transition, false, scroll, data);
				}
				// Inhalt & Header einfügen
				else if (!header && maindata) {
					// Header einfügen
					insertAjaxRequest(data, 'header', false, false, scroll);
					// Inhalt einfügen
					insertAjaxRequest(maindata, target, loading, transition, scroll, callback, callbackBeforeShow);
				}
				// einzelnen Inhalt einfügen
				else {
					insertAjaxRequest(data, target, loading, transition, scroll, callback, callbackBeforeShow);
				}

			} else {
				$('#main').append(`
					<div class="hinweis" id="fadeout" style="z-index: ${$.topZIndex()};">
						<div class="hinweis__surface">
							<div class="header">${language('js_fehler404')}</div>
							<div class="content">${language('js_fehler404text')}</div>
							<div class="footer">
								<button type="button" class="button button--primary close">${language('js_ok')}</button>
							</div>
						</div>
					</div>
				`);

				if (loading && $('#' + loading).length > 0) {
					$('#' + loading).hide();
				} else if (loading) {
					$('.' + loading).hide();
				}
			}
		},
		timeout: 30000,
		error: function (jqxhr, status, errormsg) {
			this.tryCount++;
			if (this.tryCount <= this.retryLimit) {
				// try again
				$.ajax(this);
				return;
			} else {
				console.log('Request error');
				console.log(status);
				console.log(errormsg);
				error('Es trat ein Verarbeitungsfehler auf oder die Verbindung wurde zurückgesetzt. Fehlermeldung: ' + status + ' ' + errormsg + '. Response-Text: ' + jqxhr.responseText + ' Headers: ' + jqxhr.getAllResponseHeaders() + ' URL: ' + url + ', AJAX: ' + ajax + ', HEADER: ' + header);
			}
		}
	});
}

function getTargetElement(target) {
	if ( target.indexOf('class:') == 0 ) {
		target = target.replace('class:', '');
		return $('.' + target);
	} else {
		return $('#' + target);
	}
}

// AJAX-Request einfügen
function insertAjaxRequest(data, target, loading, transition, scroll, callback, callbackBeforeShow) {
	if ( target == 'main' ) {
		registerServiceWorker();
	}
	// console.log('Ajax-Content in '+target+' einfügen');
	var $target = getTargetElement(target);
	// Position der Scrollbar speichern
	var scrolly = $target.scrollTop();
	var scrollyPage = $(window).scrollTop();
	var scrollyTable = undefined;
	var scrollxTable = undefined;
	var table = $('table.scroll-fixed', $target);
	var tbody;
	if (table.length > 0) {
		tbody = $('> tbody', table);
		scrollyTable = tbody.scrollTop();
		scrollxTable = tbody.scrollLeft();
	}
	var scrollyChild = undefined;
	var scrollxChild = undefined;
	if ($target.children().hasClass('scroll-y') || $target.children().hasClass('scroll-x')) {
		scrollyChild = $target.children().scrollTop();
		scrollxChild = $target.children().scrollLeft();
	}

	destroyDateAndTimepicker($target);
	destroyComponents($target[0]);
	if ( transition != 'add' )
		destroyChildBubbles($target);

	if (transition && transition == 'fade' && target === 'termindetails'
		|| transition && transition == 'fade' && target === 'aufgabendetails') {
		let eventDetailsContentScrollPosition;
		if (scroll) {
			const eventDetailsContent = $target.find('.event-details__content');
			if (eventDetailsContent.length) {
				eventDetailsContentScrollPosition = {
					x: eventDetailsContent.scrollLeft(),
					y: eventDetailsContent.scrollTop(),
				};
			}
		}

		$target.fadeOut(200, () => {
			$target.html(data);
			afterRequest(target, 'before');
			if (callbackBeforeShow) {
				callbackBeforeShow(target);
			}

			$target.fadeIn(200, () => {
				afterRequest(target, 'after');
				if (scroll) {
					$target.scrollTop(scrolly);
					if (eventDetailsContentScrollPosition) {
						const eventDetailsContent = $target.find('.event-details__content');
						if (eventDetailsContent.length) {
							eventDetailsContent.scrollLeft(eventDetailsContentScrollPosition.x);
							eventDetailsContent.scrollTop(eventDetailsContentScrollPosition.y);
						}
					}
				}

				if (callback) {
					callback(target);
				}
			});
		});

		return;
	}

	// Mit oder ohne Transition
	if (transition && transition == 'fade') {
		// Wrapper-DIV um den Inhalt legen
		$target.wrapInner('<div class="wrapper" id="wrapper-fade-' + target + '">');
		var wrapper = $('#wrapper-fade-' + target);

		// Scrollbar anpassen
		$target.scrollTop(scroll);

		// Inhalt ausblenden
		wrapper.fadeOut(400, function () {
			// Inhalt einfügen
			wrapper.html(data);
			// console.log('Inhalt in '+target+' eingefügt.');

			// Allgemeine Funktionen für jeden Request (nur 'before')
			afterRequest(target, 'before');
			// console.log('AfterRequest (Modus before) erledigt.');

			// Callback für Änderungen vor der Anzeige
			if (callbackBeforeShow !== undefined) {
				callbackBeforeShow(target);
				// console.log('CallbackBeforeShow erledigt');
			}

			// Inhalt einfaden
			wrapper.fadeIn(400, function () {
				// Wrapper-DIV entfernen
				wrapper.contents().unwrap();
				// console.log('Unwrap durchgeführt');

				// Allgemeine Funktionen für jeden Request (nur 'after')
				afterRequest(target, 'after');
				// console.log('AfterRequest (Modus after) erledigt.');

				// Scrollbar anpassen
				if (scroll && scroll != false) {
					$target.scrollTop(scrolly);
					$(window).scrollTop(scrollyPage);
					if (scrollyTable != undefined) {
						var table = $('table.scroll-fixed', $target);
						if (table.length > 0) {
							tbody = $('> tbody', table);
							tbody.scrollTop(scrollyTable);
							tbody.scrollLeft(scrollxTable);
						}
					}
					if (scrollyChild != undefined) {
						$target.children().scrollTop(scrollyChild);
						$target.children().scrollLeft(scrollyChild);
					}
				} else {
					$target.scrollTop(0);
					$target.scrollLeft(0);
				}

				// Loading-Gif ausblenden
				if (loading) {
					var $loading = $('#' + loading);
					if ($loading.length > 0) {
						$loading.hide();
					} else {
						$('.' + loading).hide();
					}
				}

				if (callback !== undefined) {
					callback(target);
				}
			});
		});

	} else {
		// Inhalt einfügen
		if (transition && transition == 'add') {
			if ($target.children().first().is('table') && $(data).first().is('table') && ($(data).eq(1).length == 0 || $(data).eq(1).is('input[type=hidden]'))) {
				$('tr', $(data).first()).each(function () {
					if ($('th', this).length == 0) {
						$target.children().first().append(this);
					}
				});
				if ($(data).eq(1).length > 0) {
					$target.append($(data).eq(1));
				}
			} else if ( $target.is('ul') && $(data).first().is('ul') ) {
				$(data).children().each(function () {
					$target.append(this);
				});
			} else {
				$target.append(data);
			}
		} else if ( transition && transition == 'replace' ) {
			$target.replaceWith(data);
		} else if ( transition && transition == 'replace-group' ) {
			$target.first().replaceWith(data);
			$target.remove();
		} else {
			$target.html(data);
		}

		// Allgemeine Funktionen für jeden Request
		afterRequest(target);

		// Scrollbar anpassen
		if (scroll && scroll != false) {
			$target.scrollTop(scrolly);
			$(window).scrollTop(scrollyPage);
			if (scrollyTable != undefined) {
				var table = $('table.scroll-fixed', $target);
				if (table.length > 0) {
					tbody = $('> tbody', table);
					tbody.scrollTop(scrollyTable);
					tbody.scrollLeft(scrollxTable);
				}
			}
			if (scrollyChild != undefined) {
				$target.children().scrollTop(scrollyChild);
				$target.children().scrollLeft(scrollyChild);
			}
		} else {
			$target.scrollTop(0);
		}

		// Loading-Gif ausblenden
		if (loading) {
			var $loading = $('#' + loading);
			if ($loading.length > 0) {
				$loading.hide();
			} else {
				$('.' + loading).hide();
			}
		}

		if (callback !== undefined) {
			callback(target);
		}

		if (callbackBeforeShow !== undefined) {
			callbackBeforeShow(target);
		}
	}
}

function removeHinweis(hinweis) {
	if ($(hinweis)[0]) {
		$(hinweis)[0].dispatchEvent(createCustomEvent('dialog-close'));
	}

	// adjust URL
	if ($(hinweis).attr('data-urlremove')) {
		var remove = $(hinweis).attr('data-urlremove');
		if (remove.indexOf('url:') >= 0) {
			var url = remove.replace('url:', '');
		} else {
			var url = removeFromURL(location.href, remove);
		}
		updateURL(url, 'removeHinweis()');
	}
	var transition = $(hinweis).attr('data-transition');
	// delete ajax-content
	if ($(hinweis).parent().hasClass('ajax-content')) {
		var remove = $(hinweis).parent();
	} else {
		var remove = hinweis;
	}
	destroyDateAndTimepicker($(hinweis));
	destroyComponents($(hinweis)[0]);
	destroyChildBubbles($(hinweis));
	if ($(hinweis).attr('id') != undefined && $('[data-bubbleappend=' + $(hinweis).attr('id') + ']').length > 0) {
		var bubble = $('[data-bubbleappend=' + $(hinweis).attr('id') + ']');
		if ($(bubble).is(':visible')) {
			bubbleAnzeigenVerbergen(bubble);
		}
	}
	// check if transition is requested
	if (transition == 'fade') {
		$(remove).fadeOut(400, function () {
			if (!$(remove).hasClass('bubble')) {
				$(remove).remove();
			}
		});
	} else {
		// Hinweis löschen
		if (!$(remove).hasClass('bubble')) {
			$(remove).remove();
		} else {
			hideHinweis(remove);
		}
	}
}

function hideHinweis(hinweis) {
	if ($(hinweis).hasClass('bubble')) {
		$('#bubblearrow-' + $(hinweis).attr('id')).remove();
	}
	$(hinweis).hide();
}

function showHinweis(hinweis) {
	$(hinweis).removeClass('hidden').show();
}

function removeHinweisMitPruefung(hinweis) {
	var form = $('form', hinweis);
	var changed = false;
	$('input, select, textarea', form).each(function () {
		if ($(this).attr('data-changed')) {
			changed = true;
		}
	});
	if (!form || !changed) {
		removeHinweis(hinweis);
	} else {
		$('#main').append(`
			<div class="ajax-content" style="z-index: ${$.topZIndex()}">
				<div class="hinweis">
					<div class="hinweis__surface">
						<div class="header">${language('js_ungespeicherteaenderungen')}</div>
						<div class="content">${language('js_ungespeicherteaenderungentext')}</div>
						<div class="footer">
							<button type="button" class="button close">${language('js_abbrechen')}</button>
							<button
								type="button"
								class="button button--danger close"
								data-formclose="${$('form', hinweis).attr('name')}"
							>${language('js_verwerfen')}</button>
						</div>
					</div>
				</div>
			</div>
		`);
	}
}

// Zeit ermitteln
function getTime(t) {
	var zeitformat = config.timeFormat;
	if (t == undefined) {
		return '00:00';
	} else if (zeitformat == '12H' && ( t.indexOf('AM') >= 0 || t.indexOf('PM') >= 0 ) ) {
		var hours = Number(t.match(/^(\d+)/)[1]);
		var minutes = Number(t.match(/:(\d+)/)[1]);
		var AMPM = t.match(/\s(.*)$/)[1];
		if(AMPM == "PM" && hours<12) hours = hours+12;
		if(AMPM == "AM" && hours==12) hours = hours-12;
		var sHours = hours.toString();
		var sMinutes = minutes.toString();
		if(hours<10) sHours = "0" + sHours;
		if(minutes<10) sMinutes = "0" + sMinutes;
		return sHours + ":" + sMinutes;
	} else {
		if (t.length == 4) {
			var h = t.substring(0, 2);
			var m = t.substring(2, 4);
			return h + ":" + m;
		} else if (t.length == 3) {
			var h = t.substring(0, 1);
			var m = t.substring(1, 3);
			return h + ":" + m;
		} else if (t.length == 2) {
			var h = t.substring(0, 2);
			var m = '00';
			return h + ":" + m;
		} else {
			return t;
		}
	}
}

function getDateFromDatumInput(datum, zeit) {
	var datumsformat = config.dateFormat;
	if (zeit != undefined && $(zeit).val() != '') {
		var zeit = getTime($(zeit).val()).split(':');
	} else {
		var zeit = ('00:00').split(':');
	}
	if ( datum == undefined || $(datum).val() == undefined || $(datum).val() == '' ) {
		return undefined;
	}
	if (datumsformat == 'INT' || datumsformat == 'BE') {
		var datum = $(datum).val().split('-');
	} else if (datumsformat == 'GB' || datumsformat == 'US') {
		var datum = $(datum).val().split('/');
	} else {
		var datum = $(datum).val().split('.');
	}
	if (datumsformat == 'INT') {
		var datum = new Date(datum[0], datum[1] - 1, datum[2], zeit[0], zeit[1], '00');
	} else if (datumsformat == 'US') {
		var datum = new Date(datum[2], datum[0] - 1, datum[1], zeit[0], zeit[1], '00');
	} else {
		var datum = new Date(datum[2], datum[1] - 1, datum[0], zeit[0], zeit[1], '00');
	}
	return datum;
}

// Zeiträume prüfen
function checkDateTime(a, b, c, e) {
	if ($(e).attr('data-pair')) {
		var datumsformat = config.dateFormat;
		var zeitformat = config.timeFormat;
		if (datumsformat == undefined) {
			error('Datumsformat JS nicht definiert (checkDateTime())');
		}
		var pair = $(e).attr('data-pair');
		var kategorie = $('select.kategorie[data-pair=' + pair + ']');
		if ($(kategorie).length > 0) {
			kategorie = $('option:selected', kategorie);
		} else {
			kategorie = undefined;
		}
		var von = getDateFromDatumInput($('input.datum.von[data-pair=' + pair + ']'), $('input.zeit.von[data-pair=' + pair + ']'));
		var bis = getDateFromDatumInput($('input.datum.bis[data-pair=' + pair + ']'), $('input.zeit.bis[data-pair=' + pair + ']'));
		if ($(e).hasClass('von')
				&& von !== undefined
				&& $('input.datum.von[data-pair=' + pair + ']').attr('data-dauer') != undefined
				&& config.keepDuration == '1') { // Dauer auslesen und ggf. anpassen
			if ($(e).attr('data-pair-dauer') == undefined || $(e).attr('data-pair-dauer') == 'true') {
				var dauer = $('input.datum.von[data-pair=' + pair + ']').attr('data-dauer');
				bis = von.getTime() + parseInt(dauer);
				bis = new Date(bis);
				if (!isNaN(bis.getTime())) {
					$('input.datum.bis[data-pair=' + pair + ']').removeClass('red');
					$('input.zeit.bis[data-pair=' + pair + ']').removeClass('red');
					if (datumsformat == 'INT') {
						$('input.datum.bis[data-pair=' + pair + ']').val(bis.getFullYear() + '-' + String("0" + (bis.getMonth() + 1)).slice(-2) + '-' + String("0" + bis.getDate()).slice(-2));
					} else if (datumsformat == 'US') {
						$('input.datum.bis[data-pair=' + pair + ']').val(String("0" + (bis.getMonth() + 1)).slice(-2) + '/' + String("0" + bis.getDate()).slice(-2) + '/' + bis.getFullYear());
					} else if (datumsformat == 'GB') {
						$('input.datum.bis[data-pair=' + pair + ']').val(String("0" + bis.getDate()).slice(-2) + '/' + String("0" + (bis.getMonth() + 1)).slice(-2) + '/' + bis.getFullYear());
					} else if (datumsformat == 'BE') {
						$('input.datum.bis[data-pair=' + pair + ']').val(String("0" + bis.getDate()).slice(-2) + '-' + String("0" + (bis.getMonth() + 1)).slice(-2) + '-' + bis.getFullYear());
					} else {
						$('input.datum.bis[data-pair=' + pair + ']').val(String("0" + bis.getDate()).slice(-2) + '.' + String("0" + (bis.getMonth() + 1)).slice(-2) + '.' + bis.getFullYear());
					}
					if ( zeitformat == '12H' ) {
						$('input.zeit.bis[data-pair=' + pair + ']').val(formatAMPM(bis));
					} else {
						$('input.zeit.bis[data-pair=' + pair + ']').val(String("0" + bis.getHours()).slice(-2) + ':' + String("0" + (bis.getMinutes())).slice(-2));
					}
				}
			}
		} else if ( von !== undefined && bis !== undefined ) { // Dauer berechnen und merken
			var dauer = bis.getTime() - von.getTime();
			if (!isNaN(dauer)) {
				$('input.datum.von[data-pair=' + pair + ']').attr('data-dauer', dauer);
				var inputDauer = $('input.dauer-tag-stunden[data-pair=' + pair + ']');
				var inputTagDauer = $('input.dauer-tag[data-pair=' + pair + ']');
				if ( $(inputDauer).length > 0 && $(inputTagDauer).length > 0 ) {
					// check start & end time with start date
					var vonTime = getTime($('input.zeit.von[data-pair=' + pair + ']').val()).split(':');
					var bisTime = getTime($('input.zeit.bis[data-pair=' + pair + ']').val()).split(':');
					vonTime = parseInt(vonTime[0]*60)+parseInt(vonTime[1]);
					bisTime = parseInt(bisTime[0]*60)+parseInt(bisTime[1]);
					if (bisTime >= vonTime) {
						var dauerTime = bisTime-vonTime;
					} else {
						var dauerTime = bisTime+(1440-vonTime);
					}

					var category = $('select.urlaubskategorie option:selected',$(inputDauer).closest('form')).attr('data-konto');
					var max = $(inputDauer).attr('data-maxvalue');
					var hours = dauerTime / 60;
					if ( max !== undefined && max > 0 && hours > max && category != 'S' )
						hours = max;
					if ( !$(inputDauer).is(e) && !$(inputTagDauer).is(e) ) {
						$(inputDauer).val(formatNumber2User(hours));
					}

					var tagDauer = $(inputTagDauer).attr('data-tag-dauer');
					var tag = (dauerTime / 60) / tagDauer;
					if ( tag > 1 )
						tag = 1;
					if ( !$(inputTagDauer).is(e) && !$(inputDauer).is(e) ) {
						$(inputTagDauer).val(formatNumber2User(tag));
					}
				}
			}
		}
		// Dauer eintragen wenn Feld vorhanden
		if ($('span.dauer[data-pair=' + pair + ']')) {
			var tr = $('span.dauer[data-pair=' + pair + ']').closest('tr');
			var geaendert = '1';
			var zeile = $(tr).attr('data-zeile');
			if ($(tr).length > 0 && $('input.geaendert', tr).length > 0 && zeile.indexOf('neu') == 0) {
				geaendert = $('input.geaendert', tr).val();
			}
			if (geaendert == '1') {
				var dauer = (dauer / 1000) / 3600;
				var pause = kategorie != undefined && $(kategorie).attr('data-pause') == 'true';
				$('span.dauer[data-pair=' + pair + ']').text(formatNumber2User(dauer) + ' h');
				$('span.dauer[data-pair=' + pair + ']').attr('data-pause', pause);
				// Gesamtdauer ändern wenn Feld vorhanden
				if ($('span.dauer[data-pair=' + pair + ']').attr('data-sum')) {
					var sum = $('span.dauer[data-pair=' + pair + ']').attr('data-sum');
					var gesamtdauer = 0;
					$('span.dauer[data-sum=' + sum + ']').each(function () {
						if ($(this).attr('data-pause') == undefined || $(this).attr('data-pause') == 'false') {
							gesamtdauer += +$(this).text().replace(',', '.').replace(' h', '');
						}
					});
					$('span.gesamtdauer[data-sum=' + sum + ']').text(formatNumber2User(gesamtdauer) + ' h');
				}
				// Betrag ändern
				if ($('span.betrag[data-pair=' + pair + ']')) {
					var lohn = $('span.lohn[data-pair=' + pair + ']').text();
					$('span.betrag[data-pair=' + pair + ']').text(formatNumber2User(dauer * lohn) + ' ' + config.currency);
					// Gesamtbetrag ändern wenn Feld vorhanden
					if ($('span.betrag[data-pair=' + pair + ']').attr('data-sum')) {
						var sum = $('span.betrag[data-pair=' + pair + ']').attr('data-sum');
						var gesamtbetrag = 0;
						$('span.betrag[data-sum=' + sum + ']').each(function () {
							gesamtbetrag += +$(this).text().replace(',', '.').replace(' €', '');
						});
						$('span.gesamtbetrag[data-sum=' + sum + ']').text(formatNumber2User(gesamtbetrag) + ' ' + config.currency);
					}
				}
			}
		}
		// Felder rot markieren, wenn negative Dauer
		if (von != undefined && isNaN(von.getTime())
				&& bis != undefined && !isNaN(bis.getTime())) {
			$('input.datum.von[data-pair=' + pair + ']').addClass('red');
			$('input.zeit.von[data-pair=' + pair + ']').addClass('red');
		} else if (bis != undefined
				&& isNaN(bis.getTime())
				&& von != undefined
				&& !isNaN(von.getTime())
				&& ($('input.datum.bis[data-pair=' + pair + ']').val() != '' || $('input.zeit.bis[data-pair=' + pair + ']').val() != '')) {
			$('input.datum.bis[data-pair=' + pair + ']').addClass('red');
			$('input.zeit.bis[data-pair=' + pair + ']').addClass('red');
		} else if (von != undefined
				&& bis != undefined
				&& bis < von) {
			if ($('input.datum.bis[data-pair=' + pair + ']').attr('data-null') == 'true' && bis.getFullYear() == '1970') {
				// nichts tun
			} else {
				$('input.datum.bis[data-pair=' + pair + ']').addClass('red');
				$('input.zeit.bis[data-pair=' + pair + ']').addClass('red');
			}
		} else {
			$('input.datum.von[data-pair=' + pair + ']').removeClass('red');
			$('input.zeit.von[data-pair=' + pair + ']').removeClass('red');
			$('input.datum.bis[data-pair=' + pair + ']').removeClass('red');
			$('input.zeit.bis[data-pair=' + pair + ']').removeClass('red');
		}
		// DatumBis setzen, wenn DatumVon auf später gesetzt wurde
		if ($('input.datum.von[data-pair=' + pair + ']').val() == '' && $('input.datum.von[data-pair=' + pair + ']').val() != '') {
			$('input.datum.bis[data-pair=' + pair + ']').val($('input.datum.von[data-pair=' + pair + ']').val());
			// neu berechnen
			checkDateTime(a, b, c, e);
		}
	}
}

function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  hours = hours < 10 ? '0'+hours : hours;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

// Textareas anpassen
$.fn.elasticArea = function () {
	return this.each(function () {
		const minHeight = $(this).hasClass('small') ? 30 : 150;
		const resizeTextarea = debounce(() => {
			const clone = $(this).clone()
				.removeAttr('id')
				.removeAttr('name')
				.attr('tabindex', '-1')
				.css({
						position: 'absolute',
						top: 0,
						left: -9999,
						height: 0,
					})
				.val(this.value)[0];
			const parent = this.parentElement;
			parent.insertBefore(clone, this);
			const height = clone.scrollHeight;
			parent.removeChild(clone);
			this.style.height = Math.max(height, minHeight) + 'px';
		}, 50);

		resizeTextarea();

		$(this).on('input', resizeTextarea).css('overflow', 'hidden');
	});
};

// Container vertikal zentrieren
function verticalCenter() {
	$('.center-y').each(function () {
		var height = $(this).outerHeight();
		var parentheight = $(this).parent().height();
		if (height < parentheight) {
			$(this).css('margin-top', ((parentheight - height) / 2) + 'px');
		}
	});
}

// Funktionen ausführen, wenn das Fenster geändert wird
var resizeDebounceTimeout = null;
$(window).resize(function () {
	if (resizeDebounceTimeout) {
		clearTimeout(resizeDebounceTimeout);
	}

	resizeDebounceTimeout = setTimeout(function () {
		resizeDebounceTimeout = null;

		$('table.scroll-fixed').each(function () {
			scrollTableAnpassen($(this));
		});

		// Termine im Kalender ausrichten
		if ($('table.kalender').not('div.minikalender table').length > 0) {
			monatskalenderAnpassen();
		}
		if ($('.calendar--month.week').length > 0) {
			wochenkalenderAnpassen();
		}
		if ($('table.day-view').length > 0) {
			tageskalenderAnpassen();
		}
		// Elemente vertikal zentrieren
		verticalCenter();
		// Prüfen, ob alle Toolbar-Elemente in einer Zeile sind
		headerHoeheAnpassen();
	}, 100);
});
// Funktionen ausführen, wenn alle Ressourcen (inkl. Bildern) gelasen sind
$(window).on('load', function () {
	// Prüfen, ob alle Toolbar-Elemente in einer Zeile sind
	headerHoeheAnpassen();
});

// Prüfen, ob alle Toolbar-Elemente in einer Zeile sind
function headerHoeheAnpassen(ohneRuecksprung) {
	var height = 0;
	$('div#header div.toolbar').each(function () {
		if ($(this).offset().top > height) {
			height = $(this).offset().top;
		}
	});
	if (height > 60) {
		$('div.main').addClass('doppelterheader');
	} else if (!ohneRuecksprung) {
		$('div.main').removeClass('doppelterheader');
	}
}

// Help-Bubbles anzeigen
function showHelpBubble(help) {
	// Zugehöriges Element auslesen
	var helpid = $(help).attr('data-helpfor');
	var elem = $('#' + helpid);
	if ($(elem).is(':visible')) {
		// Position auslesen
		var left = $(elem).offset().left + ($(elem).outerWidth() / 2);
		var top = $(elem).offset().top;
		// Help-Bubble positionieren
		$(help).css('top', top + $(elem).outerHeight() + 10);
		$(help).topZIndex();
		// Prüfen, ob Bubble vertikal außerhalb des Viewport liegt, evtl. nach oben verschieben
		if ($(document).height() < $(help).offset().top + $(help).outerHeight()) {
			$(help).css('top', top - $(help).outerHeight() - 10);
			var pfeil = 'down';
		}
		else {
			var pfeil = 'top';
		}
		$(help).css('left', left);
		// Evtl. Pfeil nach links oder rechts verschieben
		if ($(help).hasClass('right')) {
			$(help).css('margin-left', ($(help).outerWidth() * (-1)) + 10);
		}
		else if ($(help).hasClass('left')) {
			$(help).css('margin-left', -10);
		}
		else {
			$(help).css('margin-left', $(help).outerWidth() / 2 * (-1));
		}
		// Prüfen, ob Bubble horizontal außerhalb des Viewport liegt, evtl. anpassen
		if ($(help).offset().left < 0) {
			var diff = $(help).offset().left * (-1);
			$(help).css('left', left + diff + 10);
		}
		else if ($(document).width() < ($(help).offset().left + $(help).outerWidth())) {
			var diff = ($(help).offset().left + $(help).outerWidth()) - $(document).width();
			$(help).css('left', left - diff - 10);
		}
		$(help).css('visibility', 'visible');
		$(help).animate({ opacity: 1 }, 400);
		// Pfeil anzeigen
		if (pfeil == 'top') {
			$(help).append('<div class="helppfeil" id="helppfeil-' + $(help).attr('id') + '" style="top: ' + (top + $(elem).outerHeight() + 4) + 'px; left: ' + (left - 5) + 'px; z-index: ' + $.topZIndex() + ';"></div>');
		} else {
			$(help).append('<div class="helppfeil down" id="helppfeil-' + $(help).attr('id') + '" style="top: ' + (top - 10) + 'px; left: ' + (left - 5) + 'px; z-index: ' + $.topZIndex() + ';"></div>');
		}
	}
}

// Help-Bubbles beim nächsten Klick im Body schließen
function closeHelpBubblesOnBodyClick(a) {
	$('body').one('click', function (e) {
		$('div.help').each(function () {
			$(this).animate({ opacity: 0 }, 400, function () {
				$(this).css('visibility', 'hidden');
				$('.helppfeil', this).remove();
			});
		});
		if (e.target == a) {
			e.stopPropagation();
		}
	});
}

// Submit für Erste Schritte zu Element hinzufügen
function addFirstStepsSubmitToElement(elem) {
	if ($(elem).is('a') || $(elem).is('input') || $(elem).is('button')) {
		$(elem).attr('data-formname', 'ersteschritte');
	}
	else {
		$('a', elem).attr('data-formname', 'ersteschritte');
	}
}

// Date- und Timepicker aufräumen
function destroyDateAndTimepicker(context) {
	// Date- und Timepicker löschen
	$('input.datum', context).each(function () {
		var datepicker = $(this).data('Zebra_DatePicker');
		if (datepicker != undefined) {
			datepicker.destroy();
		}
	});
	$('input.zeit', context).each(function () {
		if ($(this).data('timepickerSettings') != undefined) {
			$(this).timepicker('remove');
		}
	});
}

function destroyChildBubbles(context) {
	if ( $(context).attr('data-keepbubbles') === 'true' )
		return;
	
	$('[data-bubble]', context).each(function() {
		var name = $(this).attr('data-bubble');
		var outOfContext = false;
		$('[data-bubble='+name+']').each(function() {
			if ( context.has(this).length === 0 )
				outOfContext = true;
		});
		if ( !outOfContext )
			$('#'+name).remove();
	});
}

// Funktionen, die nach jedem Request ausgeführt werden sollen
export function afterRequest(context, type) {
	if (context instanceof $) {
		// nichts tun
	} else if (!context) {
		context = 'body';
		context = $(context);
	} else {
		context = '#' + context;
		context = $(context);
	}

	// Set focus to inserted content
	if (
		(!document.activeElement || !document.activeElement.classList.contains('autosubmit'))
		&& context[0] && context.attr('id') === 'content'
	) {
		context[0].setAttribute('tabindex', '-1');
		context[0].focus({ preventScroll: true });
		context[0].removeAttribute('tabindex');
		(document.scrollingElement || document.documentElement).scrollTop = 0;
	}

	// Funktionen, welche auch vor einem Unwrap ausgeführt werden können
	if (!type || type == 'before') {
		// Retina-Images austauschen
		$('img', context).not('.no-retina').retina();
		// Drag & Drop für Tabellen
		let categoryEmployees = [];
		let scrollContainerId = null;
        let scrollAreaSizeTop = 100;
        let scrollAreaSizeBottom = 50;
		$("table[data-dnd=mitarbeiter]", context).each(function() {
			scrollContainerId = this.getAttribute('data-dnd-scrollcontainer');
            scrollAreaSizeTop = this.getAttribute('data-dnd-scrollareasizetop');
            scrollAreaSizeBottom = this.getAttribute('data-dnd-scrollareasizebottom');
		})
		var tbl = $("table[data-dnd=mitarbeiter]", context);

		


	


		if ( tbl && 'tableDnD' in tbl && tbl.tableDnD !== undefined && typeof tbl.tableDnD == 'function' ) {
			tbl.tableDnD({
				onDragStart: function (table, row) {
					if ($(row).parent()[0].className === 'headline-normaltext') {
						let rowCategoryId = $(row).parent().attr('data-kategorie');
						$('table[data-dnd=mitarbeiter] tbody tr.maliste-mitarbeiter').each(function () {
							if ($(this).find('td + td input.kategorie').attr('value') === rowCategoryId) {
								$(this).css('opacity', '0.3');
								categoryEmployees.push($(this));
							}
						});
					}
				},
				onDragStop: function () {
					for (let i = 0; i < categoryEmployees.length; i++) {
						$(categoryEmployees[i].css('opacity', '1'));
					}
	
					categoryEmployees = [];
				},
				onDrop: function (table, row) {
					let useNewStaffplan;
					useNewStaffplanStore.subscribe((value) => useNewStaffplan = value);
					// Logic for : moving resource into another category with the dragndrop icon
					if (useNewStaffplan) {
						moveResourceToNewCategory(row);
					}

					if ($(row).prev()[0] !== undefined) {
						if ($(row).prev().attr('id') === "maliste-kategorie-0" || $(row).prev()[0].className === "nodrag") {
							$(row).insertBefore('table[data-dnd] tbody tr#maliste-kategorie-0');
						}
					}
	
					if (categoryEmployees.length > 0) { // drag categories
						if ($(row).next()[0] === undefined) {
							$(row).insertAfter($('tr.maliste-mitarbeiter:last-child'));
						} else if ($(row).prev().length > 0 && $(row).prev()[0].className === 'headline-normaltext') {
							let categoryId = $(row).prev().attr('data-kategorie');
							let hiddenInputs = $('tr.maliste-mitarbeiter td input.kategorie[type=hidden][value=' + categoryId + ']').get().reverse();
							let lastCategoryRow = $(hiddenInputs[0]).parent().parent();
							$(row).insertAfter(lastCategoryRow);
						} else if ($(row).prev().find('input.kategorie').attr('value') === '0' || $(row).next().find('input.kategorie').attr('value') === '0') {
							let employeesWithoutCategory = $("tr.maliste-mitarbeiter input.kategorie[type=hidden][value='0']");
							let lastEmployee = $(employeesWithoutCategory[employeesWithoutCategory.length - 1]).parent().parent();
							$(row).insertAfter(lastEmployee);
						} else if ($(row).prev()[0] === undefined) {
							$(row).insertBefore($(row).next());
						} else if ($(row).prev()[0].className.match('maliste-mitarbeiter')) {
							let categoryId = $(row).prev().find('td + td input.kategorie[type=hidden]').attr('value');
							let hiddenInputs = $('tr.maliste-mitarbeiter td + td input.kategorie[type=hidden][value=' + categoryId + ']');
							let lastCategoryRow = $(hiddenInputs[hiddenInputs.length - 1]).parent().parent();
							$(row).insertAfter(lastCategoryRow);
						}
					} else { // drag employees / vehicles / rooms
						var categoryType = undefined;
						var prev = $(row).prev();
						while(categoryType == undefined && prev.length > 0 ) {
							categoryType = $(prev).attr('data-type');
							prev = $(prev).prev();
						}
						if ( $(row).attr('data-type') == 'F' ) { // Vehicle
							if ( categoryType == undefined || categoryType != 'F' ) {
								var prevCategory = $('input.kategorie',row).val();
								$(row).insertAfter($('tr[data-kategorie='+prevCategory+']'))
							}
						} else if ( $(row).attr('data-type') == 'RO' ) { // Room
							if ( categoryType == undefined || categoryType != 'RO' ) {
								var prevCategory = $('input.kategorie',row).val();
								$(row).insertAfter($('tr[data-kategorie='+prevCategory+']'))
							}
						} else { // Employee / Freelancer / Subcontractor
							if ( categoryType != undefined && categoryType != 'M' && categoryType != 'E' && categoryType != 'D' ) {
								var prevCategory = parseInt($('input.kategorie',row).val());
								if ( prevCategory > 0 ) {
									$(row).insertAfter($('tr[data-kategorie='+prevCategory+']'))
								} else {
									$(row).insertBefore($('tbody tr',table).first());
								}
							}
						}
					}
	
					for (let i = 0; i < categoryEmployees.length; i++) {
						$(categoryEmployees[i].insertAfter(row));
					}
	
					$('table[data-dnd=mitarbeiter] tbody tr.headline-normaltext').each(function (index) {
						$('td input.kategorie-order[type=hidden]', this).attr('value', index + 1);
					});
	
					mitarbeiterlisteAnpassen(table);
					var kategorie = 0;
					$('tbody tr', table).each(function () {
						if ($(this).attr('data-kategorie')) {
							kategorie = $(this).attr('data-kategorie');
						} else {
							$('input.kategorie', this).each(function () {
								$(this).val(kategorie);
							});
							$('[data-kategorieid]', this).each(function () { // Adjust personnel plan
								var kategorieAlt = $(this).attr('data-kategorieid');
								if (kategorieAlt != kategorie) {
									$(this).attr('data-kategorieid', kategorie);
									if ($(this).is('input[type=checkbox]')) {
										createInputFieldsForPersonnelPlanIfNecessary(this, $(this).parents('td'));
									}
								}
							});
							var div = $('div.lohn', this);
							if (div.length > 0) {
								var formData = new FormData();
								formData.append('i', $(this).attr('data-zeile'));
								formData.append('temaid', $('input[name=mitarbeiter-id-' + $(this).attr('data-zeile') + ']', this).val());
								formData.append('kategorieneu', kategorie);
								ajaxRequest(null, window.location.href, 'printLohnBubble', $('div.lohn', this).attr('id'),
									undefined, undefined, undefined, undefined, undefined, true,
									'TerminSeiteMitarbeiter', undefined, undefined, undefined, formData);
							}
						}
					});
				},
				scrollContainerId: scrollContainerId,
				scrollAreaSizeTop: scrollAreaSizeTop,
				scrollAreaSizeBottom: scrollAreaSizeBottom,
				dragHandle: ".dragndrop"
			});
		}
		
		afterRequestDnDTables(context);
		// Textareas anpassen
		$('textarea.elastic').elasticArea();
		// Elemente vertikal zentrieren
		verticalCenter();
		// Autocomplete für Formulare deaktivieren
		$('input').not('input.autocomplete').attr('autocomplete', 'off');
		$('form').not('form.autocomplete').attr('autocomplete', 'off');
		// Submits für Erste Schritte übernehmen
		$('input[name=ersteSchritteSubmit]').each(function () {
			var id = $(this).val();
			// Prüfen, ob eine ganze Klasse genutzt werden soll
			if (id.indexOf('class:') < 0) {
				addFirstStepsSubmitToElement($('#' + id));
			}
			else {
				var clazz = id.replace('class:', '');
				$('.' + clazz).each(function () {
					addFirstStepsSubmitToElement($(this));
				});
			}
		});
		// Timer initialisieren
		$('.timer').each(function () {
			var start = $(this).attr('data-start');
			if (start == undefined) {
				var id = $(this).attr('id');
				var jetzt = new Date();
				$(this).attr('data-start', jetzt.getTime());
				var arbeitszeit = $(this).text();
				var arbeitszeit = arbeitszeit.split(':');
				var stunden = arbeitszeit[0] * 60 * 60000;
				var minuten = arbeitszeit[1] * 60000;
				var arbeitszeit = stunden + minuten;
				$(this).attr('data-arbeitszeit', arbeitszeit);
				window.setInterval(function () {
					var timer = $('#' + id);
					var start = $(timer).attr('data-start');
					var jetzt = new Date().getTime();
					var arbeitszeit = parseInt($(timer).attr('data-arbeitszeit'));
					arbeitszeit = (arbeitszeit + (jetzt - start)) / 60000;
					var stunde = arbeitszeit / 60;
					var minute = (arbeitszeit % 60);
					$(timer).text(parseInt(stunde) + ':' + String("0" + parseInt(minute)).slice(-2));
				}, 10000);
			}
		});
		// Formulare automatisch nach x Sekunden absenden
		$('[data-autosubmitafter]').each(function () {
			var d = new Date();
			var time = d.getTime();
			var button = $(this);
			var sekunden = $(button).attr('data-autosubmitafter');
			$(button).attr('data-autosubmitfunction', time);
			window.setTimeout(function () {
				var wert = $(button).attr('data-autosubmitfunction');
				if (wert != undefined && wert == time) {
					$(button).click();
				}
			}, parseInt(sekunden) * 1000);
		});
		$('table.sortable').tablesorter();
		$('table.sortable').bind("sortEnd",function(e, table) {
			if ( $(table).attr('data-dnd') == 'nummer' )
				setRowNumForDndNumber(table, false);
		});
		// Push-Notifications möglich?
		if (('serviceWorker' in navigator) && ('PushManager' in window) && ('showNotification' in ServiceWorkerRegistration.prototype)) {
			var cookie = getLocalData('push-norequest');
			if (cookie == 'true') {
				setLocalData('push-norequest', 'true', 30);
			} else {
				if (typeof Notification !== 'undefined' && Notification.permission === 'granted') {
					navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
						serviceWorkerRegistration.pushManager.getSubscription()
							.then(function (subscription) {
								if (subscription) {
									return false;
								} else {
									return true;
								}
							})
							.then(function (show) {
								if (show) {
									$('div.hinweis.push-subscribe').removeClass('hidden').show();
								}
							});
					});
				} else {
					if (!cookie) {
						$('div.hinweis.push-subscribe').removeClass('hidden').show();
					}
				}
			}
			$('div.push-activation', context).each(function () {
				var div = $(this);
				$('p.nopush', div).hide();
				$('p.push-subscribe', div).removeClass('hidden').show();
				if (Notification.permission === 'granted') {
					navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
						serviceWorkerRegistration.pushManager.getSubscription()
							.then(function (subscription) {
								if (subscription) {
									// console.log(subscription);
									$('p.active', div).removeClass('hidden').show();
									$('p.push-subscribe', div).hide();
								}
							});
					});
				}
			});
		}
		scrollToAnker(context);
		// Fahrer- / Mitfahrer-Dropdowns anpassen
		deaktiviereVerplanteFahrer();
	}
	// Funktionen, welche nach dem Unwrap ausgeführt werden müssen
	if (!type || type == 'after') {
		// Ggf. Login-Feld fokussieren
		$('#user', context).focus();
		$('.autofocus', context).focus();
		$('table.scroll-fixed', context).each(function () {
			scrollTableAnpassen($(this));
		});
		// Termine im Kalender ausrichten
		if ($('table.kalender.monat', context).not('div.schnellfunktionen table').length > 0) {
			monatskalenderAnpassen();
		}
		if ($(context).hasClass('calendar--month') && $(context).hasClass('week')
				|| $('.calendar--month.week', context).length > 0) {
			wochenkalenderAnpassen();
		}
		if ($('table.day-view', context).length > 0) {
			tageskalenderAnpassen();
		}
		headerHoeheAnpassen(true);
		if ($(context).attr('id') == 'main') {
			setTimeout(function () {
				headerHoeheAnpassen(true);
			}, 10);
		}
		// Select-Felder anpassen
		$('div.toolbar .select', context).each(function () {
			if (!$(this).hasClass('changed')) {
				var breite = $('select', this).width();
				$('select', this).width(breite + 46);
				$(this).width(breite + 30);
				$(this).css('max-width', breite + 30);
				$(this).addClass('changed');
			}
		});

		// Tinyeditor einfügen
		$('textarea.tinyeditor', context).each(function () {
			createTinyeditor(this);
		});

		// Signature Canvas
		$('canvas.signature',context).each(function() {
			var canvas = this;
			var widthCanvas = false;
			canvas.getAttribute('class').split(' ').forEach((e) => {
				if ( e.startsWith('width') ) {
					let w = e.match(/\d+/g);
					if ( w.length > 0 && parseInt(w[0]) > 0 )
						widthCanvas = w[0]; 
				}
			});
			if ( widthCanvas )
				canvas.setAttribute("width", widthCanvas);

			var signaturePad = new SignaturePad(canvas);
			signaturePad.minWidth = 1;
			signaturePad.maxWidth = 5;
			signaturePad.penColor = "#000000";
			signaturePad.backgroundColor = "#FFFFFF";
			signaturePad.clear();
			$(this).data('signaturePad', signaturePad);
		});
		// Statistik-Canvas erzeugen
		$('canvas[data-chart]', context).each(function () {
			var canvas = $(this);
			// Labels ermitteln
			var labels = new Array();
			$('input[name=labels]', canvas).each(function () {
				labels.push($(this).val());
			});
			var stackable = false;
			var datasets = new Array();
			$('input[name=datasets]', canvas).each(function () {
				var data = new Array();
				var fillcolor = new Array();
				var color = new Array();
				var datasetName = $(this).attr('data-dataset') !== undefined ? $(this).attr('data-dataset') : $(this).val();
				$('input[name=dataset-' + datasetName + '-data]', canvas).each(function () {
					data.push($(this).val());
					fillcolor.push($(this).attr('data-fillcolor'));
					color.push($(this).attr('data-color'));
				});
				var titel = $(this).val();
				if ($(this).attr('data-title') != undefined) {
					titel = $(this).attr('data-title');
				}
				var backgroundColor = $(this).attr('data-fillcolor') != undefined ? $(this).attr('data-fillcolor') : fillcolor;
				var borderColor = $(this).attr('data-color') != undefined ? $(this).attr('data-color') : color;
				var pointColor = borderColor;
				var stack = $(this).attr('data-stack');
				var dataset = {
					label: titel,
					stack: stack,
					backgroundColor: backgroundColor,
					borderColor: borderColor,
					borderWidth: 1,
					pointColor: pointColor,
					pointStrokeColor: "#fff",
					pointHighlightFill: "#fff",
					pointHighlightStroke: "rgba(220,220,220,1)",
					data: data
				};
				datasets.push(dataset);
				if (stack != undefined) {
					stackable = true;
				}
			});
			var lineChartData = {
				labels: labels,
				datasets: datasets
			};
			var position = $(canvas).attr('data-position');
			if (position == undefined) {
				position = 'top';
			}
			if (stackable) {
				var scales = {
					xAxes: [{
						stacked: true
					}],
					yAxes: [{
						stacked: true
					}]
				};
			} else {
				var scales = undefined;
			}
			var displaylegend = $(canvas).attr('data-legend');
			displaylegend = displaylegend == undefined || displaylegend == 'true';
			var ctx = document.getElementById($(canvas).attr('id')).getContext("2d");
			var lineChart = new Chart(ctx, {
				type: $(canvas).attr('data-chart'),
				data: lineChartData,
				options: {
					scales: scales,
					responsive: true,
					maintainAspectRatio: false,
					scaleBeginAtZero: true,
					tooltips: {
						intersect: false
					},
					legend: {
						display: displaylegend,
						position: position,
						labels: {
							boxWidth: 10
						}
					}
				}
			});
		});
		scrollToAnker(context);

		onAfterNavigate.notify({ target: context.attr('id') });

		if (onLogin.loggedIn === 1) {
			onLogin.loggedIn = 2;
			onLogin.notify();
		}
		
		$('a.autoload', context).each(function() {
			callAjaxRequest(this);
		});
	}
}

function afterRequestDnDTables(context) {
	let categories = [];
	var tbl = $("table[data-dnd=unterteilung]", context);
	if ( tbl && 'tableDnD' in tbl && tbl.tableDnD !== undefined && typeof tbl.tableDnD == 'function' ) {
		tbl.tableDnD({
			onDragStart: function (table, row) {
				if ($(row).parent().hasClass('subdivision')) {
					let subdivisionId = $(row).parent().attr('data-unterteilung');
					$('tbody tr.category', table).each(function () {
						if ($(this).find('input.unterteilung').attr('value') === subdivisionId) {
							$(this).css('opacity', '0.3');
							categories.push($(this));
						}
					});
				}
				//console.log(categories);
			},
			onDragStop: function () {
				for (let i = 0; i < categories.length; i++) {
					$(categories[i].css('opacity', '1'));
				}

				categories = [];
			},
			onDrop: function (table, row) {
				if ( $(row).hasClass('subdivision') ) {
					if ( $(row).next()[0] === undefined || $(row).next().hasClass('subdivision') ) {
						// do nothing, valid place for a subdivision
					} else {
						var nextSubdivision = $(row).next();
						while(!$(nextSubdivision).hasClass('subdivision') && $(nextSubdivision).next()[0] !== undefined)
							nextSubdivision = nextSubdivision.next();
							
						if ( $(nextSubdivision).hasClass('subdivision') )
							$(row).insertBefore(nextSubdivision);
						else
							$(row).insertAfter(nextSubdivision);
					}
					
					let insertRow = row;
					for (let i = 0; i < categories.length; i++) {
						$(categories[i].insertAfter(insertRow));
						insertRow = categories[i];
					}
				}

				var subdivision = 0;
				var position = 1;
				var positionSubdivision = 1;
				$('tbody', table).children().each(function () {
					if ($(this).attr('data-unterteilung')) {
						subdivision = $(this).attr('data-unterteilung');
						$('input.position', this).val(positionSubdivision);
						positionSubdivision++;
						position = 1;
					} else {
						$('input.unterteilung', this).val(subdivision);
						$('input.position', this).val(position);
						position++;
					}
				});
			},
			dragHandle: ".dragndrop"
		});
	}
	var tbl = $("table[data-dnd=order]", context);
	if ( 'tableDnD' in tbl && tbl.tableDnD !== undefined ) {
		tbl.tableDnD({
			dragHandle: ".dragndrop"
		});
	}
	$("table[data-dnd] tr", context).not('tr.nodrag').hover(function () {
		$('td.dragndrop.icon', this).addClass('dragndrop-icon');
	}, function () {
		$('td.dragndrop.icon', this).removeClass('dragndrop-icon');
	});
	var tbl = $("table[data-dnd=nummer]", context);
	if ( tbl && 'tableDnD' in tbl && tbl.tableDnD !== undefined && typeof tbl.tableDnD == 'function' ) {
		tbl.tableDnD({
			onDrop: function (table, row) {
				setRowNumForDndNumber(table);
				if ( $(table).hasClass('sortable') ) {
					$(table).trigger('update', [false, undefined]);
				}
			},
			dragHandle: ".dragndrop"
		});
	}
}

function setRowNumForDndNumber(table, changeText) {
	var nr = 0;
	$(table).children('tbody').children().each(function () {
		if ( $(this).hasClass('opacity') )
			return; // do net set the number for new lines
			
		nr++;
		if ( changeText == undefined || changeText == true )
			$(this).children('td').first().text(nr);
		$('input.nummer', this).val(nr).trigger('change');
	});
}

function scrollToAnker(context) {
	// Anker anspringen
	var url = window.location.href;
	if (url.indexOf('#') !== undefined) {
		url = url.split('#');
		if (url[1] !== undefined && url[1] != '' && $('a[name=' + url[1] + ']', context).length > 0) {
			var anker = $('a[name=' + url[1] + ']', context);
			var top = anker.offset().top - $(context).offset().top - 10;
			$(context).animate({
				scrollTop: top
			});
		}
	}
}

function scrollTableScrollHandler() {
	var tbody = $(this);
	var timeout = tbody.data('scrollTableScrollDebounceTimeout');
	if (timeout) {
		clearTimeout(timeout);
	}

	tbody.data('scrollTableScrollDebounceTimeout', setTimeout(function () {
		tbody.data('scrollTableScrollDebounceTimeout', null);
		var thead = tbody.parent().children('thead');
		var theadTrs = thead.children('tr');
		var tbodyTrs = tbody.children('tr');
		var tbodyScrollLeft = tbody.scrollLeft();
		var translate = 'translateX(' + tbodyScrollLeft + 'px)';
		thead.css('transform', 'translateX(-' + tbodyScrollLeft + 'px)'); // fix the thead relative to the body scrolling
		theadTrs.find('th:nth-child(1),td:nth-child(1),th.fixed,td.fixed').css('transform', translate); // fix the cells of the table header
		tbodyTrs.find('td:nth-child(1),td.fixed').css('transform', translate); // fix the cells of table body
	}, 100));
}

function scrollTableAnpassen(table) {
	return;

}

function escapeHtml(text) {
	if (text == undefined) {
		return '';
	}
	var map = {
		'&': '&amp;',
		'<': '&lt;',
		'>': '&gt;',
		'"': '&quot;',
		"'": '&#039;'
	};
	return text.replace(/[&<>"']/g, function (m) { return map[m]; });
}

function bubblePositionieren(bubble, link) {
	if ($(bubble).hasClass('appended')) {
		return;
	}
	if (bubble == undefined || bubble == null || $(bubble).length == 0) {
		var html = $(link).clone().wrap('<p></p>').parent().html();
		if ($(link).attr('href') != undefined) {
			error("Bubble not found for link: " + $(link).attr('href') + ", URL: " + window.location + ", Link: " + escapeHtml(html));
		}
		return;
	}
	if ($(link).length != 1) {
		return;
	}
	// Get position of the link
	var offset = $(link).offset();
	var left = offset.left + ($(link).outerWidth() / 2);
	var top = offset.top + $(link).outerHeight();
	// Reset style attribute of the bubble
	$(bubble).removeAttr('style');
	// Show and position bubble
	$(bubble).css('bottom', '');
	$(bubble).show();
	$(bubble).css('left', left);
	$(bubble).css('top', top + 7);
	$(bubble).css('margin-left', ($(bubble).outerWidth() / 2) * -1);
	// Set the highest z-index
	$(bubble).topZIndex();
	// Set the highest z-index for all datepickers
	$('div.Zebra_DatePicker').topZIndex();
	// Should bubble be appended to another bubble?
	if ($(bubble).attr('data-bubbleappend') != undefined) {
		var append = $('#' + $(bubble).attr('data-bubbleappend'));
		if ($(append).length > 0) {
			var appendOk = false;
			var space = $(append).offset().left;
			if (space < $(bubble).outerWidth() + 20 && (space * 2) > $(bubble).outerWidth() + 10) {
				var margin = parseInt($(append).css('margin-left'));
				margin -= ($(bubble).outerWidth() / 2);
				$(append).css('margin-left', margin);
				appendOk = true;
			} else if (space < $(bubble).outerWidth() && $(append).hasClass('termin') && $(append).outerWidth() > 1240) {
				var reduce = $(append).outerWidth() - 1240;
				if ((space * 2) + reduce > $(bubble).outerWidth()) {
					var rest = $(bubble).outerWidth() - (space * 2);
					rest += 20;
					var width = $(append).width() - rest;
					$(append).css('width', width);
					var margin = $(append).outerWidth() / 2 * -1;
					margin -= ($(bubble).width() / 2);
					$(append).css('margin-left', margin);
					afterRequest(append);
					appendOk = true;
				}
			}
			if (appendOk) {
				// remove class with height and autosize if necessary
				var classlist = $(bubble).attr('class').split(/\s+/);
				for (var i = 0; i < classlist.length; i++) {
					if (classlist[i].indexOf('height') == 0) {
						$(bubble).removeClass(classlist[i]);
					} else if (classlist[i].indexOf('autosize') == 0) {
						$(bubble).removeClass(classlist[i]);
					}
				}
				$(bubble).addClass('appended');
				$(bubble).removeClass('autosize');
				$(bubble).css('top', $(append).offset().top);
				$(bubble).css('left', $(append).offset().left + $(append).outerWidth());
				$(bubble).css('margin-left', 0);
				$(bubble).css('border', 'none');
				$('div.content', append).css('border-right', 'solid 1px #C0C0C0');
				$(bubble).css('height', $(append).outerHeight());
				$(bubble).addClass('shadow-append');
				if ($(append).parents('.ajax-content').hasClass('dark')) {
					$(bubble).addClass('dark');
				}
				if ($(append).hasClass('termin')) {
					$(bubble).addClass('bighead');
				}
				return;
			}
		}
	}
	// Append arrow
	if ($('#bubblearrow-' + $(bubble).attr('id')).length == 0) {
		$(bubble).parent().append('<div class="bubblearrow" id="bubblearrow-' + $(bubble).attr('id') + '"></div>');
	}
	var pfeil = $('#bubblearrow-' + $(bubble).attr('id'));
	$(pfeil).css('top', top + 1);
	$(pfeil).css('left', left - 5);
	$(pfeil).topZIndex();
	// Check if bubble is in viewport
	var parent = $(bubble).parents('div.bubble');
	if (parent.length > 0) {
		var content = $('div.content', parent);
		if (content.length == 0) {
			content = parent;
		}
		var windowtop = content.offset().top;
		var windowheight = content.outerHeight() + content.offset().top;
		var windowwidth = content.outerWidth() + content.offset().left;
		$('div.header', parent).topZIndex();
		$('div.footer', parent).topZIndex();
	} else {
		var windowtop = 0;
		var windowheight = $(document).height();
		var windowwidth = $(document).width();
	}
	if (windowheight < $(bubble).offset().top + $(bubble).outerHeight()
		&& windowtop < $(link).offset().top - 7 - $(bubble).outerHeight()) {
		// Bubble oberhalb des Links anzeigen
		$(pfeil).addClass('down');
		$(bubble).css('top', $(link).offset().top - 7 - $(bubble).outerHeight());
		$(pfeil).css('top', $(link).offset().top - 7);
	} else if (windowheight < $(bubble).offset().top + $(bubble).outerHeight()) {
		// Where is more space?
		if ($(link).offset().top < (windowheight / 2)) { // top
			$(pfeil).addClass('down');
			var offsettop = $(link).offset().top - 7 - $(bubble).outerHeight(); // negative, because outside
			var classlist = $(bubble).attr('class').split(/\s+/);
			$(bubble).css('top', 10); // fix gap from top
			$(bubble).height($(bubble).height() - (offsettop * -1) - 10);
			$(pfeil).css('top', $(bubble).offset().top + $(bubble).height() - (offsettop * -1));
			// remove class with height and autosize if necessary
			for (var i = 0; i < classlist.length; i++) {
				if (classlist[i].indexOf('height') == 0) {
					$(bubble).removeClass(classlist[i]);
				} else if (classlist[i].indexOf('autosize') == 0) {
					$(bubble).removeClass(classlist[i]);
				}
			}
		} else { // bottom
			var offsetbottom = windowheight - $(link).offset().top - 7 - $(bubble).outerHeight();
			var classlist = $(bubble).attr('class').split(/\s+/);
			$(bubble).css('height', $(bubble).height() - (offsetbottom * -1) - 30);
			// remove class with height and autosize if necessary
			for (var i = 0; i < classlist.length; i++) {
				if (classlist[i].indexOf('height') == 0) {
					$(bubble).removeClass(classlist[i]);
				} else if (classlist[i].indexOf('autosize') == 0) {
					$(bubble).removeClass(classlist[i]);
				}
			}
		}
	} else {
		// Show bubble below the link
		$(pfeil).removeClass('down');
	}
	// shift bubble to left or right
	if (windowwidth < $(bubble).offset().left + $(bubble).outerWidth()) {
		var bubbleRight = $(bubble).offset().left + $(bubble).outerWidth();
		var difference = bubbleRight - windowwidth;
		var border = left - difference + ($(bubble).outerWidth() / 2) - left - 10;
		if (border > 60) {
			border = 60;
		}
		$(bubble).css('left', left - difference - border);
	}
	if ($(bubble).offset().left < 0) {
		$(bubble).css('left', left - ($(bubble).offset().left) + 20);
	}
}

function durchsucheListe(input) {
	var search = $(input).attr('data-search');
	if (search == 'maliste-kombisuche') {
		durchsucheMaListe();
	} else {
		if ( search == 'current' ) {
			if ( $(input).closest('ul').length > 0 ) {
				var liste = $(input).closest('ul');
			} else {
				var liste = $(input).closest('table');
			}
		} else {
			var liste = $('#' + search);
			if ($(liste).length == 0) {
				var liste = $('.' + search + ':visible');
			}
		}
		if ($(input).attr('data-subject')) {
			var subject = $(input).attr('data-subject');
		} else {
			var subject = 'search';
		}
		if ($(this).attr('data-searchtype')) {
			var searchtype = $(input).attr('data-searchtype');
		} else {
			var searchtype = 'text';
		}
		var search = encodeURI($(input).val());
		if (search != '') {
			// escape special chars
			search = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
			var searchreg = new RegExp(search.replace(/ /g, ""), 'i');
		}
		if ($(liste).prop('tagName') == 'TABLE') {
			$('tbody tr', liste).each(function () {
				showOrHideRow(this, search, searchtype, searchreg, subject);
			});
			if ($(liste).hasClass('scroll-fixed')) {
				scrollTableAnpassen($(liste));
			}
		} else if ($(liste).prop('tagName') == 'UL') {
			$('li', liste).each(function () {
				showOrHideRow(this, search, searchtype, searchreg, subject);
			});
		}
	}
}

function showOrHideRow(row, search, searchtype, searchreg, subject) {
	// Alle Einträge zeigen, wenn Suchstring leer
	if (search == '' || $('th', row).html()) {
		$(row).show();
	}
	// Ständig sichtbare Einträge
	else if ($(row).hasClass('evershow') ) {
		$(row).show();
	}
	// Einträge nach IDs in definiertem Suchstring filtern
	else if ($(row).attr('data-' + subject) && searchtype == 'id') {
		var ids = $(row).attr('data-' + subject).split(',');
		if (ids.includes(search)) {
			$(row).hide();
		} else {
			$(row).show();
		}
	}
	// Einträge nach definiertem Suchstring filtern
	else if ($(row).attr('data-' + subject) && searchtype == 'text') {
		if (sindAlleSuchstringsEnthalten(encodeURI($(row).attr('data-' + subject)), searchreg) || $(row).attr('data-' + subject) == 'evershow') {
			$(row).show();
		} else {
			$(row).hide();
		}
	}
	// Kein Suchstring, Einträge nach Ihrem Inhalt filtern
	else if (searchtype == 'text') {
		var visible = false;
		if ( $(row).prop('tagName') == 'TR' ) {
			$('td', row).each(function () {
				if (sindAlleSuchstringsEnthalten(encodeURI($(this).text()), searchreg)) {
				visible = true;
				}
			});
		} else {
			if (sindAlleSuchstringsEnthalten(encodeURI($(row).text()), searchreg)) {
				visible = true;
			}
		}
		if (visible == false) {
			$(row).hide();
		} else {
			$(row).show();
		}
	} else {
		$(row).hide();
	}
}

function durchsucheMaListe() {
	var kat1 = $('select[data-field=kategorie-1]').val();
	var kat2 = $('select[data-field=kategorie-2]').val();
	var kat3 = $('select[data-field=kategorie-3]').val();
	var zertifikat = $('select[data-field=zertifikat]').val();
	var skilllevel = $('input[data-field=skilllevel]').val();
	var umkreis = $('select[data-field=umkreis]').val();
	var sprache = $('select[data-field=sprache]').val();
	if (umkreis == '') {
		umkreis = undefined;
	}
	// if (kat1 != '' && kat2 != '') {
	//   $('select[data-field=kategorie-1]').removeClass('width100');
	//   $('select[data-field=kategorie-2]').removeClass('width100');
	//   $('select[data-field=zertifikat]').removeClass('width100');
	//   $('select[data-field=sprache]').removeClass('width100');
	//   $('select[data-field=kategorie-1]').css('width', '75px');
	//   $('select[data-field=kategorie-2]').css('width', '75px');
	//   $('select[data-field=zertifikat]').css('width', '75px');
	//   $('select[data-field=sprache]').css('width', '75px');
	//   $('select[data-field=kategorie-3]').show();
	// } else if (kat3 == '') {
	//   $('select[data-field=kategorie-3]').hide();
	//   $('select[data-field=kategorie-1]').css('width', '100px');
	//   $('select[data-field=kategorie-2]').css('width', '100px');
	//   $('select[data-field=zertifikat]').css('width', '100px');
	//   $('select[data-field=sprache]').css('width', '100px');
	// }
	$('.mitarbeiteranfragenliste').each(function () {
		var liste = $(this);
		$('tr', liste).each(function () {
			if ($(this).attr('data-search') === 'evershow') return;
			if (!$('input[type=checkbox]', this).prop('checked')) {
				$('select.kategorie', this).val(kat1).trigger('change');
				$('input[type=checkbox]', this).prop('checked', false);
			}
			if ($('th', this).html()) {
				$(this).css('display', '');
			} else if (kat1 == '' && kat2 == '' && kat3 == '' && zertifikat == '' && skilllevel == '0' && umkreis == undefined && sprache == '') {
				$(this).css('display', '');
			} else {
				var umkreisOk = false;
				if (umkreis != undefined && $(this).attr('data-entfernung') != undefined) {
					var entfernung = parseFloat($(this).attr('data-entfernung'));
					if (entfernung >= 0 && entfernung <= umkreis) {
						// console.log(entfernung+' '+umkreis);
						umkreisOk = true;
					}
				} else {
					umkreisOk = true;
				}
				var zertifikatOk = false;
				if (zertifikat != '') {
					var zertifikate = $(this).attr('data-zertifikate');
					if (zertifikate != undefined) {
						if (zertifikate.split(';').includes(zertifikat)) {
							zertifikatOk = true;
						}
					}
				} else {
					zertifikatOk = true;
				}
				var spracheOk = false;
				if (sprache != '') {
					var sprachen = $(this).attr('data-sprachen');
					if (sprachen != undefined) {
						if (sprachen.split(';').includes(sprache)) {
							spracheOk = true;
						}
					}
				} else {
					spracheOk = true;
				}
				var kategorie = $('select.kategorie', this);
				if (!umkreisOk || !zertifikatOk || !spracheOk) {
					$(this).css('display', 'none');
				} else if ($(kategorie).length > 0) {
					if (kat1 == '' && kat2 == '' && kat3 == '' && skilllevel != '0') {
						var show = false;
						$('option', kategorie).each(function () {
							if (skilllevel <= $(this).attr('data-skilllevel')) {
								show = true;
							}
						});
						if (show) {
							$(this).css('display', '');
						} else {
							$(this).css('display', 'none');
						}
					} else {
						if (kat1 != '') {
							var kat1Vorhanden = false;
							var kat1Option = $('option[value=' + kat1 + ']', kategorie);
							if ($(kat1Option).length > 0) {
								if (skilllevel == '0' || skilllevel <= $(kat1Option).attr('data-skilllevel')) {
									kat1Vorhanden = true;
								}
							}
						} else {
							var kat1Vorhanden = true;
						}
						if (kat2 != '') {
							var kat2Vorhanden = false;
							var kat2Option = $('option[value=' + kat2 + ']', kategorie);
							if ($(kat2Option).length > 0) {
								if (skilllevel == '0' || skilllevel <= $(kat2Option).attr('data-skilllevel')) {
									kat2Vorhanden = true;
								}
							}
						} else {
							var kat2Vorhanden = true;
						}
						if (kat3 != '') {
							var kat3Vorhanden = false;
							var kat3Option = $('option[value=' + kat3 + ']', kategorie);
							if ($(kat3Option).length > 0) {
								if (skilllevel == '0' || skilllevel <= $(kat3Option).attr('data-skilllevel')) {
									kat3Vorhanden = true;
								}
							}
						} else {
							var kat3Vorhanden = true;
						}
						if (kat1Vorhanden && kat2Vorhanden && kat3Vorhanden) {
							$(this).css('display', '');
						} else {
							$(this).css('display', 'none');
						}
					}
				} else {
					$(this).css('display', '');
				}
			}
		});
	});
}

function sindAlleSuchstringsEnthalten(text, searchreg) {
	if (text.search(searchreg) == -1) {
		return false;
	}
	return true;
}

function requestChangeUnterjob(el) {
	const element = $(el);
	const input = element.attr('data-changeunterjob');

	if ($('input[name=' + input + ']').length > 0) {
		const group = element.attr('data-group') || '';
		const name = element.attr('name');
		const content = element.attr('data-changeunterjobconfirm');

		$('#main').append(`
			<div class="ajax-content" style="z-index: ${$.topZIndex()}">
				<div class="hinweis confirm">
					<div class="hinweis__surface">
						<div class="header">${language('js_uebernahme')}</div>
						<div class="content">${content}</div>
						<div class="footer">
							<button type="button" class="button close" data-valuefor="${input}" data-value="0">
								${language('js_nein')}
							</button>
							<button
								type="button"
								class="button button--primary close"
								data-valuefor="${input}"
								data-value="1"
								data-group="${group}"
								data-origname="${name}"
							>${language('js_ja')}</button>
						</div>
					</div>
				</div>
			</div>
		`);
		return false;
	}

	return true;
};

export function createTinyeditor(textarea) {
	if (textarea.hasAttribute('data-tinyedit')) {
		return;
	}

	const id = textarea.getAttribute('id');
	if (!id || id.indexOf('-') != -1) {
		error('Textarea mit Bindestrich: ' + id);
	}

	const { classList } = textarea;
	const inBox = $(textarea).parents('.box').length > 0;
	const lazyReveal = textarea.getAttribute('data-lazy-reveal') === 'true';

	const textColor = config.currentTheme === 'dark' ? '#fff' : '#000';
	const bgRGB = config.currentTheme === 'dark' ? '255' : '0';

	let bg = 'rgba(' + bgRGB + ', ' + bgRGB + ', ' + bgRGB + ', 0.1)';
	let bgHover = 'rgba(' + bgRGB + ', ' + bgRGB + ', ' + bgRGB + ', 0.2)';

	if (lazyReveal) {
		bgHover = bg;
		bg = 'transparent';
	} else if (inBox && config.currentTheme !== 'dark') {
		bg = '#fff';
		bgHover = 'rgba('+bgRGB+', '+bgRGB+', '+bgRGB+', 0.05)';
	}

	const width = textarea.getAttribute('data-width') || '100%';
	const footer = classList.contains('html');
	const extraButton = classList.contains('extra-button');
	const singleLineControls = textarea.getAttribute('data-single-line-controls') === 'true';
	const onlyBaseControls = textarea.getAttribute('data-basecontrols') === 'true';
	const showFullControls = textarea.getAttribute('data-fullcontrols') === 'true';
	const placeholder = textarea.getAttribute('placeholder');

	if ( textarea.getAttribute('data-height') !== undefined && textarea.getAttribute('data-height') !== null ) {
		var subtract = 40;
		if ( !onlyBaseControls && !singleLineControls )
			subtract += 35;
		if ( $(textarea).next().hasClass('textvorlagen') )
			subtract += 35;
		if ( textarea.getAttribute('data-height') == 'stretch' ) {
			var height = $(textarea).parent().innerHeight()-subtract;
		} else {
			var height = textarea.getAttribute('data-height');
		}
	} else {
		var height = 200;
	}
			
	const baseControls = [
		'bold', 'italic', 'underline',
		'|',
		'orderedlist', 'unorderedlist',
		'|',
		'unformat'
	];
	const controls = [
		'size',
		'|',
		'undo', 'redo',
		'|',
		'link', 'unlink',
		'|',
		'orderedlist', 'unorderedlist', 'outdent', 'indent',
		singleLineControls ? '|' : 'n',
		'bold', 'italic', 'underline',
		'|',
		'leftalign', 'centeralign', 'rightalign', 'blockjustify',
		'|',
		'unformat'
	];
	const fullControls = [
		'style','size',
		'|',
		'undo', 'redo',
		'|',
		'link', 'unlink','image',
		'|',
		'orderedlist', 'unorderedlist', 'outdent', 'indent',
		singleLineControls ? '|' : 'n',
		'bold', 'italic', 'underline',
		'|',
		'leftalign', 'centeralign', 'rightalign', 'blockjustify',
		'|',
		'unformat'
	];
	
	const editor = new TINY.editor.edit(id, {
		id,
		footer,
		controls: showFullControls ? fullControls : (onlyBaseControls ? baseControls : controls),
		width: width,
		height: height + 'px;',
		cssclass: 'tinyeditor',
		controlclass: 'tinyeditor-control',
		rowclass: 'tinyeditor-header',
		dividerclass: 'tinyeditor-divider',
		fonts: ['Verdana', 'Arial', 'Georgia', 'Trebuchet MS'],
		xhtml: true,
		toggle: { text: 'HTML-Code', activetext: 'HTML-Editor', cssclass: 'toggle' },
		css: `
			body {
				margin: 0;
				padding: 8px;

				font-family: Arial;
				font-size: 14px;

				color: ${textColor};
				background-color: ${bg};
				transition: background-color 100ms ease-in-out;
			}

			body:hover {
				background-color: ${bgHover};
			}

			body:focus {
				background-color: ${bg};
			}

			${placeholder
				? `
					body:empty:after {
						content: "${placeholder}";
						opacity: 0.4;
						font-style: italic;
						width: 100%;
						display: block;
						text-align: center;
					}
				`
				: ''
			}
		`,
	});

	const wrapper = $(textarea).parents('div.tinyeditor');
	const iframe = $('iframe', wrapper)[0];
	if ( iframe != undefined ) {
		var iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
	} else {
		var iframeDocument = undefined;
	}

	wrapper.on('click', '.tinyeditor-control', () => {
		editor.e.body.focus();
	});

	if (textarea.classList.contains('autosubmit')) {
		listen(editor.e.body, 'input', debounce(() => {
			const oldContent = textarea.value;
			editor.post();
			const newContent = textarea.value;
			if (oldContent != newContent) {
				$(textarea).closest('form').submit();
			}
		}, 1000));
	}
			
	if ( $(textarea).parents('table').hasClass('autoadd') ) {
		listen(editor.e.body, 'input', debounce(() => {
			$(textarea).trigger('change');
		}, 100));
	}

	if (lazyReveal) {
		let oldContent = textarea.value;
		if ( iframeDocument != null && iframeDocument.documentElement.scrollHeight > height ) {
			iframe.style.height = iframeDocument.documentElement.scrollHeight + 'px';
		}

		const cancelButton = $('<button type="button" class="button button--danger tinyeditor__cancel">' + language('cancel') + '</button>');
		cancelButton.on('click', () => {
			textarea.value = oldContent;
			editor.d = 0;
			editor.toggle(1);
			wrapper.attr('data-lazy-reveal', 'false');
			if ( iframeDocument != null && iframeDocument.documentElement.scrollHeight > height ) {
				iframe.style.height = iframeDocument.documentElement.scrollHeight + 'px';
			}
		});

		const saveButton = $('<button type="button" class="button button--primary tinyeditor__save">' + language('js_ok') + '</button>');
		saveButton.on('click', () => {
			editor.post();
			var contentChanged = oldContent !== textarea.value;
			oldContent = textarea.value;
			wrapper.attr('data-lazy-reveal', 'false');
			$('textarea.tinyeditor', wrapper).each(function(){
				if ( contentChanged )
					$(textarea).attr('data-changed', 'true');
			});
			if ( iframeDocument != null && iframeDocument.documentElement.scrollHeight > height ) {
				iframe.style.height = iframeDocument.documentElement.scrollHeight + 'px';
			}
		});

		const reveal = () => {
			oldContent = textarea.value;
			wrapper.attr('data-lazy-reveal', 'true');
			if ( iframe != undefined ) {
				iframe.style.height = height + 'px';
			}
		};

		listen(editor.e, 'focus', reveal);
		listen(editor.e.body, 'focus', reveal);

		wrapper.attr('data-lazy-reveal', 'false');
		wrapper.after(cancelButton);
		wrapper.after(saveButton);
	}

	$(textarea)
		.data('tinyeditor', editor)
		.attr('data-tinyedit', '1');

	wrapper
		[footer || extraButton ? 'addClass' : 'removeClass']('with-toggle')
		.find('select')
			.wrap('<div class="select-wrapper"></div>')
			.after(chevronDown());

	editor.post(); // einmal posten, damit später auf Änderungen geprüft werden kann
}

function setApiFarbschema(theme) {
	var res = null;
	$.ajax({
		type: 'PUT',
		async: false,
		url: window.location.origin+'/api/account',
		data: JSON.stringify({Farbschema: theme == 'dark' ? 'dark' : 'light'}),
		success: function(response) {
			res = response;
		},
	});
	return res;
}

function getBrowserTheme() {
	return window.matchMedia("(prefers-color-scheme:dark)").matches ? 'dark' : 'light';
}

function updateFarbschema() {
	let browserTheme = getBrowserTheme();
	if ( !config.isAutoTheme || config.currentTheme == browserTheme )
		return;

	setApiFarbschema(browserTheme);
	config.currentTheme = browserTheme;

}

function showFarbschemaHinweis() {
	if ( !config.isAutoTheme )
		return;

	config.isAutoTheme = false; // set temporary false to prevent multiple hints
	$('#main').append(
		'<div class="ajax-content" style="z-index: ' + $.topZIndex() + '">'+
			'<div class="hinweis hinweis--update farbschema-update small">'+
				'<div class="hinweis__surface">'+
					'<div class="header row">' + 
						language('js_farbschema_geaendert') +
					'</div>'+
					'<div class="content row">' + 
						language('js_farbschema_hinweis') +
					'</div>'+
					'<div class="footer">' +
						'<a href="javascript:window.location.reload()" class="button button--primary follow">'+language('newVersionReload')+'</a>' +
						'<button class="button close">' + language('js_schliessen') + '</button>' +
					'</div>' +
				'</div>'+
			'</div>' +
		'</div>'
	);
}

function iOSOrAndroid() {
	return ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform)
		|| navigator.userAgent.includes('Mac') && 'ontouchend' in document
        || navigator.userAgent.includes('Android') && 'ontouchend' in document;
}